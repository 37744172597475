/* Layouts: Pages: About us banners
--------------------------------------------------------------------------------------------------------------------- */
.aboutbanners {
  background-color: $light-gray;
  padding: 90px 0;

  @include responsive-down(mobile-large) {
    padding: 75px 0 0;
  }

  .container {
    @include responsive-up(desktop-large) {
      max-width: 1720px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 0 -25px;

    @include responsive-down (tablet-small) {
      flex-direction: column;
      margin: 0;
    }
  }

  &__contenttext {
    padding: 30px;

    @include responsive-down(tablet-small) {
      padding: 0;
    }
  }

  &__item {
    background-color: $dark-gray;
    margin: 0 25px;
    width: 50%;
    height: 100%;
    min-height: 770px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include responsive-down(tablet-small) {
      background-color: transparent;
      padding: 0 0 20px;
      height: auto;
      min-height: auto;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 0;
    }

    .title {
      font-size: 48px;
      color: $white;
      line-height: 1.1;

      @include responsive-down(tablet-large) {
        font-size: 38px;
      }

      @include responsive-down(tablet-small) {
        color: $black;
        font-size: 44px;
      }
    }

    .description {
      color: $white;
      font-size: 16px;

      @include responsive-down(tablet-small) {
        color: $black;
        margin: 20px 0;
        font-size: 14px;
      }
    }

    .img {
      margin: 50px 0 0;
      height: auto;
      object-fit: contain;
      max-width: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive-down(tablet-small) {
        width: 100%;
        max-width: none;
        margin: 20px 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &.left-banner {
      .img {
        @include responsive-down(mobile-large) {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }

    &.right-banner {
      margin: 100px 25px 0 25px;

      @include responsive-down(tablet-small) {
        margin: 0;
      }

      .img {
        @include responsive-down(mobile-large) {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }

    @include responsive-down (tablet-small) {
      width: 100%;
      margin: 0;

      &:nth-child(2) {
        margin: 30px 0 0;
      }
    }
  }
}
