/* component::product list
--------------------------------------------------------------------------------------------------------------------- */
.productgrid {
  display: flex;
  flex-wrap: wrap;

  .itemproduct {
    width: calc(100% / 4 - 20px);
    max-width: none;
    margin: 0 10px 40px;
    height: 500px;

    @include responsive-down(desktop) {
      width: calc(100% / 3 - 20px);
      margin: 0 10px 60px;
    }

    @include responsive-down(tablet-small) {
      width: calc(100% / 2 - 20px);
    }

    @include responsive-down(mobile) {
      width: calc(100% / 2 - 10px);
      margin: 0 5px 40px;
    }

    &__img {
      img {
        animation: 0.5s cubic-bezier(0, 0.36, 0.14, 1.01) 0s 1 normal both running scale-down-center-grid;
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        .itemproduct {
          &__img {
            img {
              animation: 0.8s cubic-bezier(0, 0.36, 0.14, 1.01) 0s 1 normal both running scale-up-center-grid;
            }
          }

          &__prices {
            animation: none;
          }
        }
      }
    }

    &__prices {
      animation: none;
    }
  }
}

@keyframes scale-up-center-grid {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes scale-down-center-grid {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

.productslider {
  padding: 60px 0;
  position: relative;
  margin: 30px 0;

  @include responsive-down(mobile-large) {
    position: relative;
    padding: 0 0 90px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 50px;
    height: 40px;
    background: $white;
    z-index: 8;
    width: calc(((100vw - 1949px) / 2) + 165px);

    @include responsive-down(desktop-large) {
      width: calc(((100vw - 1740px) / 2) + 165px);
    }

    @include responsive-down(desktop) {
      width: calc(((100vw - 1440px) / 2) + 153px);
    }

    @include responsive-down(tablet-extra-large) {
      width: calc(((100vw - 1320px) / 2) + 165px);
    }

    @include responsive-down(tablet) {
      width: 30px;
    }

    @include responsive-down(mobile-large) {
      width: 20px;
    }

    @include responsive-down(tablet-extra-small) {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 50px;
    height: 40px;
    background: $white;
    z-index: 8;
    width: calc(((100vw - 1949px) / 2) + 165px);

    @include responsive-down(desktop-large) {
      width: calc(((100vw - 1740px) / 2) + 165px);
    }

    @include responsive-down(desktop) {
      width: calc(((100vw - 1440px) / 2) + 153px);
    }

    @include responsive-down(tablet-extra-large) {
      width: calc(((100vw - 1320px) / 2) + 165px);
    }

    @include responsive-down(tablet) {
      width: 30px;
    }

    @include responsive-down(mobile-large) {
      width: 20px;
    }

    @include responsive-down(tablet-extra-small) {
      display: none;
    }
  }

  &__intro {
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px;
    margin: 0 calc(((100vw - 1949px) / 2) + 165px);

    @include responsive-down(desktop-large) {
      margin: 0 calc(((100vw - 1740px) / 2) + 165px);
    }

    @include responsive-down(desktop) {
      margin: 0 calc(((100vw - 1440px) / 2) + 153px);
    }

    @include responsive-down(tablet-extra-large) {
      margin: 0 calc(((100vw - 1320px) / 2) + 165px);
    }

    @include responsive-down(tablet-large) {
      margin: 0 50px;
    }

    @include responsive-down(tablet) {
      margin: 0 30px;
    }

    @include responsive-down(mobile-large) {
      margin: 0 20px;
    }

    @include responsive-down(mobile-large) {
      flex-direction: column;
      padding: 0 0 20px;
    }
  }

  &__slider {
    height: 540px;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 40px;
    scrollbar-color: $mid-gray $light-gray;
    scrollbar-width: thin;

    &.active {
      cursor: grab;
    }

    @include responsive-down(tablet-small) {
      height: 400px;
      padding-bottom: 0;
    }

    @include responsive-down(mobile) {
      height: 360px;
    }

    &::-webkit-scrollbar {
      width: 500px;
      height: 4px;
      border-radius: 50px;
      padding: 0 50px;

      @include responsive-down(tablet-small) {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      background: $light-gray;
      border-radius: 50px;
      padding: 0 50px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mid-gray;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $mid-gray;
      border-radius: 50px;
      height: 4px;
    }

    .itemproduct {
      width: 345px;
      height: 100%;
      margin: 0 10px 0 0;
      white-space: normal;

      @include responsive-down(tablet) {
        width: 280px;
      }

      @include responsive-down(mobile-large) {
        width: 235px;
        margin: 0 5px 0 0;
        min-height: 370px;
      }

      &:first-child {
        margin-left: calc(((100vw - 1949px) / 2) + 165px);

        @include responsive-down(desktop-large) {
          margin-left: calc(((100vw - 1740px) / 2) + 165px);
        }

        @include responsive-down(desktop) {
          margin-left: calc(((100vw - 1440px) / 2) + 153px);
        }

        @include responsive-down(tablet-extra-large) {
          margin-left: calc(((100vw - 1320px) / 2) + 165px);
        }

        @include responsive-down(tablet-large) {
          margin-left: 50px;
        }

        @include responsive-down(tablet) {
          margin-left: 30px;
        }

        @include responsive-down(mobile-large) {
          margin-left: 20px;
        }
      }

      &:last-child {
        margin-right: 50px;

        @include responsive-down(tablet) {
          margin-right: 30px;
        }

        @include responsive-down(mobile-large) {
          margin-right: 20px;
        }
      }
    }
  }

  &__nav {
    @include responsive-down(mobile-large) {
      padding: 10px 0 0;
      position: absolute;
      bottom: 10px;
      right: 20px;
    }

    .prev,
    .next {
      position: relative;
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;

      @include responsive-down(tablet-extra-small) {
        margin: 0 0 0 5px;
      }

      i {
        z-index: 1;

        @include responsive-down(tablet-extra-small) {
          margin: -3px 0 0 -3px;
        }
      }

      &::after {
        content: "";
        background-color: $light-gray;
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        z-index: 0;

        @include transition(all 0.3s ease-in);

        @include responsive-down(tablet-extra-small) {
          height: 46px;
          width: 46px;
        }
      }

      &:hover {
        @include responsive-up(tablet) {
          &::after {
            background-color: $hover-gray;
          }

          @include transition(all 0.3s ease-in);
        }
      }
    }
  }

  &__bar {
    width: 100%;
    margin-top: 50px;

    @include responsive-down(tablet) {
      display: none;
    }

    input[type="range"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      height: 4px;
      padding: 0;
      background: $light-gray;
      outline: none;
      border-radius: 10px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 35%;
        height: 4px;
        border-radius: 10px;
        background: $gray;
        cursor: pointer;
      }
    }
  }

  &--category {
    margin: 40px 0;

    @include responsive-down(tablet-extra-small) {
      margin: 60px 0;
    }
  }
}
