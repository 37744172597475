/* Layouts: Pages: Checkout: Order
--------------------------------------------------------------------------------------------------------------------- */
.page-wrapper--password {
  min-height: 60vh;
  max-width: 700px;
  margin: 0 auto;
  padding: 40px 0 110px;

  .page-footer--password {
    .account-link {
      .material-icons {
        display: none;
      }
    }

    ul {
      padding: 0;

      li {
        padding: 0;

        &::before {
          display: none;
        }

        a {
          padding: 11px 24px;
          background-color: $primary;
          border: 1px solid $primary;
          color: $white;
          font-size: 16px;
          display: inline-block;
          border-radius: 5px;
          font-weight: 600;
          text-align: center;

          @include transition(all 0.3s ease-in);

          &:hover {
            @include responsive-up(tablet) {
              background-color: $white;
              color: $primary;
              border: 1px solid $primary;

              @include transition(all 0.3s ease-in);

              .flip {
                &::before {
                  opacity: 0;
                  transform: translateY(-50%) rotateX(90deg);
                  color: $primary;
                }

                &::after {
                  opacity: 1;
                  transform: translateY(0) rotateX(0);
                  color: $primary;
                }
              }
            }
          }

          &:focus {
            background-color: $white;
            color: $primary;
            border: 1px solid $primary;

            @include transition(all 0.3s ease-in);

            .flip {
              &::before {
                opacity: 0;
                transform: translateY(-50%) rotateX(90deg);
                color: $primary;
              }

              &::after {
                opacity: 1;
                transform: translateY(0) rotateX(0);
                color: $primary;
              }
            }
          }

          i {
            font-size: 11px;
            margin: 0 0 0 4px;
          }

          .flip {
            &::after,
            &::before {
              color: $white;
            }
          }
        }
      }
    }
  }

  .page-header--password {
    h1 {
      @include responsive-down(mobile-extra-large) {
        font-size: 28px;
      }
    }
  }
}

.page-content--password {
  padding: 10px 0 50px;

  @include responsive-down(mobile-extra-large) {
    padding: 10px 0 40px;
  }

  .form-fields {
    padding: 20px 0;
  }

  .alert {
    margin: 0;
  }

  .forgotten-password {
    .form-control-submit {
      background-color: transparent;
      border: none;
      color: $black;
      font-weight: 300;
      padding: 15px 0 0;
      text-decoration: underline;

      @include transition(all 0.2s ease-in);

      &:hover {
        @include responsive-up(tablet) {
          color: $black;
          text-decoration: none;

          @include transition(all 0.2s ease-in);
        }
      }

      .visible--desktop {
        display: block;

        @include responsive-down(tablet) {
          display: none;
        }
      }

      .visible--mobile {
        display: none;

        @include responsive-down(tablet) {
          display: block;
        }
      }
    }
  }
}
