/* common::classes
--------------------------------------------------------------------------------------------------------------------- */
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  @include responsive-down(pc) {
    text-rendering: auto;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

.clearfix {
  clear: both;
  float: none;
  margin: 0;
  padding: 0;
  height: 0;
}

iframe {
  border: 0 solid transparent;
  width: 100%;
  height: 100%;
}

select,
textarea,
input[type=text] {
  // @include apearance(none);
  border-radius: 5px;
  border: none;
  box-shadow: none;
}

/* GENERALES */
body {
  background: $white;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-family: $font-secondary;

  @include responsive-down(tablet) {
    height: 100%;
    min-height: 100vh;
  }
}

div {
  margin: 0;
  padding: 0;
}

/* ENCABEZADOS */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  text-align: left;
  color: $dark-gray;
}

h1,
.h1 {
  font-family: $font-main;
  font-size: 50px;
  line-height: 1;
  font-weight: 400;

  @include responsive-down(tablet) {
    font-size: 45px;
  }

  @include responsive-down(mobile-large) {
    font-size: 35px;
  }
}

h2,
.h2 {
  font-family: $font-main;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 400;

  @include responsive-down(tablet) {
    font-size: 30px;
  }

  @include responsive-down(mobile-large) {
    font-size: 24px;
  }
}

h3,
.h3 {
  font-family: $font-main;
  font-size: 50px;
  line-height: 1;
  font-weight: 400;

  @include responsive-down(tablet-large) {
    font-size: 45px;
  }

  @include responsive-down(tablet) {
    font-size: 35px;
  }
}

h4,
.h4,
h5,
.h5 {
  font-family: $font-main;
  font-size: 22px;
  line-height: 1.8;
  font-weight: 400;

  @include responsive-down(mobile-large) {
    font-size: 22px;
  }

  @include responsive-down(mobile-large) {
    font-size: 20px;
  }
}

h6,
.h6 {
  font-family: $font-main;
}

a {
  text-decoration: none;
  color: black;

  @include transition (all 0.2s ease 0s);

  &:hover {
    @include responsive-up(tablet) {
      color: $primary;

      @include transition (all 0.2s ease 0s);
    }
  }
}

p {
  font-size: 14px;
  line-height: 1.6;
  color: $mid-gray;
  font-family: $font-secondary;
}

button {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: $font-secondary;

  @include transition (all 0.2s ease 0s);

  &:hover {
    @include responsive-up(tablet) {
      background-color: $white;
      color: $primary;

      @include transition (all 0.2s ease 0s);
    }
  }

  &:disabled,
  .disabled {
    background-color: #7da3d1;
    border: 1px solid #7da3d1;
    cursor: not-allowed;

    &:hover {
      @include responsive-up(tablet) {
        background-color: #7da3d1;
        border: 1px solid #7da3d1;
        color: white;

        @include transition (all 0.2s ease 0s);
      }
    }
  }
}

/* LISTAS */

ol {
  margin: 0;
  list-style: none;
  padding: 0 0 0 14px;
  counter-reset: li;

  li {
    padding: 0 0 10px 0;
    counter-increment: li;

    &::before {
      content: counter(li);
      display: inline-block;
      width: 16px;
      font-weight: 500;
      color: $primary;
    }
  }
}

ul {
  padding: 0 0 0 35px;
  list-style: none;

  li {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 300;
    padding: 0 0 20px 0;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 23px;
      height: 1px;
      background: $mid-gray;
      position: absolute;
      left: -30px;
      top: 12px;
    }
  }

  li::marker {
    display: none;
  }
}

/* FORMS */

input {
  padding: 10px 10px;
  font-size: 14px;
}

:active,
:focus {
  border: none;
  outline: none;
  text-decoration: none;
}

.form-block {
  max-width: 300px;
}

::selection {
  background: $primary;
  text-shadow: none;
  color: $white;
}

input[type="submit"] {
  border: none;
  border-radius: 0;
  width: 100%;

  @include transition (all 0.2s ease 0s);

  &:hover {
    @include transition (all 0.2s ease 0s);
  }
}

input[type="search"] {
  border: none;
  border-radius: 0;
  width: 100%;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"] {
  border: none;
  border-radius: 0;
  width: 100%;

  @include transition (border-bottom 0.2s ease 0s);

  &:focus {
    color: inherit;
  }

  &:hover {
    @include transition(background-color 0.2s ease 0s);
  }

  &.disabled {
    &:hover {
      color: inherit;
    }

    opacity: 0.5;
  }
}

input[type="date"],
input[type="number"],
input[type="time"] {
  width: 100%;

  @include transition (all 0.2s ease 0s);
}

input[type="number"] {
  max-width: 60px;
  margin-left: 10px;
}

textarea[rows] {
  height: auto;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid $grayac;
  background-color: $light-gray;
  border-radius: 0;
  font-size: 14px;
  color: $black;
  text-align: left;
  font-family: $font-secondary;

  &:focus {
    border: 1px solid $light-gray;
  }

  &::-webkit-input-placeholder {
    color: $white;
  }
}

/* TABLES */
table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding-top: 10px;
  }

  .table_center {
    text-align: right;
  }

  th {
    text-align: left;
  }
}

/* Icons */
i {
  display: inline-block;
}

/* Tns */
.tns-visually-hidden {
  display: none !important;
}
