/* component:: other:: page my account
--------------------------------------------------------------------------------------------------------------------- */
#module-ps_emailalerts-account,
.page-customer-account,
.page-my-account,
.page-addresses,
.page-order-detail {
  .breadcrumbs-container {
    display: none;
  }

  #content-wrapper {
    width: 100%;
    max-width: 1280px;
    min-width: 1280px;
    margin: 10px auto;

    .page-wrapper--authentication {
      max-width: 500px;
      margin: 80px auto;

      @include responsive-down(tablet) {
        margin: 40px 0;
      }

      .page-header--authentication {
        @include responsive-down(tablet) {
          display: block;
        }

        @include responsive-down(mobile-large) {
          h1 {
            font-size: 33px;
            margin: 0 0 20px;
          }
        }
      }

      .needs-validation {
        .btn {
          width: 250px;
          margin: 30px 0 5px;

          @include responsive-down(mobile-small) {
            width: 100%;
          }
        }
      }
    }

    @include responsive-down(desktop-extra-small) {
      width: auto;
      max-width: none;
      min-width: auto;
      margin: 50px;
    }

    @include responsive-down(tablet) {
      margin: 0 30px 50px;
    }

    @include responsive-down(mobile-large) {
      margin: 0 20px 50px;
    }
  }

  #wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0 200px;
    min-height: 650px;

    @include responsive-down(tablet) {
      padding: 0 0 200px;
    }

    @include responsive-down(mobile-large) {
      padding: 0 0 100px;
    }

    & > .row {
      width: 100%;
    }
  }

  .page-header {
    margin-bottom: 30px;

    @include responsive-down(tablet) {
      display: none;
    }

    h1 {
      font-size: 40px;
      line-height: 1;
      margin: 0 0 40px;
    }
  }

  .page-content {
    display: flex;

    @include responsive-down(tablet) {
      flex-direction: column;
    }

    .help-block {
      ul {
        padding: 0;
      }
    }

    .login-form {
      width: 100%;

      .help-block {
        ul {
          padding: 0;
        }
      }
    }

    .needs-validation {
      .form-group {
        margin: 0 0 20px;

        .forgot-password {
          a {
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
    }

    .form-footer {
      .btn {
        width: 40%;
        margin: 30px 0 0;
        display: inline-flex;
        justify-content: center;
      }
    }

    .account-links {
      display: flex;
      flex-direction: column;
      width: 275px;
      margin-right: 25px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include responsive-down(tablet) {
        align-items: center;
        flex-direction: row;
        min-width: calc(100% + 60px);
        margin: 0 -30px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        background-color: $background-gray;
      }

      @include responsive-down(mobile-large) {
        min-width: calc(100% + 40px);
        margin: 0 -20px;
      }

      a {
        display: inline-block;
        margin: 10px 0;
        font-size: 14px;
        line-height: 18px;

        @include responsive-down(tablet) {
          margin-right: 20px;
          padding: 5px 0;
        }

        &:first-of-type {
          margin-top: 0;

          @include responsive-down(tablet) {
            margin-top: 10px;
            margin-left: 20px;
          }
        }

        &:last-of-type {
          color: $gray;
        }
      }

      .comments-link {
        display: none;
      }
    }

    .account-content {
      width: calc(100% - 300px);

      @include responsive-down(tablet) {
        width: 100%;
        margin-top: 20px;
      }

      & > .container {
        @include responsive-down(tablet) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      h6 {
        font-size: 16px;
        margin: 0 0 20px;
      }

      &__content {
        position: relative;
        height: 500px;

        @include responsive-down(tablet) {
          height: 350px;
        }

        @include responsive-down(mobile-large) {
          height: 450px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          z-index: 8;
        }
      }

      &__text {
        position: absolute;
        left: 50px;
        top: 50px;
        z-index: 8;

        @include responsive-down(tablet) {
          left: 0;
          top: 0;
          padding: 20px;
        }

        p {
          margin-top: 0;
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 16px;
          color: $white;
        }

        span {
          font-size: 14px;
          line-height: 12px;
          color: $white;
        }
      }

      .psgdprinfo17 {
        margin-top: 0;
        margin-bottom: 30px;
        padding: 40px;
      }

      .psgdprgetdatabtn17 {
        float: none;
      }

      #exportDataToCsv {
        margin-left: 0;
      }

      a#exportDataToPdf {
        @include responsive-down(tablet) {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      #notifications {
        .container {
          padding: 0 !important;

          .alert {
            display: inline-block;
            padding: 8px 16px;
            margin-bottom: 20px;

            ul {
              margin: 0;
              padding-left: 20px;
              position: relative;

              li {
                padding-bottom: 0;
                font-size: 13px;
                line-height: 13px;
                font-weight: 500;

                &::before {
                  display: none;
                }
              }

              &::before {
                content: "\e918";
                font-family: $icomoon;
                font-size: 10px;
                font-weight: 600;
                position: absolute;
                left: 0;
                top: 1px;
              }
            }

            &.alert-danger {
              ul {
                &::before {
                  content: "\e903";
                }
              }
            }
          }
        }
      }

      .js-customer-form {
        & > div {
          display: flex;
          flex-wrap: wrap;

          .form-group {
            width: 50%;
          }
        }

        // .form-fields {
        //   display: flex;
        //   flex-wrap: wrap;
        //   width: calc(100% + 20px);
        //   margin-right: -20px;

        //   .form-group {
        //     width: calc(50% - 30px);
        //     margin: 10px 30px 10px 0;
        //     position: relative;
        //   }
        // }
      }

      .form-fields {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-right: -20px;

        @include responsive-down(mobile-extra-large) {
          width: 100%;
          margin: 0;
        }

        .form-group {
          width: calc(50% - 30px);
          margin: 10px 30px 10px 0;
          position: relative;

          @include responsive-down(mobile-extra-large) {
            width: 100%;
            margin: 10px 0;
          }

          &.id_country {
            select + label {
              display: none;
            }
          }

          select {
            padding: 14px;

            & + label {
              display: none;
            }
          }
        }
      }

      .container-address {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 30px;
        flex-wrap: wrap;

        & > div {
          width: calc(100% / 2 - 20px);
          min-height: 300px;
          margin: 0 0 40px;

          @include responsive-down(mobile-extra-large) {
            width: 100%;
            margin: 0 0 30px;
            min-height: 200px;
          }
        }
      }

      .address {
        padding: 20px;
        background-color: $light-gray;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &-body {
          address {
            margin: 0 0 30px 0;
            font-size: 14px;
            font-style: inherit;
            line-height: 1.3;
          }
        }

        &-footer {
          margin: auto 0 0;

          a {
            margin-right: 10px;
            color: #393939;
            font-size: 13px;
            text-decoration: underline;
          }
        }
      }

      .orders.visible--mobile {
        display: none;

        @include responsive-down(tablet) {
          display: flex;
          flex-wrap: wrap;
        }

        .order {
          width: 100%;
          margin: 0 0 40px;

          a {
            .nameorder,
            h3 {
              font-size: 15px;
              font-weight: 800;
              margin: 0 0 20px;
            }
          }

          .col-10 {
            padding: 15px;
            border: 1px solid $hover-gray-footer;
            margin: 0 0 20px;
          }

          .buttons-container {
            @include responsive-down(tablet) {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .links-account--mobile {
                display: flex;
                width: calc(100% / 2 - 10px);

                a {
                  width: 100%;
                }
              }
            }

            @include responsive-down(mobile) {
              flex-direction: column;

              .links-account--mobile {
                width: 100%;
                margin: 0 0 10px;
              }
            }

            .links-account--mobile {
              a {
                @include responsive-up(tablet) {
                  text-decoration: underline;
                  margin: 5px 0;
                  display: inline-block;
                  font-size: 14px;
                }
              }
            }
          }

          .status {
            margin: 30px 0 0;

            span {
              font-size: 13px;
              background-color: $light-gray !important;
              padding: 5px;
            }
          }

          .date,
          .total {
            font-size: 13px;
            margin: 0 0 5px;
          }
        }
      }

      .visible--desktop {
        @include responsive-down(tablet) {
          display: none;
        }
      }

      .account-table-container {
        @include responsive-down(desktop-small) {
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          position: relative;

          &::-webkit-scrollbar {
            display: block;
            height: 3px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: $light-gray;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $dark-gray;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: $dark-gray;
          }
        }
      }

      .table {
        th,
        td {
          min-width: 150px;
          font-size: 12px;
          padding: 15px;
          border-bottom: 1px solid #dfdfdf;
          white-space: initial;
        }

        td {
          span {
            background-color: transparent !important;
            font-size: 12px;
          }

          &.order-actions {
            // display: flex;
            // flex-direction: column;

            a {
              text-decoration: underline;
              margin: 5px 0;
              display: inline-block;
              font-size: 14px;
            }
          }
        }
      }

      //page details orders
      aside:empty {
        display: none;
      }

      #order-infos {
        .box {
          .col-lg-9,
          .col-lg-3 {
            margin: 0 0 30px;

            .refname {
              font-size: 16px;
              margin: 0;
            }
          }

          & > ul {
            padding: 0;

            li {
              padding: 0 0 4px;
              font-size: 14px;

              &::before {
                display: none;
              }

              span {
                font-weight: 700;
              }
            }
          }
        }

        hr {
          display: none;
        }
      }

      #order-history {
        h3 {
          font-size: 18px;
          margin: 40px 0 10px;
          line-height: 1.4;
          font-family: $font-secondary;
        }

        .visible--mobile {
          display: none;
        }
      }

      .addresses {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;

        @include responsive-down(mobile-extra-large) {
          flex-wrap: wrap;
        }

        .address {
          padding: 20px;
          background-color: #f7f7f7;
          width: calc(100% / 2 - 20px);
          height: 100%;
          display: flex;
          flex-direction: column;

          @include responsive-down(tablet) {
            min-height: 310px;
            width: calc(100% / 2 - 10px);
          }

          @include responsive-down(mobile-extra-large) {
            width: 100%;
            min-height: 200px;
            margin: 0 0 20px;
          }

          &-body {
            margin: 0 0 30px 0;
            font-size: 14px;
            font-style: inherit;
            line-height: 1.3;
          }
        }
      }

      .box .visible--desktop {
        @include responsive-down(tablet) {
          display: none;
        }
      }

      .box {
        table {
          margin: 40px 0;

          td {
            a {
              margin: 0 0 5px;
              display: inline-block;
            }
          }
        }
      }

      .order-items,
      .order-totals,
      .shipping-lines {
        &.visible--mobile {
          display: none;

          @include responsive-down(tablet) {
            display: block;
          }
        }
      }

      .order-items {
        margin: 40px 0;

        .order-item {
          margin: 20px 0;

          .name,
          .ref {
            font-size: 14px;
            padding: 5px 0;
            font-weight: 900;
          }

          .col-sm-7.qty {
            .row {
              display: flex;
              align-content: center;
              padding: 10px 0 0;

              .col-4 {
                margin-right: 15px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .order-totals {
        margin: 40px 0;

        .order-total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 0;
        }
      }

      .shipping-lines {
        ul {
          padding: 0;

          li {
            font-size: 14px;
            padding: 3px 0;

            span {
              font-weight: 600;
              margin: 0 5px 0 0;
            }

            &::before {
              display: none;
            }
          }
        }
      }

      .order-message-form {
        padding: 40px 0 0;

        .form-fields {
          flex-direction: column;

          @include responsive-down(tablet) {
            width: 100%;
          }

          .form-group {
            @include responsive-down(mobile-large) {
              width: 100%;
            }

            label {
              display: inherit;
              position: initial;
              max-height: initial;
              font-weight: 400;
              top: auto;
              padding: 0 0 10px;
              font-size: 14px;
            }
          }
        }

        h3 {
          font-size: 18px;
          font-family: $font-secondary;
          font-weight: 700;
        }
      }
    }

    .register-form {
      &__title {
        margin: 0;
        padding: 0 0 30px;

        a {
          text-decoration: underline;

          &:hover {
            @include responsive-up (tablet) {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .page-footer {
    .no-account {
      a {
        font-size: 14px;
        text-decoration: none;
        margin: 10px 0 0;
        display: inline-block;
      }
    }
  }

  //my account
  .page-wrapper--my-account {
    .page-header--my-account {
      h1 {
        font-size: 30px;
        margin: 0 0 30px;
      }

      @include responsive-down(tablet) {
        display: none;
      }
    }
  }
}

#module-ps_emailalerts-account {
  .page-wrapper--module-ps_emailalerts-account {
    .account-content {
      @include responsive-down(tablet-large) {
        margin: 50px 0 0;
      }

      & > ul {
        padding: 0;
        margin: 0 0 0 100px;

        @include responsive-down(tablet-large) {
          margin: 0;
        }

        & > li {
          @include responsive-down(mobile-large) {
            flex-wrap: wrap;
            align-items: flex-start !important;
          }

          &::before {
            display: none;
          }

          .emailalerts {
            &__img {
              margin: 0 30px 0 0;

              @include responsive-down(mobile-large) {
                width: 30%;
              }
            }

            &__name {
              @include responsive-down(mobile-large) {
                width: calc(70% - 30px);
              }
            }
          }

          .btn {
            margin: 0 0 0 auto;
            font-size: 14px;

            @include responsive-down(mobile-large) {
              width: 100%;
              margin: 20px 0 0;
            }
          }
        }
      }
    }
  }
}
