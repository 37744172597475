/* Layouts: Pages: Search Products
--------------------------------------------------------------------------------------------------------------------- */
.searchproducts {
  padding: 70px 0;

  @include responsive-down(mobile-large) {
    padding: 50px 0;
  }

  @include responsive-down(mobile) {
    padding: 30px 0;
  }

  .title {
    text-align: center;
  }

  &__listcontainer {
    margin: 0 -10px;

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: $mid-gray;
      margin: 0;
    }
  }

  &__selection {
    display: flex;
    justify-content: space-between;
    padding: 70px 0 30px;
    align-items: center;

    @include responsive-down(mobile-large) {
      padding: 40px 0 20px;
    }

    @include responsive-down(mobile-small) {
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      margin: 0;
    }

    .filter-order {
      @include responsive-down(mobile-large) {
        display: flex;
        justify-content: flex-end;
      }

      label {
        margin: 0 5px 5px 0;
        font-size: 14px;
        line-height: 1.6;
        color: #525252;
        display: inline-block;
        white-space: nowrap;
      }

      select {
        padding: 3px;
        background-color: $light-gray;
        font-size: 12px;
      }
    }

    .shownumberproducts {
      &.visible--mobile,
      &.visible--desktop {
        display: none;
      }
    }

    .blockfilterselect {
      margin: 0 0 0 auto;
      display: none;

      @include responsive-down(mobile) {
        margin: 0;
      }
    }
  }

  .page-content.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;

    @include responsive-down(mobile-large) {
      align-items: flex-start;
      padding: 40px 0 80px;
    }

    h4 {
      padding: 0 0 10px;

      @include responsive-down(mobile) {
        padding: 0;
      }
    }

    .search-widget {
      .header__form {
        display: flex;
        align-items: center;
        position: relative;
        width: 380px;
        cursor: pointer;
        right: inherit;

        @include responsive-down(tablet) {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          right: inherit;
        }

        @include responsive-down(mobile) {
          width: 335px;
        }

        input.header__input-search {
          background: $light-gray;
          padding: 12px 20px;
          display: flex;

          &::placeholder {
            color: $primary-dark;

            @include transition(all 0.25s ease-in-out);
          }
        }
      }
    }
  }
}
