/* Layouts: Pages: Authentication
--------------------------------------------------------------------------------------------------------------------- */
.page-authentication,
.page-identity {
  .page-content {
    .register-form,
    .account-content {
      .form-fields {
        .form-group {
          .custom-control {
            display: flex;
            align-items: center;
            order: 2;

            &:first-child {
              margin-right: 10px;
            }

            &.custom-checkbox {
              align-items: flex-start;

              .custom-control-label {
                max-height: inherit;
                top: -2px;
                font-size: 14px;

                em {
                  max-width: 550px;
                  width: 100%;
                  display: block;
                  font-size: 10px;
                  margin-top: 5px;
                  color: #989898;
                }
              }
            }

            .radio {
              margin-right: 5px;
              line-height: 13px;
            }
          }

          .label.mr-3 {
            position: inherit;
            order: 1;
            font-size: 10px;
            color: $dark-gray;
          }

          input {
            &:focus ~ label {
              font-size: 11px !important;
              color: $light-gray !important;
            }

            &:placeholder-shown ~ label {
              font-size: 10px;
            }
          }
        }
      }

      .initsesion {
        color: $primary;

        &:hover {
          @include responsive-up(tablet) {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
