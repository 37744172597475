/* component::product list
--------------------------------------------------------------------------------------------------------------------- */
.subcategorynavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $primary;
  color: $white;

  @include responsive-up(tablet-extra-small) {
    background-color: transparent;
    border: none;
  }

  @include responsive-down(tablet-extra-small) {
    padding: 10px 20px;
  }

  .breadcrumb {
    @include responsive-down(tablet-extra-small) {
      padding: 0;
      position: relative;
      margin: 0;
    }

    &-item {
      @include responsive-down(tablet-extra-small) {
        font-size: 15px;

        span {
          color: $white;
        }
      }

      i {
        @include responsive-down(tablet) {
          color: $white;
        }

        @include responsive-up(tablet) {
          display: none;
        }
      }

      //TODO @dayane ELIMINAR .ACTIVE
      .breadcrumb-item--mobile {
        @include responsive-down(tablet-extra-small) {
          padding: 0;

          &::before {
            border-top: 1px solid $white;
            border-left: 1px solid $white;
          }
        }
      }
    }
  }

  .btnfilters {
    @include responsive-down(tablet-extra-small) {
      .filters-icon {
        rect {
          fill: $white;
        }

        circle {
          stroke: $white;
          fill: $primary;
        }
      }

      .textfilter {
        color: $white;
        font-size: 14px;
      }
    }
  }

  nav.visible--desktop {
    display: block !important;
  }
}
