/* Layouts: Pages: Opinions
--------------------------------------------------------------------------------------------------------------------- */
.page-wrapper.page-wrapper--module-ws_productreviews-reviewaccount {
  width: 100%;
  margin: 100px auto;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 1600px;

  @include responsive-down(tablet) {
    padding-left: 30px;
    padding-right: 30px;
    margin: 80px auto;
  }

  @include responsive-down(mobile-large) {
    padding-left: 20px;
    padding-right: 20px;
    margin: 50px auto;
  }

  .page-header {
    margin: 0 0 50px;

    @include responsive-down(mobile-large) {
      margin: 0 0 30px;
    }

    h1 {
      @include responsive-down(tablet) {
        font-size: 40px;
      }

      @include responsive-down(tablet-extra-small) {
        font-size: 33px;
      }
    }
  }

  .page-content {
    margin: 0;

    .table {
      display: flex;
      flex-direction: column;
      font-family: $font-main;

      thead {
        margin: 0 0 30px;
        font-family: $font-main;

        @include responsive-down(mobile-large) {
          display: none;
        }

        tr {
          display: flex;
          width: 100%;

          th {
            width: calc(100% / 3);
            font-size: 20px;
          }
        }
      }

      tbody {
        padding: 0 0 40px;
        width: 100%;
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          width: 100%;
          margin: 0 0 50px;
          border-top: 1px solid #e2e2e2;
          padding: 70px 0 0;

          @include responsive-down(tablet) {
            display: grid;
            grid-template-columns: repeat(3, 1fr) repeat(2, 0);
            grid-template-rows: repeat(4, 1fr) 0;
            grid-column-gap: 0;
            grid-row-gap: 0;
          }

          @include responsive-down(mobile-large) {
            display: flex;
            flex-direction: column;
            margin: 0 0 20px;
            padding: 40px 0 0;
          }

          &:first-child {
            border: none;
            padding: 0;
          }

          td {
            width: calc(100% / 3);
            max-width: none;

            @include responsive-down(tablet) {
              width: 100%;
            }

            &:first-child {
              font-size: 14px;
              padding: 0 20px 0 0;

              @include responsive-down(tablet) {
                grid-area: 1 / 1 / 6 / 2;
              }

              @include responsive-down(mobile-large) {
                font-size: 20px;
                padding: 0 0 30px 0;
              }

              img {
                width: 140px;
                height: auto;
                background-color: #f7f7f7;
                margin: 0 0 20px;

                @include responsive-down(mobile-large) {
                  width: 80%;
                }
              }
            }

            &.criterion_column {
              @include responsive-down(tablet) {
                grid-area: 1 / 2 / 3 / 4;
              }

              .form-control-label {
                margin: 0 0 10px;
                display: block;
                max-width: none;
              }

              .criterions_review_list {
                margin: 0 0 20px;

                .form-control-label {
                  margin: 0 0 4px;
                  display: block;
                }
              }

              .yes-ws_review,
              .no-ws_review {
                i {
                  display: none;
                }
              }

              .rating {
                span {
                  color: $secondary;
                  cursor: pointer;

                  &::before {
                    left: auto !important;
                  }
                }
              }
            }

            &.history_link {
              @include responsive-down(tablet) {
                grid-area: 3 / 2 / 5 / 4;
                margin: -30px 0 0;
              }

              @include responsive-down(mobile-large) {
                margin: 20px 0 0;
              }
            }

            textarea {
              margin: 10px 0 20px;
              resize: none;
              border: none;
              min-height: 40px;
              line-height: 1.5;
            }
          }
        }

        .button {
          cursor: pointer;
          border-radius: 5px;
          width: 200px;

          &:hover {
            @include responsive-up(tablet) {
              background-color: #0a3b76;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .alert.alert-success {
    display: inline-block;
    padding: 8px 16px;
    margin-bottom: 20px;
    background: #f2f7ed;
    border-radius: 3px;
    font-size: 12px;
    width: auto;
    color: #5ead3b;
  }
}
