/* component::product
--------------------------------------------------------------------------------------------------------------------- */
.itemproduct {
  background-color: $light-gray;
  padding: 20px;
  display: inline-block;
  // max-width: 300px;
  overflow: hidden;
  position: relative;

  @include responsive-down(mobile-large) {
    padding: 15px;
  }

  &:hover {
    @include responsive-up(tablet) {
      color: $black;

      .itemproduct__img {
        img {
          animation: 0.8s cubic-bezier(0, 0.36, 0.14, 1.01) 0s 1 normal both running scale-up-center;
        }
      }

      .itemproduct__bottom {
        height: 78.4px;

        @include transition(height 0.325s ease-in);

        .btn--buy {
          animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
      }

      .itemproduct__prices {
        padding-bottom: 13px;
        padding-top: 14px;
        animation: slide-top-prices 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }

  &__img {
    overflow: hidden;
    height: 55%;
    display: flex;
    align-items: center;

    @include responsive-down(mobile-large) {
      height: 55%;
    }

    @include responsive-down(mobile) {
      height: 45%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      animation: 0.5s cubic-bezier(0, 0.36, 0.14, 1.01) 0s 1 normal both running scale-down-center;
      mix-blend-mode: multiply;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45%;

    @include responsive-down(mobile-large) {
      height: 45%;
      padding: 20px 0 0;
    }
  }

  &__text {
    .characteristic {
      ul {
        margin: 0;
        padding-left: 15px;

        li {
          color: $gray;
          font-size: 12px;
          line-height: 1.2;
          margin: 12px 0;
          padding-bottom: 0;
          display: flex;
          align-items: flex-start;

          &::before {
            display: none;
          }

          &::after {
            content: "\e916";
            font-family: $icomoon;
            color: $primary;
            position: absolute;
            left: -15px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      p {
        color: $gray;
        font-size: 12px;
        line-height: 1.2;
      }
    }
  }

  &__title {
    font-weight: 300;
    min-height: 47px;
    margin: 10px 0 0;

    @include transition(all 0.3s ease-in);

    &:hover {
      @include responsive-up(tablet) {
        color: $primary;

        @include transition(all 0.3s ease-in);
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    height: 16px;

    @include transition(all 0.325s ease-in);

    @include responsive-down(tablet-small) {
      height: auto;
      padding: 20px 0 0;
    }

    .btn--buy {
      // transform: translateY(100px);
      padding: 9px 24px;
      animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      // @include transition(all 0.325s ease-in-out);

      @include responsive-down(tablet-small) {
        display: none;
      }
    }
  }

  &__prices {
    display: inline-flex;
    padding-bottom: 0;
    align-items: center;

    @include transition(all 0.325s ease-in);

    @include responsive-down(tablet-small) {
      padding: 0;
    }

    @include responsive-down(mobile-small) {
      flex-direction: column;
      align-items: flex-start;
    }

    .price {
      font-size: 16px;
      font-family: $font-secondary;
      font-weight: 700;
      padding: 0 10px 0 0;
      margin: 0;
      line-height: 16px;

      &--before {
        text-decoration: line-through;
        color: $grayac;
      }

      &--new {
        color: $red;
      }

      &--regular {
        color: $mid-gray;
      }
    }
  }

  &__offer {
    position: absolute;
    top: 18px;
    left: 18px;
    background-color: $red;
    padding: 4px 8px 2px;
    display: flex;
    align-items: center;

    @include responsive-down(mobile-large) {
      top: 15px;
      left: 15px;
    }

    .discount {
      color: $white;
      font-size: 12px;
      margin: 0;
    }
  }

  &--buylist {
    background-color: transparent;
    padding: 0;
    height: 100% !important;

    .itemproduct {
      &__img {
        background-color: $light-gray;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
      }

      &__title {
        min-height: 40px;
        font-weight: 500;
      }

      &__prices {
        .price {
          font-size: 14px;
        }
      }

      &__offer {
        @include responsive-down(tablet-extra-small) {
          top: 0;
          left: 0;
          padding: 1px 5px 1px;

          .discount {
            font-size: 11px;
          }
        }
      }

      &__content {
        @include responsive-down(mobile-large) {
          padding: 0;
        }
      }

      &__bottom {
        @include responsive-down(tablet-extra-small) {
          padding: 0;
        }
      }
    }

    .characteristic {
      padding: 0 0 20px;

      @include responsive-down(mobile-large) {
        padding: 0 0 17px;

        ul li {
          margin: 7px 0;
        }
      }
    }

    .btn--buy {
      position: absolute;
      width: calc(100% - 30px);
      bottom: -80px;
    }

    &:hover {
      @include responsive-up(tablet) {
        .itemproduct__bottom {
          height: auto;
        }

        .itemproduct__prices {
          padding: 0;
        }

        .btn--buy {
          bottom: 15px;
        }

        @include transition(all 0.4s ease-in);
      }
    }
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

@keyframes slide-top-prices {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom-prices {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
