/* component::opinions
--------------------------------------------------------------------------------------------------------------------- */
.opinionsproduct {
  width: 100%;
  padding: 80px 0;
  overflow: hidden;

  @include responsive-down(tablet-extra-small) {
    padding: 40px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include responsive-down(mobile-extra-large) {
      align-items: flex-start;
    }
  }

  .btn--opinions {
    min-width: 170px;
    display: flex;
    justify-content: space-between;
    padding: 9px 34px;
  }

  &__title {
    text-align: center;
    font-size: 34px;
    font-family: $font-main;
    margin: 0 0 10px;

    @include responsive-down(mobile-extra-large) {
      text-align: left;
      line-height: 1.1;
      margin: 0 0 20px;
    }

    @include responsive-down(mobile) {
      max-width: 250px;
    }
  }

  &__subtitle {
    text-align: center;
    margin: 0;

    @include responsive-down(mobile-extra-large) {
      text-align: left;
      line-height: 1.1;
    }

    .valoration {
      font-weight: 700;
    }
  }

  &__intro {
    margin: 0 0 40px;
    max-width: 600px;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 40px 0 20px;

    @include responsive-down(mobile-extra-large) {
      flex-direction: row;
      overflow: scroll;
      width: calc(100% + 40px);
      margin: 0 -20px 0;
    }
  }
}
