/* component::item category
--------------------------------------------------------------------------------------------------------------------- */
.itemcategory {
  width: 100%;
  height: 250px;
  position: relative;
  display: block;
  overflow: hidden;

  @include responsive-up(desktop-large) {
    height: 300px;
  }

  &::before {
    content: "";
    background-color: $black03;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;

    @include transition(all 0.3s ease-in);
  }

  .flip {
    margin: 0 0 -10px 4px;

    &::before {
      color: $white !important;

      @include responsive-down(tablet-extra-small) {
        font-size: 15px;
      }
    }
  }

  .link {
    &::before {
      content: "";
      height: 1px;
      background-color: $white;
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &:hover {
    @include responsive-up(tablet) {
      @include transition(all 0.3s ease-in);

      &::before {
        opacity: 1;

        @include transition(all 0.3s ease-in);
      }

      .itemcategory__img {
        transform: scale(1.1);
      }

      .flip {
        &::before {
          opacity: 0;
          transform: translateY(-50%) rotateX(90deg);
        }

        &::after {
          opacity: 1;
          transform: translateY(0) rotateX(0);
          color: $white;
        }
      }

      .link {
        color: $white;

        &::before {
          content: "";
          width: 100%;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
  }

  &__content {
    position: absolute;
    z-index: 2;
    bottom: 35px;
    left: 25px;
    width: calc(100% - 50px);
    right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include responsive-down(tablet) {
      bottom: 28px;
    }
  }

  &__title {
    margin: 0;
    line-height: 1;
    font-size: 21px;
    font-weight: 700;
    color: $white;
    letter-spacing: 0.2px;

    @include responsive-down(tablet) {
      font-size: 18px;
    }

    &::before {
      background-color: $white;
    }
  }

  &__img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);

    @include transition(all 0.3s ease-in);
  }
}
