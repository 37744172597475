/* variables::fonts::icomoon
--------------------------------------------------------------------------------------------------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url('/themes/goodyear-power/assets/css/fonts/icomoon.eot');
  src:
    url('/themes/goodyear-power/assets/css/fonts/icomoon.eot?lqf5ui#iefix') format('embedded-opentype'),
    url('/themes/goodyear-power/assets/css/fonts/icomoon.ttf?lqf5ui') format('truetype'),
    url('/themes/goodyear-power/assets/css/fonts/icomoon.woff?lqf5ui') format('woff'),
    url('/themes/goodyear-power/assets/css/fonts/icomoon.svg?lqf5ui#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-diagonal::before {
  content: "\e900";
}

.icon-arrow-left::before {
  content: "\e901";
}

.icon-arrow-right::before {
  content: "\e902";
}

.icon-close::before {
  content: "\e903";
}

.icon-search::before {
  content: "\e904";
}

.icon-transport::before {
  content: "\e905";
}

.icon-user::before {
  content: "\e906";
}

.icon-pay-secure::before {
  content: "\e907";
}

.icon-padlock::before {
  content: "\e908";
}

.icon-packging::before {
  content: "\e909";
}

.icon-facebook::before {
  content: "\e90a";
}

.icon-twitter::before {
  content: "\e90b";
}

.icon-youtube::before {
  content: "\e90c";
}

.icon-whatsapp::before {
  content: "\e90d";
}

.icon-insta::before {
  content: "\e90e";
}

.icon-visa::before {
  content: "\e90f";
}

.icon-bizum::before {
  content: "\e910";
}

.icon-mastercard::before {
  content: "\e911";
}

.icon-paypal::before {
  content: "\e912";
}

.icon-certificate::before {
  content: "\e913";
}

.icon-star::before {
  content: "\e914";
}

.icon-mid-star::before {
  content: "\e915";
}

.icon-characteristic::before {
  content: "\e916";
}

.icon-trash::before {
  content: "\e917";
}

.icon-check::before {
  content: "\e918";
}

.icon-download::before {
  content: "\e919";
}

.icon-filters::before {
  content: "\e91a";
}

.icon-gallery::before {
  content: "\e91b";
}

.icon-less::before {
  content: "\e91c";
}

.icon-more::before {
  content: "\e91d";
}

.icon-faqs::before {
  content: "\e91e";
}

.icon-support::before {
  content: "\e91f";
}

.icon-arrow-down-short::before {
  content: "\e920";
}

.icon-arrow-up-short::before {
  content: "\e921";
}

.icon-arrow-left-short::before {
  content: "\e922";
}

.icon-arrow-right-short::before {
  content: "\e923";
}

.icon-duration::before {
  content: "\e924";
}

.icon-reliability::before {
  content: "\e925";
}

.icon-innovation::before {
  content: "\e926";
}

.icon-secure::before {
  content: "\e927";
}

.icon-power::before {
  content: "\e928";
}

.icon-check-circle::before {
  content: "\e929";
}

.icon-hand-star::before {
  content: "\e92a";
}
