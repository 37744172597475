/* Layouts: Pages: Error 404
--------------------------------------------------------------------------------------------------------------------- */
.page-wrapper--pagenotfound {
  width: 100%;
  min-height: 70vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  flex-direction: column;

  @include responsive-down(tablet-extra-small) {
    text-align: left;
    max-width: none;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .page-header--pagenotfound {
    @include responsive-down(tablet-extra-small) {
      padding: 60px 20px 0;
    }

    @include responsive-down(mobile-large) {
      padding: 60px 20px 0;
    }

    h1 {
      text-align: center;
      margin: 0 0 20px;
      line-height: 1.1;
      font-size: 45px;

      @include responsive-down(tablet) {
        font-size: 35px;
      }

      @include responsive-down(tablet-extra-small) {
        text-align: left;
      }

      @include responsive-down(mobile-large) {
        font-size: 30px;
      }
    }
  }

  p {
    font-size: 16px;
  }

  .btn {
    margin: 30px 0 0;
  }
}
