/* component::section explication
--------------------------------------------------------------------------------------------------------------------- */
.sectionexplication {
  padding: 70px 0 100px;

  @include responsive-down(tablet-large) {
    padding: 50px 0;
  }

  @include responsive-down(tablet-extra-small) {
    padding: 30px 0;
  }

  &__title {
    width: 500px;
    margin: 0 0 30px;

    h2 {
      font-size: 34px !important;
    }

    @include responsive-down(mobile-large) {
      width: 100%;
      margin: 0 0 40px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;

    @include responsive-down(tablet-small) {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: calc(100% / 3);
    padding: 0 10px;

    @include responsive-down(tablet-small) {
      width: 100%;
      padding: 0 10px 60px;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 0 10px 40px;
    }

    @include responsive-down(mobile) {
      padding: 0 10px 50px;
    }

    .img {
      width: 100%;
      height: 250px;

      @include responsive-down(tablet-large) {
        height: 180px;
      }

      @include responsive-down(tablet-small) {
        height: 340px;
      }

      @include responsive-down(mobile) {
        height: 220px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
