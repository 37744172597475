/* component::general description
--------------------------------------------------------------------------------------------------------------------- */
.section2columns {
  display: flex;
  justify-content: space-between;
  padding: 90px 0;

  @include responsive-down(tablet) {
    padding: 100px 0;
  }

  @include responsive-down(tablet-small) {
    flex-direction: column;
    padding: 100px 0;
  }

  @include responsive-down(tablet-extra-small) {
    padding: 60px 0;
  }

  &__content {
    width: 40%;
    padding: 70px 70px 70px 0;

    @include responsive-up(desktop) {
      padding: 150px 80px 150px 0;
    }

    @include responsive-down(desktop-small) {
      width: 50%;
    }

    @include responsive-down(tablet) {
      padding: 70px 70px 70px 0;
    }

    @include responsive-down(tablet-small) {
      width: 100%;
      padding: 0 0 30px;
    }

    h2,
    h3 {
      line-height: 1.1;
      margin: 0 0 30px;
      color: $dark-gray;
      width: 80%;

      @include responsive-down(tablet-extra-large) {
        width: 100%;
      }

      @include responsive-down(tablet) {
        font-size: 34px;
      }

      @include responsive-down(tablet-small) {
        margin: 0 0 30px;
      }
    }

    p {
      color: $mid-gray;
    }

    ul {
      padding: 20px 0 0 30px;
    }

    ul li {
      color: $mid-gray;

      @include responsive-down(tablet) {
        font-size: 14px;
      }
    }
  }

  &__media {
    width: 40%;
    height: 100%;

    @include responsive-down(desktop-small) {
      width: 50%;
    }

    @include responsive-down(tablet-small) {
      width: calc(100% + 60px);
      margin: 0 -30px;
    }

    @include responsive-down(mobile-large) {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--reverse {
    flex-direction: row-reverse;

    @include responsive-down(tablet-small) {
      flex-direction: column;
    }

    .section2columns__content {
      @include responsive-down(desktop-small) {
        padding: 70px 0 70px 70px;
      }

      @include responsive-down(tablet) {
        padding: 70px 0 70px 50px;
      }

      @include responsive-down(tablet-small) {
        padding: 0 0 30px;
      }
    }

    .section2columns__media {
      @include responsive-down(desktop-small) {
        width: 40%;
      }

      @include responsive-down(desktop-small) {
        width: 50%;
      }

      @include responsive-down(tablet-small) {
        width: calc(100% + 60px);
        margin: 0 -30px;
      }

      @include responsive-down(mobile-large) {
        width: calc(100% + 40px);
        margin: 0 -20px;
      }
    }
  }
}
