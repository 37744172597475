/* component::slider home
--------------------------------------------------------------------------------------------------------------------- */
.sliderinitial {
  height: calc(100vh - 108px);
  width: 100%;
  margin: 84px 0 0;
  position: relative;
  min-height: 620px;

  @include responsive-down(tablet) {
    height: calc(90vh - 80px);
    margin: 53px 0 0;
    min-height: 520px;
  }

  .tns-outer {
    height: 100%;

    .tns-ovh {
      height: 100%;

      .tns-inner {
        height: 100%;

        .tns-slider {
          height: 100%;

          .tns-item {
            height: 100%;
          }
        }
      }
    }
  }

  .tns-nav {
    position: absolute;
    top: 30%;
    left: 50px;
    z-index: 3;
    opacity: 1;
    transform: translateY(0);
    animation-name: opacityTitleHome;
    animation-duration: 1.5s;
    animation-timing-function: ease;

    @include responsive-down(tablet) {
      left: 30px;
    }

    @include responsive-down(mobile-large) {
      left: 20px;
      bottom: 10px;
      top: auto;
    }

    &-active.tns-nav-active {
      &::before {
        background-color: $white;
        padding: 1.4px;
      }
    }

    button {
      background-color: transparent;
      border-radius: 0;
      border: none;
      position: relative;
      padding: 20px 10px;
      width: 40px;
      margin: 0 10px 0 0;

      &::before {
        content: "";
        background-color: $white05;
        padding: 0.4px;
        height: 1px;
        width: 40px;
        position: absolute;
        bottom: 50%;
        left: 0;
      }
    }
  }

  &__list {
    display: flex;
    overflow: hidden;

    .itemsliderinitial {
      width: 0;

      &:first-child {
        width: 100%;
      }

      &__content {
        bottom: 70px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .title {
          margin: 0 0 20px;

          @include responsive-down(tablet) {
            margin: 0 0 14px;
          }
        }

        p {
          @include responsive-down(tablet) {
            margin: 0 0 20px;
          }
        }
      }
    }

    &.tns-slider {
      display: inherit;
      overflow: inherit;

      .itemsliderinitial {
        width: 100%;

        &__content {
          bottom: 79px;

          @include responsive-down(tablet) {
            bottom: 62px;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          .title {
            margin: 0 0 20px;
          }
        }
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 50px;
    left: auto;
    right: 40px;
    width: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    opacity: 1;
    transform: translateY(0);
    animation-name: opacityTitleHomeRight;
    animation-duration: 1.5s;

    @include responsive-down(mobile-large) {
      display: none;
    }

    .scroll {
      transform: rotate(270deg);
      position: absolute;
      top: -50px;
      font-size: 12px;
      color: $white;
      letter-spacing: 2px;
    }

    &::after {
      content: "";
      width: 2px;
      height: 100px;
      background-color: #919191;
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      z-index: 2;
      margin: auto;
    }

    .loading_line {
      position: relative;
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      margin: auto;
      width: 2px;
      height: 100%;
      z-index: 4;
      transform-origin: 0% 100%;
      animation: kf_loading_line 2s ease 0s infinite;

      .loading_line_inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
        background-color: #e6e6e6;
        transform-origin: 0% 0%;
      }

      .loading_line_inner--1 {
        opacity: 0.2;  //change for inner line opacity, currently 0/hidden
        animation: kf_loading_line_inner-1 2s ease 0s infinite;
      }

      .loading_line_inner--2 {
        opacity: 1;
        animation: kf_loading_line_inner-2 2s ease 0s infinite;
      }
    }

    @keyframes kf_loading_line {
      0% {
        transform: scaleY(1);
      }

      50% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(0);
      }
    }

    @keyframes kf_loading_line_inner-1 {
      0% {
        transform: scaleY(0);
      }

      25% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }

    @keyframes kf_loading_line_inner-2 {
      0% {
        transform: scaleY(0);
      }

      25% {
        transform: scaleY(0);
      }

      50% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }
  }

  &--about {
    .sliderinitial__list,
    .itemsliderinitial {
      height: 100%;
    }

    .itemsliderinitial__content {
      @include responsive-down(mobile) {
        width: calc(100% - 20px);
        padding: 0 20px 0 0;
      }

      .title {
        @include responsive-down(tablet-extra-small) {
          font-size: 34px;
          padding: 0;
        }
      }

      .description {
        @include responsive-down(tablet-extra-small) {
          margin: 0;
        }
      }
    }
  }
}

body.topbar {
  .sliderinitial {
    margin: 0;
  }
}

@keyframes opacityTitleHome {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  50% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes opacityTitleHomeRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  50% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
