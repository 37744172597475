/* Layouts: Pages: About us spirit
--------------------------------------------------------------------------------------------------------------------- */
.page-cms {
  .breadcrumb {
    display: none;
  }
}

.aboutspirit {
  width: 100%;
  padding: 110px 0;

  @include responsive-down(tablet-extra-small) {
    padding: 80px 0;
  }

  @include responsive-down(mobile-large) {
    padding: 40px 0;
  }

  @include responsive-down(mobile) {
    padding: 40px 0 10px;
  }

  &__title,
  &__description {
    text-align: center;
    margin: 0 auto;

    @include responsive-down(tablet-extra-small) {
      text-align: left;
    }
  }

  &__title {
    max-width: 400px;
    padding: 0 0 40px;
    color: $mid-gray;

    @include responsive-down(tablet-extra-small) {
      font-size: 44px;
      max-width: none;
    }

    @include responsive-down(mobile-large) {
      padding: 0 0 20px;
    }
  }

  &__description {
    max-width: 700px;
    margin: 0 auto 20px;

    @include responsive-down(mobile-large) {
      margin: 0 0 30px;
    }
  }

  &__values {
    display: flex;
    justify-content: space-between;
    padding: 60px 0 0;
    max-width: 1380px;
    margin: 0 auto;

    @include responsive-down(tablet-small) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 40px 0 0;
      max-width: none;
    }

    @include responsive-down(mobile-large) {
      flex-direction: column;
      width: max-content;
      margin: 0 auto;
      align-items: flex-start;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include responsive-down(tablet-small) {
      width: calc(100% / 3);
      margin: 0 0 40px;
    }

    @include responsive-down(mobile-large) {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      margin: 0 0 54px;
    }

    i {
      font-size: 42px;
      color: $primary;

      @include responsive-down(mobile-large) {
        width: 50px;
        display: flex;
        justify-content: center;
        margin: 0 20px 0 0;
      }
    }

    .value {
      margin: 20px 0 0;
      font-size: 26px;
      font-family: $font-main;

      @include responsive-down(tablet-small) {
        font-size: 24px;
      }

      @include responsive-down(tablet-extra-small) {
        font-size: 26px;
      }

      @include responsive-down(mobile-large) {
        margin: 0;
      }
    }
  }
}
