/* components::partials::footer
--------------------------------------------------------------------------------------------------------------------- */
.footer {
  background-color: $light-black;
  padding: 25px 0 18px;
  overflow-x: hidden;

  @include responsive-down(mobile-large) {
    padding: 20px 0 15px;
  }

  .newsletter {
    border-bottom: 1px solid $mid-gray;
    padding: 0 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include responsive-down(tablet) {
      flex-direction: column;
    }

    @include responsive-down(mobile-large) {
      padding: 0 0 40px;
    }

    &__intro {
      width: 30%;

      @include responsive-down(tablet) {
        width: 100%;
        padding: 0 0 30px;
      }

      .title,
      .description {
        color: $white;
      }

      .description {
        font-size: 12px;

        @include responsive-down(tablet) {
          font-size: 14px;
        }
      }
    }

    #mc_embed_signup {
      width: 40%;

      @include responsive-down(desktop-extra-small) {
        width: 47%;
      }

      @include responsive-down(tablet-extra-large) {
        width: 50%;
      }

      @include responsive-down(tablet-extra-large) {
        width: 56%;
      }

      @include responsive-down(tablet-extra-large) {
        width: 60%;
      }

      @include responsive-down(tablet) {
        width: 100%;
      }
    }

    &__form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;

      .subscription {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;

        @include responsive-down(mobile-large) {
          flex-direction: column;
        }

        &__container {
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          width: 100%;
          flex-direction: column;

          @include responsive-down(tablet) {
            position: relative;
            padding: 0 0 70px;
          }

          .form-group {
            display: flex;
            position: relative;

            @include responsive-down(tablet) {
              margin: 0;
              position: initial;
              flex-direction: column;
            }

            input {
              background-color: $mid-gray;
              border: none;
              color: $white;
              height: 45px;
            }

            label {
              position: absolute;
              top: 38px;
              width: 100%;

              &::before {
                color: #707070;
                font-size: 16px;
                transform: translate3d(0, -32px, 0) scale3d(0.72, 0.72, 1);
              }
            }

            input:placeholder-shown + label::before {
              transform: translate3d(0, -25px, 0) scale3d(1, 1, 1);
            }

            input:focus + label::before {
              color: #707070 !important;
              transform: translate3d(0, -32px, 0) scale3d(0.72, 0.72, 1);
            }

            div.mce_inline_error {
              position: absolute;
              top: -34px;

              @include responsive-down(tablet) {
                border: 1px solid #6b0505;
                position: relative;
                top: 0;
                margin: 20px 0 0 !important;
                width: max-content;
              }
            }
          }
        }

        button {
          padding: 11px 24px;
          display: flex;
          justify-content: space-between;
          margin: 0 0 0 20px;

          @include responsive-down(tablet) {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
          }
        }
      }

      #mce-success-response {
        margin: 15px 0 1em 0;
        padding: 5px 10px;
        background-color: $success;
        font-weight: bold;
        z-index: 1;
        color: #fff;
      }

      .forms-group {
        width: calc(100% - 180px);
        width: 100%;

        @include responsive-down(mobile-large) {
          width: 100%;
          margin: 0 0 30px;
        }

        input {
          border: 2px solid $mid-gray;
          background-color: $mid-gray;
          color: $white !important;
        }

        .label {
          color: $grayac;
        }
      }

      .btn {
        .flip {
          margin: -10px 0 -10px 4px;
        }
      }

      .checkbox {
        width: 100%;
        padding: 15px 0 0;

        label,
        a {
          color: $grayac;
          font-size: 12px;
        }

        a {
          &:hover {
            @include responsive-up(tablet) {
              color: $white;
            }
          }
        }
      }
    }
  }

  &__content {
    display: flex;

    @include responsive-down(mobile-large) {
      flex-direction: column;
    }
  }

  &__info {
    width: 30%;
    height: 100%;
    margin: 60px 0 0;
    display: flex;
    flex-direction: column;

    @include responsive-down(tablet) {
      width: 40%;
    }

    @include responsive-down(tablet-small) {
      width: 50%;
    }

    @include responsive-down(mobile-large) {
      margin: 40px 0 0;
    }

    .logo {
      width: 140px;
      margin: 0 0 15px;
    }
  }

  &__linkhome {
    width: 120px;
    margin-bottom: 20px;

    @include responsive-down(mobile-large) {
      width: 140px;
    }

    svg {
      fill: $secondary;
    }
  }

  .footerlink {
    color: $white;
    font-size: 12px;
    margin: 0 0 17px;

    &:hover {
      @include responsive-up (tablet) {
        color: $hover-gray-footer;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-start;
    gap: 80px;
    width: 100%;
    margin: 52px 0 0 0;
    padding: 0 0 0 115px;
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: 100%;
      background-color: $mid-gray;
      position: absolute;
      top: 0;
      left: 0;
    }

    @include responsive-down(desktop-extra-small) {
      padding: 0 0 0 90px;
    }

    @include responsive-down(tablet-extra-large) {
      padding: 0 0 0 70px;
    }

    @include responsive-down(tablet-large) {
      padding: 0 0 0 30px;
      gap: 0;
    }

    @include responsive-down(tablet-large) {
      padding: 0 0 0 80px;
      flex-wrap: wrap;
    }

    @include responsive-down(tablet-small) {
      padding: 0 0 0 60px;
    }

    @include responsive-down(mobile-large) {
      padding: 0;
      border: none;
      margin: 40px 0 0;
      gap: 20px;

      &::before {
        display: none;
      }
    }
  }

  &__colnav {
    display: flex;
    flex-direction: column;
    width: calc(100% / 4 - 50px);
    padding: 0 20px 0 0;

    @include responsive-down(tablet-extra-large) {
      width: calc(100% / 4 - 30px);
    }

    @include responsive-down(tablet-large) {
      width: calc(100% / 4 - 10px);
    }

    @include responsive-down(tablet-large) {
      width: calc(100% / 2 - 20px);
      padding: 0 0 50px;
    }

    @include responsive-down(mobile-large) {
      padding: 0 0 32px;
    }

    .titlecol {
      color: $grayac;
      font-size: 16px;
      padding: 0 0 35px;
      margin: 0;

      @include responsive-down(mobile-large) {
        padding: 0 0 25px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0;
  }

  &__icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 10px;
    border-bottom: 1px solid $mid-gray !important;
    align-items: center;

    @include responsive-down(mobile-large) {
      flex-direction: column;
      align-items: flex-start;
      border: none !important;
      padding: 0;
    }

    .social {
      @include responsive-down(mobile-large) {
        text-align: center;
        width: 100%;
        padding: 0 0 30px;
        border-bottom: 1px solid $mid-gray;
      }

      i {
        color: $white;
        font-size: 20px;

        @include transition($transition-slow);

        @include responsive-down(mobile-large) {
          font-size: 24px;
        }
      }

      &__link {
        padding: 0 20px 0 0;
        display: inline-block;

        @include responsive-down(mobile-large) {
          padding: 0 15px 0;
        }

        &:hover {
          @include responsive-up(tablet) {
            i {
              color: $hover-gray-footer;

              @include transition($transition-slow);
            }
          }
        }
      }
    }

    .payments {
      display: flex;
      align-items: center;

      @include responsive-down(mobile-large) {
        padding: 15px 0 0;
      }

      i,
      svg {
        fill: $grayac;
        font-size: 16px;
        font-size: 36px;

        @include transition($transition-slow);

        @include responsive-down(mobile-large) {
          font-size: 40px;
        }

        &.visa {
          width: 38px;
        }

        &.master {
          width: 100px;
        }

        &.paypal {
          width: 55px;
        }

        &.bizum {
          width: 55px;
        }
      }

      &__link {
        padding: 0 0 0 18px;
        display: inline-block;

        @include responsive-down(mobile-large) {
          padding: 0 20px 0 0;
        }
      }
    }
  }

  &__legal {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0;

    @include responsive-down(tablet) {
      flex-direction: column-reverse;
      border: none;
      padding: 40px 0 0;
    }

    .textlegal {
      color: $gray;
      font-size: 10px;
      margin: 0 auto 0 0;
      width: 60%;

      @include responsive-down(tablet-extra-large) {
        width: 50%;
      }

      @include responsive-down(tablet) {
        width: 100%;
      }

      @include responsive-down(mobile-large) {
        padding: 20px 0 0;
        width: 100%;
      }

      &--numericco {
        margin: 10px 0 0;
        display: flex;
        align-items: baseline;

        a {
          color: $white;
          margin: 0 0 0 5px;

          @include transition(all 0.3s ease-in-out);

          &:hover {
            @include responsive-up(tablet-extra-large) {
              color: $gray;

              @include transition(all 0.3s ease-in-out);
            }
          }
        }
      }
    }

    .linklegal {
      color: $grayac;
      font-size: 11px;
      padding: 0 0 0 20px;

      @include responsive-down(tablet) {
        padding: 0 0 20px;
      }

      &:hover {
        @include responsive-up(tablet) {
          color: $white;
        }
      }
    }
  }
}
