/* component::banner full
--------------------------------------------------------------------------------------------------------------------- */
.bannerfull {
  width: 100%;
  margin: 90px 0 50px;
  display: flex;
  justify-content: center;
  height: 800px;
  overflow: hidden;
  position: relative;

  @include responsive-down(tablet-small) {
    margin: 30px 0 50px;
  }

  @include responsive-down(mobile-large) {
    margin: 30px 0;
  }

  &::before {
    content: "";
    background-color: $black03;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    position: absolute;
  }

  &__media {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 140px 0;

    @include responsive-down(tablet-small) {
      padding: 70px 0;
    }

    @include responsive-down(mobile-extra-large) {
      flex-direction: column;
    }
  }

  &__title {
    color: $white;
    width: 40%;

    h3,
    h1,
    h2,
    h4,
    h5,
    p,
    span {
      color: $white;
      font-size: 50px;

      @include responsive-down(tablet-extra-large) {
        font-size: 40px;
      }

      @include responsive-down(tablet-small) {
        font-size: 35px;
      }
    }

    p {
      color: $white;
    }

    @include responsive-down(tablet) {
      width: 45%;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      padding: 0 0 50px;
    }

    @include responsive-down(mobile-large) {
      padding: 0 0 60px;
    }
  }

  &__list {
    width: 42%;

    @include responsive-down(tablet-extra-large) {
      width: 50%;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
    }

    .img {
      width: 40px;
      height: 40px;
      margin: 0 20px 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 0 0 60px;

    &:last-child {
      padding: 0;
    }

    img {
      padding: 0;
    }

    .description {
      color: $white;
      width: calc(100% - 50px);
      padding: 10px 0 0;

      h3,
      h4 {
        font-size: 18px;
        margin: 0 0 22px;
        color: $white;
      }

      p {
        color: $white;
      }
    }
  }
}
