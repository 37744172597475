/* Layouts: Pages: Order Confirmation
--------------------------------------------------------------------------------------------------------------------- */
.page-wrapper--order-confirmation {
  #content-hook_order_confirmation {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;

    @include responsive-down(tablet-extra-small) {
      width: calc(100% - 40px);
      margin: 20px auto;
      padding-top: 20px;
    }

    .card-body {
      display: flex;
      align-items: center;
      flex-direction: column;

      .card-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 18px;
        margin: 0 0 15px;

        i {
          font-size: 20px;
          margin-bottom: 10px;
          color: $success;
        }
      }

      p {
        text-align: center;
        font-size: 13px;
        color: $mid-gray;
      }
    }
  }

  .page-content.page-order-confirmation {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #e3e3e3;
    margin-top: 50px;
    border-radius: 3px;

    @include responsive-down(tablet-extra-small) {
      width: calc(100% - 40px);
      margin: 20px auto;
      padding: 20px;
    }

    #order-items {
      & > .row {
        &:first-child {
          display: none;
        }
      }

      hr {
        display: none;
      }

      .order-confirmation-table {
        .order-line {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          min-height: 100px;
          margin-top: 26px;
          padding-top: 26px;
          border-top: 1px solid #e3e3e3;

          &:first-child {
            border-bottom: none;
            border: none;
            margin: 15px 0 0;
            padding: 0;
          }

          .col-sm-2.col-3 {
            width: 100px;
            margin-right: 15px;

            .image {
              display: flex;
              align-content: center;
              justify-content: center;
              height: 100px;
              width: 100px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: $light-gray;
              }
            }
          }

          .col-sm-4.col-9.details {
            font-size: 13px;
            color: $mid-gray;
            width: calc(100% - 90px);
          }

          .col-sm-6.col-12.qty {
            position: absolute;
            left: 120px;
            font-size: 13px;
            bottom: 20px;

            .row {
              display: flex;
              align-content: center;
              font-size: 16px;

              .col-4.text-sm-center {
                display: none;

                &.text-left {
                  display: block;
                }
              }
            }
          }
        }

        .table {
          width: calc(100% + 60px);
          font-size: 14px;
          padding: 30px;
          margin: 50px -30px 0;
          display: inline-block;
          background-color: $light-gray;

          @include responsive-down(tablet-extra-small) {
            width: calc(100% + 40px);
            margin: 50px -20px 0;
            padding: 30px 20px;
          }

          tbody {
            width: 100%;

            tr {
              min-height: 20px;
              padding-bottom: 10px;

              td {
                color: $black;
                text-align: right;
                width: 100%;

                &:first-of-type {
                  text-align: left;
                  color: $mid-gray;
                }

                &.text-right {
                  font-weight: 700;
                }
              }
            }

            .total-value {
              padding-top: 20px;
              padding-bottom: 0;
              margin-top: 20px;
              display: table-row;
              justify-content: normal;
              width: 100%;

              .text-right {
                font-size: 17px;
                font-weight: 700;
              }

              .text-uppercase {
                padding-top: 20px;
                font-size: 17px;
                color: $black;
                font-weight: 700;

                span {
                  color: $mid-gray;
                  font-size: 11px;
                  display: inline-block;
                  margin-left: 5px;
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }

    #order-details {
      margin-top: 20px;
      padding-top: 20px;
      font-size: 14px;
      border-top: none;

      .card-title {
        font-size: 16px;
        line-height: 14px;
        font-weight: 700;
        font-family: $font-secondary;
      }

      ul {
        margin-top: 10px;
        padding: 0;

        li {
          color: $mid-gray;
          font-size: 13px;
          line-height: 23px;
          padding: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  #content-hook_payment_return {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 60px;

    @include responsive-down(tablet-extra-small) {
      width: calc(100% - 40px);
      margin: 20px auto;
      padding: 30px 0 0;
    }

    p {
      font-size: 13px;
      line-height: 23px;
      margin-bottom: 30px;
      text-align: left;
    }

    dl {
      text-align: left;

      dd,
      dt {
        margin: 0;
        font-size: 13px;
        line-height: 23px;
        padding: 0;
      }

      dt {
        color: $black;
        font-weight: 700;
      }

      dd {
        color: #525252;
      }
    }

    .btn,
    button {
      width: auto;
      font-size: 13px;
      text-transform: inherit;
      line-height: 16px;
      letter-spacing: 0.5px;
      font-weight: 300;
    }
  }

  #content-hook-order-confirmation-footer {
    .featured-products.clearfix {
      height: 100%;
    }
  }

  #registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 60px;

    @include responsive-down(tablet-extra-small) {
      width: calc(100% - 40px);
      margin: 20px auto;
      max-width: none;
    }

    .h4 {
      line-height: 1.2;
      margin: 0 0 20px;
    }

    .form-group {
      margin-bottom: 20px !important;
    }

    .form-footer {
      margin-top: 40px;
    }
  }
}
