/* Layouts: Pages: About us banners
--------------------------------------------------------------------------------------------------------------------- */
.aboutcategories {
  margin: 100px 0;
  padding: 0 0 20px;
  position: relative;

  &__scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;

    &::-webkit-scrollbar {
      display: block;
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $light-gray;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $dark-gray;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $dark-gray;
    }
  }

  &::before {
    content: "";
    width: 100px;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: white;

    @include responsive-down(desktop-large) {
      width: 50px;
    }

    @include responsive-down(tablet) {
      width: 30px;
    }

    @include responsive-down(mobile-large) {
      width: 20px;
    }
  }

  &::after {
    content: "";
    width: 100px;
    height: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1;

    @include responsive-down(desktop-large) {
      width: 50px;
    }

    @include responsive-down(tablet) {
      width: 30px;
    }

    @include responsive-down(mobile-large) {
      width: 20px;
    }
  }

  &__item {
    display: inline-block;
    width: 435px;
    margin: 0 25px 0 0;

    @include responsive-down(mobile-large) {
      width: 400px;
    }

    @include responsive-down(mobile) {
      width: 300px;
    }

    &:first-child {
      margin-left: 100px;
      margin-right: 25px;

      @include responsive-down(desktop-large) {
        margin-left: 50px;
      }

      @include responsive-down(tablet) {
        margin-left: 30px;
      }

      @include responsive-down(mobile-large) {
        margin-left: 20px;
        margin-right: 10px;
      }
    }

    .title {
      font-size: 26px;
      font-family: $font-main;

      @include responsive-down(mobile-large) {
        font-size: 22px;
        margin: 10px 0 30px;
      }
    }
  }

  &__img {
    width: 435px;

    @include responsive-down(mobile-large) {
      width: 400px;
    }

    @include responsive-down(mobile) {
      width: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
