/* component::linklist
--------------------------------------------------------------------------------------------------------------------- */
.linklist {
  padding: 80px 0;

  &__title {
    font-size: 34px;
    padding: 0 0 30px;

    @include responsive-down(tablet) {
      font-size: 28px;
      padding: 0 0 15px;
    }

    @include responsive-down(mobile-large) {
      font-size: 24px;
      padding: 0;
    }
  }

  &__content {
    columns: 4;
    column-gap: 60px;

    @include responsive-down(mobile-extra-large) {
      columns: 3;
    }

    @include responsive-down(mobile) {
      columns: 2;
      column-gap: 0;
    }

    p,
    a {
      margin: 0;
      padding: 10px 0;
      font-size: 16px;
      color: $mid-gray;

      @include responsive-down(mobile-large) {
        font-size: 14px;
      }
    }
  }
}
