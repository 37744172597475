/* component::redes sociales
--------------------------------------------------------------------------------------------------------------------- */
.opinions {
  background-color: $light-gray;
  padding: 50px 0 60px;

  @include responsive-down(tablet-extra-small) {
    padding: 40px 0;
  }

  .container {
    @include responsive-down(desktop-large) {
      max-width: 1600px;
    }

    @include responsive-down(desktop) {
      max-width: 1240px;
    }

    @include responsive-down(desktop-small) {
      max-width: 1200px;
    }
  }

  &__intro {
    display: flex;
    align-items: center;
    padding: 0 0 40px;
  }

  &__img {
    @include responsive-down(mobile-large) {
      img {
        width: 63px;
      }
    }
  }

  p {
    margin: 0;
  }

  .slidercontent {
    position: relative;
  }

  &__slider {
    .itemopinion {
      &:first-child {
        margin-left: calc(((100vw - 1440px) / 2) + 165px);

        @include responsive-up(desktop-large) {
          margin-left: calc(((100vw - 2040px) / 2) + 165px);
        }

        @include responsive-down(desktop-large) {
          margin-left: calc(((100vw - 1600px) / 2) + 49px);
        }

        @include responsive-down(desktop) {
          margin-left: calc(((100vw - 1440px) / 2) + 150px);
        }

        @include responsive-down(tablet-extra-large) {
          margin-left: calc(((100vw - 1320px) / 2) + 110px);
        }

        @media (max-width: 1200px) {
          margin-left: 50px;
        }

        @include responsive-down(tablet) {
          margin-left: 30px;
        }

        @include responsive-down(mobile-large) {
          margin-left: 20px;
        }
      }
    }
  }

  &__text {
    // padding: 0 0 0 20px;

    .valoration {
      font-size: 30px;
      font-family: $font-main;

      @include responsive-down(mobile-large) {
        font-size: 25px;
        line-height: 1.2;
      }

      &--actual {
        font-weight: 700;
      }
    }

    .numbervaloration {
      font-size: 15px;
      font-family: $font-main;

      @include responsive-down(tablet-extra-small) {
        font-size: 14px;
      }
    }

    .stars {
      i {
        font-size: 20px;
        color: $secondary;
        padding: 0 5px 0 0;

        @include responsive-down(tablet-extra-small) {
          font-size: 16px;
        }

        &.icon-mid-star {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &::before {
            z-index: 1;
          }

          &::after {
            content: "\e914";
            font-family: $icomoon;
            font-size: 20px;
            color: #e8e8e8;
            position: absolute;
            z-index: 0;
          }
        }

        &.icon-star--empty {
          color: $light-gray;
        }
      }
    }
  }

  &__slidernav {
    top: 50%;
    bottom: 50%;
    left: 50px;
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    position: absolute;

    @include responsive-down(tablet-extra-small) {
      position: relative;
      justify-content: flex-end;
      padding: 30px 0 0;
      width: 100%;
      left: inherit;
      right: 20px;
      display: inline-flex;
    }

    .prev,
    .next {
      position: relative;
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;

      @include responsive-down(tablet-extra-small) {
        margin: 0 0 0 10px;
      }

      i {
        z-index: 1;

        @include responsive-down(tablet-extra-small) {
          margin: -3px 0 0 -4px;
        }
      }

      &::after {
        content: "";
        background-color: $light-gray;
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        z-index: 0;

        @include transition(all 0.3s ease-in);

        @include responsive-down(tablet-extra-small) {
          background-color: $white;
          height: 46px;
          width: 46px;
        }
      }

      &:hover {
        @include responsive-up(tablet) {
          &::after {
            background-color: $hover-gray;
          }

          @include transition(all 0.3s ease-in);
        }
      }
    }
  }
}
