/* Layouts: Pages: About us spirit
--------------------------------------------------------------------------------------------------------------------- */
.page-opinions {
  .container {
    max-width: 1280px;

    @include responsive-down(desktop-small) {
      max-width: 1150px;
    }
  }

  .opinionsproduct {
    &__title {
      // @include responsive-down(mobile-extra-large) {
      //   text-align: center;
      //   line-height: 1.1;
      //   margin: 0 auto 20px;
      // }

      @include responsive-down(mobile) {
        max-width: none;
      }
    }

    &__intro {
      @include responsive-down(mobile-extra-large) {
        max-width: none;
        text-align: left;
      }
    }

    // &__subtitle {
    //   @include responsive-down(mobile-extra-large) {
    //     text-align: center;
    //     margin: 0 auto;
    //   }
    // }

    &__list {
      @include responsive-down(mobile-extra-large) {
        flex-direction: column;
        overflow: inherit;
        width: 100%;
        margin: 0;
      }
    }
  }

  .itemopinionsproduct {
    @include responsive-down(mobile-extra-large) {
      margin: 0 0 20px;

      &:first-child {
        margin: 0 0 20px;
      }
    }
  }
}
