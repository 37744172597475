/* component::banners
--------------------------------------------------------------------------------------------------------------------- */
.banners {
  width: 100%;
  height: 600px;
  min-height: 610px;
  position: relative;
  display: block;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);

  @include responsive-down(tablet) {
    height: 500px;
    min-height: auto;
  }

  @include responsive-down(mobile-large) {
    height: 450px;
  }

  @include responsive-down(mobile) {
    height: 350px;
  }

  &::before {
    content: "";
    background-color: $black03;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;

    @include transition(all 0.3s ease-in);
  }

  .flip {
    margin: 0 0 -10px;

    &::before {
      color: $white !important;
    }
  }

  .link {
    &::before {
      content: "";
      height: 1px;
      background-color: $white;
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &:hover {
    @include responsive-up(tablet) {
      @include transition(all 0.3s ease-in);

      &::before {
        background-color: $black05;

        @include transition(all 0.3s ease-in);
      }

      .banners__img {
        transform: scale(1.1);
      }

      .link {
        color: $white;

        &::before {
          content: "";
          width: 100%;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      .flip {
        &::before {
          opacity: 0;
          transform: translateY(-50%) rotateX(90deg);
        }

        &::after {
          opacity: 1;
          transform: translateY(0) rotateX(0);
          color: $white;
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);

    @include transition(all 0.3s ease-in);
  }

  &__content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include responsive-down(mobile-large) {
      padding: 20px;
    }
  }

  &__top {
    max-width: 400px;

    @include responsive-down(mobile) {
      max-width: 280px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .banners__title {
      font-size: 50px;
      font-weight: 500;
      font-family: $font-main;
      color: $white;
      margin: 0;
      line-height: 1;

      @include responsive-down(tablet) {
        font-size: 38px;
      }

      @include responsive-down(mobile-large) {
        margin: 5px 0 0;
      }

      @include responsive-down(mobile) {
        font-size: 32px;
      }
    }

    p,
    .banners__description {
      font-size: 16px;
      color: $white;
      line-height: 1.3;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    i {
      color: $white;
    }
  }

  &__link {
    margin: 0;
    line-height: 1;
    font-size: 20px;
    color: $white;
    font-weight: 600;

    @include responsive-down(mobile-large) {
      font-size: 16px;
    }

    &::before {
      background-color: $white;

      @include responsive-down(tablet-extra-small) {
        font-size: 15px;
      }
    }
  }
}
