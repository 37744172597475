/* component::item slider home
--------------------------------------------------------------------------------------------------------------------- */
.itemsliderinitial {
  position: relative;
  height: 700px;

  @include responsive-down(mobile-large) {
    height: 90vh;
  }

  &::before {
    content: "";
    background-color: $black03;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }

  &__img {
    display: flex;
    align-items: center;
    height: 100%;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    bottom: 60px;
    left: 50px;
    max-width: 550px;
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
    animation-name: opacityTitleHome;
    animation-duration: 1.5s;
    animation-timing-function: ease;

    @include responsive-down(tablet) {
      left: 30px;
    }

    @include responsive-down(tablet-extra-small) {
      max-width: 500px;
    }

    @include responsive-down(mobile-large) {
      left: 20px;
      max-width: 380px;
      bottom: 60px;
    }

    @include responsive-down(mobile) {
      max-width: none;
      width: 100%;
      padding: 0 40px 0 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .title {
      color: $white;
      font-size: 50px;
      font-weight: 500;
      line-height: 0.9;
      margin: 0 0 30px;

      @include responsive-down(tablet) {
        padding-right: 60px;
        font-size: 40px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 0 10px;
        font-size: 35px;
      }
    }

    p {
      color: $white;
      line-height: 1.5;
      margin: 0 0 30px;

      @include responsive-down(mobile-large) {
        line-height: 1.4;
      }
    }
  }
}

@keyframes opacityTitleHome {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  50% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
