/* component::another models
--------------------------------------------------------------------------------------------------------------------- */
.anothermodels {
  padding: 100px 0;
  display: flex;

  @include responsive-down(tablet) {
    flex-direction: column;
    position: relative;
  }

  @include responsive-down(mobile-large) {
    padding: 40px 0 150px;
  }

  &__intro {
    margin-left: calc(((100vw - 1520px) / 2) + 0px);
    margin-right: 60px;

    @include responsive-down(desktop) {
      margin-left: calc(((100vw - 1520px) / 2) + 140px);
    }

    @include responsive-down(desktop-extra-small) {
      margin-left: calc(((100vw - 1520px) / 2) + 210px);
    }

    @include responsive-down(tablet-extra-large) {
      margin-left: 50px;
      width: 350px;
    }

    @include responsive-down(tablet-large) {
      width: 450px;
      margin-right: 40px;
    }

    @include responsive-down(tablet) {
      margin-left: 30px;
      width: auto;
    }

    @include responsive-down(mobile-large) {
      margin-left: 20px;
    }

    .slider__nav {
      @include responsive-down(tablet) {
        position: absolute;
        bottom: 60px;
        right: 20px;
      }

      .prev,
      .next {
        position: relative;
        width: 50px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        margin: 0 10px 0 0;

        @include responsive-down(tablet-extra-small) {
          margin: 0 0 0 5px;
        }

        i {
          z-index: 0;

          @include responsive-down(tablet-extra-small) {
            margin: -3px 0 0 -3px;
          }
        }

        &::after {
          content: "";
          background-color: $light-gray;
          position: absolute;
          top: 0;
          left: 0;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          z-index: -1;

          @include transition(all 0.3s ease-in);

          @include responsive-down(tablet-extra-small) {
            height: 46px;
            width: 46px;
          }
        }

        &:hover {
          @include responsive-up(tablet) {
            &::after {
              background-color: $hover-gray;
            }

            @include transition(all 0.3s ease-in);
          }
        }
      }
    }
  }

  &__title {
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 30px;
    max-width: 350px;
    font-family: $font-main;

    @include responsive-down(mobile-large) {
      max-width: initial;
    }
  }

  &__list {
    width: 100%;

    @include responsive-down(tablet) {
      width: 100%;
      padding: 0;
    }

    .itemproduct {
      background-color: transparent;
      padding: 0;

      &:first-child {
        @include responsive-down(tablet) {
          margin: 0 0 0 30px;
        }

        @include responsive-down(tablet-extra-small) {
          margin: 0 0 0 20px;
        }
      }

      &__title {
        margin: 10px 0 0;
        min-height: 70px;

        @include responsive-down(tablet-extra-small) {
          min-height: 80px;
          margin: 0;
          line-height: 1.4;
        }

        @include responsive-down(mobile) {
          min-height: 60px;
        }
      }

      .characteristic {
        display: none;
      }

      &__img {
        background-color: $light-gray;
        padding: 5px 18px 18px;

        @include responsive-down(tablet-extra-small) {
          padding: 8px;
          height: 140px;
        }
      }

      &__content {
        background-color: $light-gray;
        padding: 18px;

        @include responsive-down(tablet-extra-small) {
          padding: 10px;
        }
      }

      &__bottom {
        height: auto;
        padding: 0;

        @include transition(height 0.325s ease-in);

        .btn--buy {
          display: none;
        }
      }

      &__prices {
        padding: 12px 0 0;
        animation: none;

        @include responsive-down(mobile) {
          flex-direction: column;
          align-items: flex-start;
          min-height: 40px;
          height: 64px;

          .price {
            padding: 5px 10px 0 0;
          }
        }
      }

      &:hover {
        @include responsive-up(tablet) {
          .itemproduct__bottom {
            height: auto;
          }

          .itemproduct__prices {
            padding-bottom: 0;
            animation: none;
          }
        }
      }
    }
  }
}
