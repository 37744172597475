/* Layouts: Pages: Notifications
--------------------------------------------------------------------------------------------------------------------- */
.notifications-container {
  width: auto;
  max-width: 1400px;
  margin: 0 auto;
  // padding: 0 0 25px;
  position: relative;

  &:empty {
    display: none;
    padding: 0;
  }

  article {
    width: auto;
    display: inline-block;
    padding: 8px 24px;
    border-radius: 3px;
    background-color: $gray;
    border: 1px solid $light-gray;

    ul {
      padding: 0;
      margin: 0;

      li {
        font-size: 12px;
        color: $green;
        padding: 0;

        &::before {
          display: none;
        }
      }
    }

    &.alert-danger {
      background-color: $danger;
      border: 1px solid rgba(166, 1, 37, 0.4);

      ul {
        li {
          color: $red;
        }
      }
    }
  }
}

.alert {
  &[data-alert="success"] {
    padding: 5px 15px;
    background: $green-news;
    border-radius: 3px;
    font-size: 12px;
    width: auto;
    color: $green;
  }
}
