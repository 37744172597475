/* common::variables
--------------------------------------------------------------------------------------------------------------------- */

// common::variables::breakpoints
$desktop-extra-large: 2560px;
$desktop-large: 1919px;
$desktop: 1600px;
$desktop-small: 1440px;
$desktop-extra-small: 1366px;
$tablet-extra-large: 1280px;
$tablet-large: 1080px;
$tablet: 991px;
$tablet-small: 860px;
$tablet-extra-small: 768px;
$mobile-extra-large: 720px;
$mobile-large: 640px;
$mobile: 540px;
$mobile-small: 480px;
$mobile-extra-small: 375px;

// common::variables::typography
$font-main: 'Britanica', sans-serif;
$font-secondary: 'Manrope', sans-serif;
$icomoon: 'icomoon', sans-serif;
$letter-spacing: 0.03rem;
$letter-spacing-2x: 0.06rem;
$letter-spacing-big: 0.1rem;

// common::variables::colors::base
$primary: #004ea8;
$primary-light: #4176b2;
$primary-dark: #004491;
$secondary: #ffdf00;
$red: #a73535;
$green: #5ead3b;
$green-news: #f2f7ed;
$white: #fff;
$white05: rgba(255, 255, 255, 0.5);
$background-gray: #fafafa;
$dark-gray: #393939;
$mid-gray: #525252;
$gray: #8d8d8d;
$hover-gray: #e5e5e5;
$hover-gray-footer: #b1b1b1;
$light-gray: #f7f7f7;
$light-black: #393939;
$mid-black: #111;
$black: #000;
$black08: rgba(0, 0, 0, 0.8);
$black05: rgba(0, 0, 0, 0.5);
$black03: rgba(0, 0, 0, 0.3);
$blockquote:#9d9d9d;
$grayac: #acacac;

// common::variables::colors::text
$title-color: $dark-gray;
$text-color: $light-black;
$post-color: $dark-gray;

// common::variables::colors::alerts
$success: #8ac148;
$success-hover: #599014;
$info: #00a9f4;
$info-hover: #007ac1;
$warning: #ff9800;
$warning-hover: #c66900;
$danger: #6b0505;
$danger-hover: #ab000e;

//TRANSITIONS
$transition-slow: all 0.3s ease-in;
$transition-medium: all 0.2s ease 0s;
$transition-fast: all 100ms ease-in;
$transition-moreslow: all 0.5s ease-in;
