/* component::section blog
--------------------------------------------------------------------------------------------------------------------- */
.sectionblog {
  padding: 50px 0;

  @include responsive-down(tablet-extra-small) {
    position: relative;
  }

  &__intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .btn {
      @include responsive-down(tablet-extra-small) {
        position: absolute;
        bottom: -5px;
        margin: 20px 0 10px;
      }
    }
  }

  &__description {
    max-width: 550px;
  }

  &__title {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin: 0 -15px;
    padding: 25px 0 0;

    @include responsive-down(tablet-extra-large) {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      position: relative;
      display: inherit;
      margin: 0 -50px;
      width: calc(100% + 100px);
      padding: 25px 0 0;
    }

    @include responsive-down(tablet) {
      margin: 0 -30px;
      width: calc(100% + 60px);
    }

    @include responsive-down(mobile-large) {
      margin: 0 -20px;
      width: calc(100% + 40px);
      padding: 5px 0 0;
    }
  }

  &__item {
    width: calc(100% / 4);
    margin: 0 15px;

    @include responsive-down(tablet-extra-large) {
      display: inline-block;
      width: 300px;
      margin: 0 30px 0 0;

      &:first-child {
        margin: 0 30px 0 50px;
      }
    }

    @include responsive-down(tablet) {
      margin: 0 30px 0 0;

      &:first-child {
        margin: 0 30px;
      }
    }

    @include responsive-down(mobile-large) {
      margin: 0 20px 0 0;

      &:first-child {
        margin: 0 20px;
      }
    }

    .img {
      width: 100%;
      height: 200px;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .number {
      display: none;
      margin: 0;
      font-family: $font-main;
      font-size: 28px;

      @include responsive-down(tablet-extra-small) {
        display: block;
        margin: 14px 0 10px;
        line-height: 1;
      }
    }

    .description {
      font-size: 16px;
      white-space: pre-wrap;

      @include responsive-down(tablet-extra-small) {
        font-size: 14px;
      }
    }
  }
}
