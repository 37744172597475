/* component::intro product
--------------------------------------------------------------------------------------------------------------------- */
.modaladdcart {
  &__intro {
    width: 100%;
    background-color: $light-gray;
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive-down(mobile-large) {
      padding: 20px 30px;
      flex-direction: column;
    }

    i {
      color: $success;
      font-size: 18px;
      margin: 0 10px 0 0;

      @include responsive-down(mobile-large) {
        margin: 0 0 10px;
      }
    }

    .title {
      margin: 0;
      font-size: 16px;

      @include responsive-down(mobile-large) {
        font-size: 14px;
        line-height: 1.3;
        max-width: 270px;
        margin: 0 auto;
      }
    }
  }

  &__bottom {
    padding: 0 40px 40px;
    width: 100%;

    @include responsive-down(mobile-large) {
      padding: 0 20px 20px;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;

    @include responsive-down(mobile-large) {
      flex-direction: column;
      margin: 0;
    }

    .btn {
      width: 100%;
      margin: 0 10px;
      padding: 0;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive-down(mobile-large) {
        min-height: 36px;
        font-size: 14px;
        margin: 15px 0 0;
      }
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 40px 0;

    @include responsive-down(mobile-large) {
      padding: 25px 0;
    }

    .product-price {
      flex-direction: column;
      margin: 0 0 0 auto;
    }

    .discount-percentage {
      display: none;
    }

    .product-name {
      margin: 0 0 0 20px;
      max-width: 60%;
    }

    .img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      background-color: $light-gray;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .introproduct__prices {
    animation: none;
    margin: 0 0 0 auto;

    .iva {
      display: none;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
