/* component::redes sociales
--------------------------------------------------------------------------------------------------------------------- */
.itemopinion {
  padding: 20px;
  background-color: $white;
  min-height: 220px;
  margin: 0 10px 0 0;

  p {
    margin: 0;
  }

  .date {
    color: $gray;
    font-size: 14px;
    margin: 0 0 10px;
    display: flex;
    align-items: center;

    i {
      color: $success;
      font-size: 12px;
      margin: 0 0 0 5px;
    }
  }

  .description {
    font-size: 12px;
    margin: 10px 0 0;
  }
}
