/* component::pagination
--------------------------------------------------------------------------------------------------------------------- */
.pagination {
  width: 100%;
  padding: 30px 0 0;

  &__title {
    font-size: 12px;
    text-align: center;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0 0;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px auto;
    padding: 0;
  }

  &__item {
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0 10px;
    padding: 3px;

    @include transition($transition-slow);

    &:hover {
      @include responsive-up(tablet) {
        background-color: #f6f6f6;
        border-color: #f6f6f6;

        @include transition($transition-slow);

        a {
          color: $black;
        }
      }
    }

    &::before {
      display: none;
    }

    &.active {
      background-color: #f6f6f6;
      border-color: #f6f6f6;
    }

    a {
      font-size: 18px;
      padding: 2px 6px;

      i {
        font-size: 14px;
      }
    }
  }
}
