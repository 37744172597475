/* component::subcategory
--------------------------------------------------------------------------------------------------------------------- */
.subcategorylist {
  position: relative;

  &__scrollbar {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include responsive-down(tablet-large) {
      margin: 0 0 0 15px;
      // display: inherit;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      justify-content: flex-start;
      padding: 0 0 30px;

      &::-webkit-scrollbar {
        display: block;
        height: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $light-gray;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $dark-gray;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $dark-gray;
      }
    }
  }

  @include responsive-down(mobile) {
    padding: 0 0 20px;
  }

  &::before {
    content: "";
    width: 100px;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: -10px;
    z-index: 1;
    background-color: white;

    @include responsive-down(desktop-large) {
      width: 50px;
    }

    @include responsive-down(tablet) {
      width: 30px;
    }

    @include responsive-down(mobile-large) {
      width: 20px;
    }

    @include responsive-down(mobile) {
      bottom: 10px;
    }
  }

  &::after {
    content: "";
    width: 100px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: -10px;
    background-color: white;
    z-index: 1;

    @include responsive-down(desktop-large) {
      width: 50px;
    }

    @include responsive-down(tablet) {
      width: 30px;
    }

    @include responsive-down(mobile-large) {
      width: 20px;
    }

    @include responsive-down(mobile) {
      bottom: 10px;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
    cursor: pointer;

    @include responsive-down(desktop-large) {
      margin: 0 5px;
      display: inline-flex;
    }

    &:hover {
      @include responsive-up(tablet) {
        .subcategorylist__name {
          span {
            &::before {
              width: 100%;
            }
          }
        }

        .subcategorylist__img {
          transform: translateY(-7px);
        }

        @include transition($transition-slow);
      }
    }

    @include transition($transition-slow);
  }

  &__name {
    font-size: 16px;
    text-align: center;
    margin: 6px 0 0;
    line-height: 1.4;
    width: 130px;
    white-space: initial;

    @include transition($transition-slow);

    @include responsive-down(tablet-extra-large) {
      width: 120px;
      font-size: 14px;
    }

    @include responsive-down(tablet) {
      font-size: 14px;
      width: 100px;
    }

    span {
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 1px;
        width: 0;
        background-color: $mid-gray;

        @include transition($transition-slow);
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: 95px;
    text-align: center;
    object-fit: contain;

    @include transition($transition-slow);

    @include responsive-down(tablet-extra-large) {
      max-width: 75px;
    }

    @include responsive-down(mobile-large) {
      max-width: 70px;
    }
  }
}
