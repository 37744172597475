.itemopinionsproduct {
  display: flex;
  padding: 30px 40px;
  background-color: $light-gray;
  margin: 0 0 20px;

  @include responsive-down(tablet) {
    padding: 20px;
  }

  @include responsive-down(mobile-extra-large) {
    min-width: 250px;
    flex-direction: column;
    margin: 0 10px 0 0;

    &:first-child {
      margin: 0 10px 0 20px;
    }
  }

  &:hover {
    @include responsive-up(tablet) {
      .linkproduct {
        opacity: 1;
      }
    }
  }

  &__info {
    .date {
      display: inline-flex;
      align-items: center;
      color: $grayac;
      margin: 0 0 10px;

      i {
        padding: 0 0 0 8px;
        color: $green;
      }
    }
  }

  &__description {
    padding: 0 40px;

    @include responsive-down(mobile-extra-large) {
      padding: 0;
    }

    .name {
      margin: 0 0 10px;

      @include responsive-down(mobile-extra-large) {
        padding: 0;
        font-size: 12px;
        margin: 10px 0 5px;
      }
    }

    .description {
      font-size: 12px;
    }

    .linkproduct {
      margin: 10px 0 20px;
      font-size: 14px;
      display: inline-block;
      opacity: 1;
      text-decoration: underline;
      color: $mid-gray;

      @include responsive-up(tablet) {
        opacity: 0;
        margin: 10px 0;
      }
    }
  }

  &__valoration {
    i {
      font-size: 12px;
      color: $secondary;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    width: 100px;
  }
}
