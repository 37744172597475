/* component::banner full
--------------------------------------------------------------------------------------------------------------------- */
.technicalspecs {
  width: 100%;
  background-color: $light-gray;
  padding: 80px 30px;

  @include responsive-down(tablet) {
    padding: 80px 0;
  }

  @include responsive-down(tablet-extra-small) {
    padding: 50px 0;
  }

  &__title {
    font-size: 34px;
    font-family: $font-main;
    line-height: 1.2;
    margin: 0 0 10px;
  }

  &__intro {
    width: 50%;
    padding: 0;

    @include responsive-down(tablet-large) {
      width: 60%;
    }

    @include responsive-down(tablet-extra-large) {
      width: 70%;
    }

    @include responsive-down(mobile-large) {
      width: 100%;
    }

    .product-description {
      font-size: 14px;
      line-height: 1.6;
      color: $mid-gray;
      font-family: $font-secondary;
    }
  }

  &__titlegroup {
    font-size: 16px;
    font-weight: 700;
    padding: 0 0 16px;
    position: relative;
    color: $dark-gray;
    margin: 0 0 14px;

    @include responsive-down(tablet-extra-small) {
      padding: 30px 0 16px;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 0 0 16px;
    }

    &::before {
      content: "";
      height: 1px;
      width: 100%;
      background-color: $hover-gray;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__list {
    padding: 80px 0;
    columns: 2;

    @include responsive-down(tablet-extra-small) {
      padding: 10px 0;
    }

    @include responsive-down(mobile-extra-large) {
      columns: 1;
    }

    .value,
    .name {
      font-size: 14px;
      display: inline-block;
      width: 50%;
    }

    .name {
      color: $mid-gray;
    }

    .value {
      color: $black;
    }
  }

  &__item {
    padding: 0 10px 50px;
    page-break-inside: avoid;
    break-inside: avoid;

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      padding: 0 0 20px;
    }

    &--attachment {
      display: flex;
      flex-wrap: wrap;

      .technicalspecs__titlegroup {
        width: 100%;
      }

      .attachment {
        display: flex;
        margin: 20px 10px 0 0;

        .btn {
          display: inline-flex;
          align-items: center;

          i {
            margin: 0 0 0 9px;
            font-size: 14px;
          }
        }
      }
    }
  }

  &__characteristic {
    display: flex;
    padding: 12px 0;
  }
}
