/* Layouts: Above the fold
--------------------------------------------------------------------------------------------------------------------- */
#idxrcookies {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.2);

  #top,
  #bottom,
  #center {
    box-shadow: none !important;
    display: flex;
    align-items: center;
    position: initial !important;
    padding: 5px 30px;
    max-width: 600px;
    margin: 1.75rem auto !important;
    min-height: calc(100% - 3.5rem);
    background-color: transparent !important;

    @include responsive-down(mobile-large) {
      padding: 5px 20px !important;
    }

    @include responsive-down(mobile-small) {
      padding: 5px 20px !important;
    }

    .contenido {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 40px;
      background-color: $light-black !important;

      @include responsive-down(tablet-extra-large) {
        padding: 30px;
      }

      &::before {
        display: none;
      }

      @include responsive-down(tablet-extra-small) {
        flex-direction: column;
      }

      @include responsive-down(mobile-large) {
        padding: 30px 20px;
      }

      #textDiv {
        margin: 0 !important;
        padding: 0 !important;

        @include responsive-down(tablet) {
          max-width: 100%;
        }

        br {
          display: none;
        }

        p {
          color: $white !important;
          font-weight: 400 !important;
          text-align: left !important;
          margin: 0 0 30px !important;
          font-size: 14px;
          line-height: 1.5;

          @include responsive-down(tablet) {
            max-width: 100% !important;
            font-size: 12px !important;
          }

          @include responsive-down(mobile) {
            line-height: 18px !important;
            padding: 0 !important;
          }
        }
      }

      #buttons {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0;
        width: 100%;

        @include responsive-down(tablet-extra-small) {
          padding: 0;
          width: 100%;
        }

        .btn-cookies {
          color: white !important;

          .cookies-conf {
            display: block;

            @include responsive-down(tablet) {
              display: block !important;
            }
          }

          .cookies-icon {
            display: none;
          }
        }

        #idxrcookiesOK {
          width: 100%;
          padding: 10px;
          font-size: 13px;
          background-color: $primary;
          border-radius: 5px;

          @include responsive-down(tablet-extra-small) {
            margin: 0;
            width: 100%;
            margin-top: 10px;
          }

          @include responsive-down(mobile) {
            margin-top: 0;
          }

          &:hover {
            @include responsive-up(tablet) {
              background-color: $primary-dark !important;
              color: white !important;
              opacity: 1;
            }
          }
        }

        #cookiesConf {
          width: auto;
          font-size: 13px;
          margin: 10px 0 0;
          padding: 0;
          color: white;
          background-color: transparent;
          text-decoration: underline;

          @include responsive-down(tablet-extra-small) {
            width: 100%;
            margin: 10px 0 0;
          }

          @include responsive-down(mobile) {
            margin-top: 10px !important;
          }

          &:hover {
            @include responsive-up(tablet) {
              background-color: transparent;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.fancybox-overlay.fancybox-overlay {
  .fancybox-wrap {
    @include responsive-down(tablet-extra-small) {
      width: calc(100% - 40px) !important;
      left: 20px !important;
    }

    @include responsive-down(mobile-large) {
      top: 180px !important;
    }

    @include responsive-down(mobile-small) {
      top: 110px !important;
    }

    .fancybox-skin {
      border-radius: 0 !important;

      .fancybox-inner {
        background-color: white !important;

        @include responsive-down(mobile) {
          height: auto !important;
        }
      }

      a.fancybox-item.fancybox-close {
        background-image: none !important;
        top: 2px !important;
        right: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: "\e903";
          position: absolute;
          font-family: $icomoon;
          font-size: 15px;
          color: $black;
        }
      }
    }
  }
}

#cookieModal {
  grid-template-rows: auto 1fr auto !important;

  @include responsive-down(tablet-extra-small) {
    grid-template-columns: 120px auto !important;
  }

  @include responsive-down(mobile) {
    grid-template-areas: "h h" "b b" "f f" !important;
  }

  #cookieModalHeader {
    background-color: white;
    color: $black;
    padding: 30px;
    border-bottom: none;

    @include responsive-down(mobile) {
      font-size: 13px;
      padding: 20px 20px 10px 20px;
    }

    img {
      display: none;
    }
  }

  #cookieModalBody {
    background-color: white;

    @include responsive-down(tablet-extra-small) {
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }

    #cookieModalList {
      ul {
        @include responsive-down(tablet-extra-small) {
          overflow-x: auto;
          white-space: nowrap;
        }

        @include responsive-down(tablet-extra-small) {
          &::-webkit-scrollbar {
            width: 5px;
            height: 2px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
          }

          &::-webkit-scrollbar-thumb {
            background-color: $primary;
          }
        }

        .dlxctab-row {
          padding: 17px 30px;
          border: none;
          width: 170px;
          background-color: $light-gray;
          margin-bottom: 2px;
          color: $black;

          &::before {
            display: none;
          }

          @include responsive-down(tablet-extra-small) {
            width: auto;
            display: inline-block;
            padding: 10px 0 5px;
            margin: 0 10px 8px 10px;
            background-color: white;
          }

          @include responsive-down(mobile) {
            font-size: 12px !important;
          }

          &.active {
            background-color: white;
            color: $black;
            font-weight: normal;

            @include responsive-down(tablet-extra-small) {
              color: $primary;
            }
          }

          &:nth-child(1) {
            @include responsive-down(tablet-extra-small) {
              margin-left: 30px;
            }

            @include responsive-down(mobile) {
              margin-left: 20px;
            }
          }

          &:last-child {
            @include responsive-down(tablet-extra-small) {
              margin-right: 30px;
            }

            @include responsive-down(mobile) {
              margin-right: 20px;
            }
          }
        }
      }
    }

    #cookieModalContent {
      padding: 0 20px;
      width: 100%;

      @include responsive-down(mobile) {
        padding: 0 10px;
      }

      p {
        font-size: 13px;

        @include responsive-down(mobile-large) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .dlxctab-content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0 0 15px;
          font-size: 19px;

          @include responsive-down(mobile-large) {
            font-size: 16px;
            margin: 0 0 10px;
          }
        }
      }

      .cookie-content-title {
        border-bottom: none;
        font-size: 17px !important;

        @include responsive-down(tablet-extra-small) {
          margin: 20px 0 15px;
        }

        @include responsive-down(mobile-large) {
          margin: 10px 0 9px;
          font-size: 15px !important;
        }
      }

      .always-active {
        color: $primary;
      }

      .always-check {
        border-color: $primary;
      }

      .cookie-content-subtitle {
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      .info-cookie-list {
        li {
          border: none;
          padding: 6px 0;

          @include responsive-down(mobile-large) {
            padding: 4px 0;
          }

          label {
            font-size: 13px;

            @include responsive-down(mobile-large) {
              font-size: 10px;
            }
          }

          input {
            padding: 8px 4px !important;
            height: 5px !important;
            width: 36px !important;

            &.switch {
              &:checked {
                box-shadow: inset 20px 0 0 1px $primary !important;
                border: 1px solid $primary-dark !important;
              }
            }
          }
        }
      }
    }
  }

  #cookieModalFooter {
    background-color: white;
    padding: 20px;

    @include responsive-down(mobile-large) {
      padding: 0 20px 20px 15px;
    }

    @include responsive-down(mobile-small) {
      padding: 0 10px 20px 5px;
    }

    .cookie-info-page {
      margin-left: 0 !important;
    }

    .btn-config {
      padding: 10px;
      font-size: 13px;
      background-color: $primary;
      border-radius: 5px;
      margin: 0;
      width: 120px;
      color: white !important;
      text-align: center;

      &:hover {
        @include responsive-up(tablet) {
          background-color: $primary-dark !important;
        }
      }
    }
  }
}
