/* component::filters
--------------------------------------------------------------------------------------------------------------------- */
.only-desktop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 40px 0 20px;

  @include responsive-down(tablet-extra-small) {
    display: none;
  }
}

.btnfilters {
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  font-size: 15px;

  @include responsive-up(tablet) {
    cursor: pointer;
    font-size: 12px;
    padding: 40px 0 0;
  }

  & > span {
    line-height: 12px;
    font-size: 18px;
    margin-right: 8px;
    color: $mid-gray;
  }

  .filters-icon {
    svg {
      * {
        transition: 0.15s cubic-bezier(0.35, 0.35, 0, 1);
      }
    }

    circle {
      will-change: transform;
      stroke-width: 2px;
    }

    rect {
      transform-origin: 50% 50%;
    }
  }

  .js-filters-mobile & {
    display: flex;

    @include responsive-up(tablet-extra-small) {
      display: none;
    }
  }

  .js-filters-desktop & {
    display: none;

    @include responsive-up(tablet-extra-small) {
      display: flex;
    }
  }

  &:hover {
    @include responsive-up(tablet) {
      .fltr-crcl1 {
        transform: translateX(-15px);
      }

      .fltr-crcl2 {
        transform: translateX(15px);
      }
    }
  }
}

.filters {
  position: fixed;
  right: -100%;
  top: 0;
  width: 370px;
  height: 100%;
  background-color: $white;
  z-index: 30;
  padding: 20px 0;
  bottom: 0;

  @include transition(all 0.3s ease-in);

  @include responsive-down(mobile) {
    width: 100%;
  }

  &.visible--desktop,
  .visible--desktop {
    display: block !important;
  }

  &__content {
    height: 100%;

    .filterscroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100% - 90px);
      padding: 0 4px 0 0;
      margin: 0 -4px 0 0;
      white-space: nowrap;
    }
  }

  .titlefilter {
    font-family: $font-main;
    margin: -20px 0 0;
    padding: 15px 20px;
    background-color: $light-gray;
    font-size: 26px;
    display: flex;
    align-items: center;

    i {
      margin: 0 0 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: center;
      z-index: 1;
      cursor: pointer;

      &::before {
        z-index: 1;
      }

      &::after {
        content: "";
        width: 30px;
        height: 30px;
        background-color: $white;
        position: absolute;
        border-radius: 50px;
      }
    }
  }

  .btndelete-filters {
    text-decoration: underline;
  }

  &__clearall {
    margin: 0 0 10px;
    border-bottom: 1px solid $hover-gray !important;
    padding: 20px;

    .filtersapplied {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      &__item {
        padding: 6px;
        background-color: $light-gray;
        width: max-content;
        font-size: 14px;
        margin: 0 12px 12px 0;

        a {
          margin: 0 0 0 5px;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  &__tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @include transition($transition-slow);

    .title {
      font-size: 20px;
      color: $mid-gray;
      font-family: $font-main;
      margin: 0;
      padding: 10px 0;
    }

    i {
      font-size: 16px;
      color: $mid-gray;
    }

    .arrow-tab {
      transform: rotate(135deg);

      @include transition($transition-slow);
    }
  }

  &__tabcontent {
    display: none;
    cursor: pointer;

    &--slider {
      padding: 0;
      margin: 0;
      margin: 0 2px;

      li {
        width: 95%;
        padding: 0;

        &::before {
          display: none;
        }
      }

      .filterpriceslider {
        margin: 20px 0;
      }

      .ui-slider.ui-slider {
        height: 3px !important;
        border: none;
        background: $light-gray;
      }

      .ui-slider-range {
        background-color: $dark-gray !important;
      }

      .ui-slider-handle {
        width: 20px !important;
        height: 20px !important;
        border-radius: 50px;
        background-color: $primary !important;
        border: none !important;
      }
    }

    &--select {
      .custom-select {
        width: 100%;
        font-size: 14px;
        border-bottom: 1px solid $hover-gray;
        border-radius: 0;
        background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
        background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: white;
        display: inline-block;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;
      }
    }

    .custom-checkbox,
    .custom-radio {
      margin: 0 20px 10px 0;
    }
  }

  &__block {
    width: 100%;
    padding: 0 20px;

    &--order {
      width: 100%;

      .facet__header {
        margin-bottom: 10px;
      }

      .collpase--facet {
        align-items: center;
        width: calc(100% + 40px);
        margin: 0 -20px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        flex-direction: row !important;

        &::-webkit-scrollbar {
          display: none;
        }

        .custom-control {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          position: relative;
          margin: 0 0 11px 11px;

          &:hover {
            @include responsive-up(tablet) {
              .custom-control-label {
                background-color: $primary;
                color: $white;

                @include transition(all 0.2s ease-in);
              }
            }
          }

          &:first-child {
            margin-left: 10px;
          }

          &:last-child {
            margin-right: 20px;
          }

          &:nth-child(2) {
            display: none;
          }

          input[type="checkbox"],
          input[type="radio"] {
            appearance: none;
            border: 1px solid transparent;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            z-index: 8;
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
          }

          .custom-control-label {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 35px;
            height: 30px;
            font-size: 14px;
            padding: 10px;
            background-color: $hover-gray;
            border-radius: 4px;
            z-index: 7;
            cursor: pointer;

            @include transition(all 0.2s ease-in);
          }

          &--active {
            .custom-control-label {
              background-color: $black;
              color: $white;
            }
          }
        }

        select {
          width: 100%;
          padding: 5px;
        }
      }
    }

    &--opened {
      .arrow-tab {
        transform: rotate(225deg);

        @include transition($transition-slow);
      }

      .filters__tabcontent {
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 10px;
      }
    }
  }

  .showresult {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  &--opened {
    right: 0;
    left: auto;
    display: block;

    @include transition(all 0.3s ease-in);
  }

  &.visible-desktop {
    display: block !important;
  }
}
