/* component::intro subcategory
--------------------------------------------------------------------------------------------------------------------- */
.subcategory-container {
  animation: slide-top-intro 1.3s ease;
}

.introsubcategory {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  max-width: 740px;

  @include responsive-down(tablet) {
    padding: 10px 0 0;
  }

  @include responsive-down(tablet-extra-small) {
    padding: 40px 0 0;
  }

  @include responsive-down(mobile-large) {
    text-align: left;
    padding: 30px 0 0;
  }

  .title {
    text-align: center;
    margin: 0 0 20px;
    font-size: 26px;

    @include responsive-down(tablet) {
      font-size: 24px;
    }

    @include responsive-down(mobile-large) {
      font-size: 24px;
      text-align: left;
      margin: 0 0 15px;
    }
  }
}
