/* component::subcategory
--------------------------------------------------------------------------------------------------------------------- */
.subcategoryproducts {
  @include responsive-down(tablet-extra-small) {
    padding: 40px 0 0;
  }

  .itemproduct {
    width: calc(100% / 3);
    margin: 0 3px 20px;
  }

  .productgrid {
    width: 100%;
  }
}

.container-list {
  @include responsive-up(desktop-extra-small) {
    max-width: 1330px !important;
  }

  @include responsive-up(desktop) {
    max-width: 1920px !important;
  }
}
