/* component::intro product
--------------------------------------------------------------------------------------------------------------------- */
.introproduct {
  width: 100%;
  height: calc(100vh - 91px);
  background-color: $light-gray;
  position: relative;

  @include responsive-down(tablet-extra-large) {
    overflow: hidden;
  }

  @include responsive-down(tablet-extra-small) {
    height: 100%;
    padding: 0 0 40px;
  }

  & > .visible--desktop {
    display: block !important;
  }

  .container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 100px);
    position: absolute;
    flex-direction: column;
    top: 0;

    @include responsive-down(tablet-extra-small) {
      height: auto;
      position: relative;
    }
  }

  &__content {
    width: 40%;
    padding: 70px 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include responsive-up(desktop) {
      padding: 120px 0 0;
    }

    @include responsive-down(tablet-extra-large) {
      width: 50%;
    }

    @include responsive-down(tablet-extra-small) {
      width: 100%;
      padding: 10px 0;
    }

    .btn {
      text-align: left;
      display: flex;
      justify-content: space-between;
      width: 350px;

      @include responsive-down(mobile-large) {
        width: 100%;
        padding: 10px 15px;
      }

      i {
        font-size: 20px;
      }
    }

    .box-price-product-contact {
      margin: auto 0 0;
      animation: fadeInSlideLeft ease 1.5s;

      @include responsive-down(tablet-extra-small) {
        margin: 55px 0 0;
      }
    }

    .contact-product-btn {
      margin: 20px 0 60px;
    }
  }

  &__description {
    @include responsive-down(tablet-extra-small) {
      padding: 25px 0 0;
    }

    .legend {
      font-size: 12px;
      margin: 0;
      line-height: 1;
      color: $mid-gray;
      display: none;

      @include responsive-down(tablet-extra-small) {
        display: block;
      }
    }

    &--mobile {
      padding: 20px 0 0;
      display: none;

      @include responsive-up(tablet-extra-small) {
        display: block;
        padding: 0;
        animation: fadeInSlideLeft ease 1.5s;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    display: inline-block;
    animation: fadeIn ease 0.3s;

    @include responsive-down(tablet-extra-large) {
      display: flex;
      justify-content: flex-end;
      margin-left: -400px;
      width: 150%;
    }

    @include responsive-down(tablet) {
      margin-left: -300px;
    }

    @include responsive-down(tablet-extra-small) {
      height: 50.3vh;
      padding: 40px 40px 0;
      margin: 0;
      display: inline-block;
      width: 100%;
      animation: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      mix-blend-mode: multiply;

      @include responsive-down(tablet-extra-small) {
        object-fit: contain;
      }
    }
  }

  &__reference {
    font-size: 12px;
    margin: 14px 0 17px;
    animation: fadeInSlideLeft ease 1.5s;

    @include responsive-down(tablet-extra-small) {
      margin: 12px 0 5px;
    }

    .numberref {
      padding: 0 5px;
    }
  }

  &__name {
    font-size: 26px;
    animation: fadeInSlideLeft ease 1.5s;

    @include responsive-down(tablet-extra-small) {
      font-size: 24px;
    }

    @include responsive-down(mobile-large) {
      font-size: 20px;
    }
  }

  &__prices {
    margin: auto 0 10px;
    animation: fadeInSlideLeft ease 1.5s;

    @include responsive-down(tablet-extra-small) {
      margin: 15px 0 20px;
    }

    @include responsive-down(mobile-large) {
      display: flex;
      align-items: flex-end;
    }

    .product-price {
      padding: 0 5px 3px 0;
      display: flex;
      justify-content: flex-start;

      &.has-discount {
        .price-regular {
          color: $red;
        }
      }
    }

    .current-price {
      display: flex;
      flex-direction: row;

      .price-regular {
        color: $mid-gray;
        font-weight: 600;
      }
    }

    .discount {
      background-color: $red;
      color: $white;
      font-size: 10px;
      padding: 4px;
      margin: 0 5px 0 0;
    }

    .price-discount,
    .price-before,
    .price-regular {
      padding: 0 10px 0 0;
      font-size: 18px;

      @include responsive-down(tablet) {
        padding: 0 0 0 5px;
      }
    }

    .price-discount {
      color: $red;
      font-weight: 700;
    }

    .price-before {
      text-decoration: line-through;
      color: $grayac;
    }

    .iva {
      font-size: 10px;
      padding: 4px 0 10px;

      @include responsive-down(tablet-extra-small) {
        padding: 0 0 4px;
      }
    }
  }

  &__send {
    display: flex;
    padding: 20px 0 50px;
    animation: fadeInSlideLeft ease 1.5s;

    @include responsive-down(tablet-extra-small) {
      padding: 20px 0 10px;
      flex-direction: row;
    }

    @include responsive-down(mobile) {
      flex-direction: column;
      padding: 0;
    }

    .availability:empty {
      padding: 0;
      display: none;
    }

    .availability {
      font-size: 12px;
      padding: 0;
      display: flex;
      align-items: center;
      margin: 0 10px 0 0;

      @include responsive-down(tablet-extra-small) {
        padding: 0 20px 0 0;
      }

      @include responsive-down(mobile) {
        padding: 3px 0;
      }

      .msg-result {
        color: $green;
        padding: 0 12px 0 0;
        font-size: 12px;

        i {
          margin: 0 5px 0 0;

          &-noavailable {
            color: $warning;
          }
        }

        &--lastitems {
          margin: 10px 0;
          color: $warning;
          display: inline-block;

          .textmsg {
            padding: 0 20px 0 0;
          }
        }

        &--nostock {
          margin: 10px 0;
          color: $red;
          display: inline-block;

          .textmsg {
            padding: 0 20px 0 0;
          }
        }

        & + span {
          padding: 0 20px 0 0;
        }
      }
    }

    .freeshipping {
      font-size: 12px;
      margin: 0;
      color: $mid-gray;
      display: flex;
      align-items: center;

      @include responsive-down(tablet) {
        padding: 3px 0;
      }

      i {
        font-size: 18px;
        margin: 0 5px 0 0;
      }
    }
  }

  &__countopinion {
    display: flex;
    justify-content: space-between;
    width: 350px;
    position: relative;
    animation: fadeInSlideLeft ease 1.5s;

    @include responsive-down(tablet-extra-small) {
      width: 100%;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive-down(tablet-extra-small) {
      width: 100%;
    }

    .product-additional-info {
      .product-availability {
        @include responsive-down(mobile-large) {
          padding: 0 0 10px;
        }

        input.form-control {
          margin: 20px 0 10px;
        }

        .input-group {
          flex-direction: column;
          align-items: flex-start;
        }

        p.btn {
          background: transparent;
          border: none;
          padding: 0;
          font-size: 12px;
          width: auto;
          font-weight: 500;
          justify-content: flex-start;
          cursor: pointer;

          &::before {
            content: "";
            height: 1px;
            width: 100%;
            opacity: 1;
            transform-origin: left;
            transform: scaleX(0);
            transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);

            @include responsive-down(tablet-extra-small) {
              transform-origin: left;
              transform: scaleX(1);
              transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }

          .flip {
            &::after,
            &::before {
              font-size: 8px;
              color: #393939;
            }
          }

          &:hover {
            @include responsive-up(tablet) {
              background-color: transparent;

              &::before {
                transform-origin: left;
                transform: scaleX(1);
                transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
              }
            }
          }
        }

        .alert.alert-info {
          font-size: 14px;
          padding: 0;
          background: transparent;
          margin: 10px 0 0;
        }
      }
    }

    #product_review_block_extra {
      display: none !important;
      padding: 0;
      margin: 0;
      height: auto;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: 0;
      top: 0;

      .ws_stars_wrapp {
        display: inline-flex;
        margin: 0;
        align-items: center;

        .star_content {
          min-width: 80px;

          i {
            padding: 0;
          }
        }

        .review_count_extra {
          font-size: 12px;
        }
      }

      .comments_advices {
        margin: -5px 0 0;
        padding: 0;

        li {
          list-style: none;
          margin: 0;
          padding: 0;

          &::before {
            display: none;
          }

          .open-ws-review-form {
            padding: 0;
            background: transparent;
            border: none;
            text-decoration: underline !important;
            margin: 0;
            font-size: 12px;
            color: #4d4d4d;

            i {
              display: none;
            }
          }
        }
      }
    }

    .product-add-to-cart {
      .control-label {
        display: none;
      }

      .product-quantity {
        height: auto !important;
      }
    }

    .count-number {
      display: inline-block;
      margin: 0;
      background: transparent;
      border: none;
      max-width: 40px;
      text-align: center;
      -webkit-appearance: none;
      position: absolute;
      left: 30px;

      @include responsive-down(tablet-extra-small) {
        margin: 0;
      }

      &:hover {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
    }

    .intro-addtocart {
      @include responsive-down(tablet-extra-small) {
        width: 100%;
      }
    }

    #product-availability {
      margin: 10px 0;
      color: $warning;
      display: inline-block;
      font-size: 14px;
    }

    .alert.alert-danger {
      margin: 10px 0;
      color: $red;
      display: inline-block;
      font-size: 14px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .input-group {
      display: flex;
      align-items: center;

      &.bootstrap-touchspin {
        position: relative;
      }

      .input-group-btn-vertical {
        display: flex;
        flex-direction: row-reverse;
        width: 100px;
        justify-content: space-between;
      }
    }

    .input-group-btn,
    .btn-touchspin {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: $black;
      z-index: 3;
      height: 25px;
      width: 25px;
      background-color: #fff;
      border-radius: 50px;
      padding: 0;

      @include transition(all 0.2s ease-in);

      &.bootstrap-touchspin-up {
        &::after {
          content: "\e91d";
          font-size: 10px;
          z-index: 1;
          font-family: $icomoon;
          color: $black;
          font-weight: 200;
        }
      }

      &.bootstrap-touchspin-down {
        &::after {
          content: "\e91c";
          font-size: 10px;
          font-family: $icomoon;
          z-index: 1;
          font-weight: 200;
          color: $black;
        }
      }

      &:hover {
        @include responsive-up (tablet) {
          background-color: #e9e9e9;
          border: none;

          @include transition(all 0.2s ease-in);
        }
      }

      .touchspin-down,
      .touchspin-up {
        display: none;
      }

      input {
        background-color: transparent;
        max-width: 40px;
      }

      i {
        font-size: 10px;
        z-index: 1;
      }
    }

    .add.col-auto {
      padding: 25px 0 0;

      @include responsive-down(tablet-extra-small) {
        padding: 30px 0 0;
      }
    }

    .product-minimal-quantity {
      margin: 0;
    }
  }

  &__valoration {
    position: absolute;
    right: 0;
    top: 10px;
    display: none;

    i {
      color: $secondary;
      font-size: 12px;

      &.icon-mid-star {
        position: relative;
        display: inline-flex;
        align-items: center;
        align-content: center;

        &::after {
          content: "\e914";
          font-family: $icomoon;
          position: absolute;
          z-index: 0;
          color: #e8e8e8;
        }

        &::before {
          z-index: 1;
        }
      }
    }

    .numbervaloration {
      font-size: 12px;
    }
  }

  .viewgallery {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: transparent;
    border-radius: 50px;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 130px;
    animation: fadeInSlideRight ease 1.5s;

    @include transition(all 0.2s ease-in);

    @include responsive-down(tablet-extra-small) {
      top: 50vh;
      right: 10px;
      padding: 0;
      bottom: auto;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: center;
      width: auto;
    }

    @include responsive-down(tablet-extra-small) {
      top: 46vh;
    }

    .text {
      font-size: 13px;
      font-weight: 500;
      transform: translateX(-45px);
      position: absolute;
      white-space: nowrap;
      opacity: 1;
      color: $black;
      visibility: visible;
      display: inline-block;

      @include transition(all 0.2s ease-in);

      @include responsive-down(tablet-extra-small) {
        display: inline-flex;
        visibility: visible;
        width: auto;
        opacity: 1;
        z-index: 0;
        bottom: auto;
        color: $black;
        right: auto;
        position: relative;
        animation: none;
        margin: 14px 0 0;
        font-size: 9px;
        transform: translateX(0);
      }
    }

    i {
      color: $black;
      position: relative;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include transition(all 0.2s ease-in);

      @include responsive-down(tablet-extra-small) {
        font-size: 18px;
      }

      @include responsive-down(mobile-large) {
        font-size: 16px;
      }

      &::before {
        z-index: 1;
      }

      &::after {
        content: "";
        background-color: $white;
        border-radius: 50px;
        border: none;
        position: absolute;
        width: 45px;
        height: 45px;
        z-index: 0;

        @include transition(all 0.2s ease-in);

        @include responsive-down(tablet-extra-small) {
          width: 40px;
          height: 40px;
        }

        @include responsive-down(mobile-large) {
          width: 32px;
          height: 32px;
        }
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        .text {
          opacity: 1;
          color: $primary;
          visibility: visible;
          display: inline-block;
          transform: translateX(-55px);

          @include transition(all 0.2s ease-in);
        }

        i {
          color: $primary;

          @include transition(all 0.2s ease-in);

          &::after {
            width: 55px;
            height: 50px;

            @include transition(all 0.2s ease-in);
          }
        }

        @include transition(all 0.2s ease-in);
      }
    }
  }

  &--catalog {
    .introproduct__prices {
      margin: 0 0 20px;

      @include responsive-down(tablet-extra-small) {
        margin: 0 0 10px;
      }

      @include responsive-down(mobile-large) {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }
}

.page-product {
  .breadcrumb {
    position: absolute;
    top: 0;
    margin: 0;

    @include responsive-down(tablet-extra-small) {
      padding: 13px 20px 0;
    }

    &-item {
      &.breadcrumb-item--mobile {
        &::before {
          border-top: 1px solid #525252;
          border-left: 1px solid #525252;
        }

        @include responsive-down(tablet-extra-small) {
          span {
            color: #000;
          }
        }
      }
    }
  }
}

body.topbar {
  .introproduct {
    height: calc(100vh - 118px);
    min-height: 560px;

    @include responsive-down(tablet-extra-small) {
      height: 100%;
      min-height: auto;
    }
  }
}
