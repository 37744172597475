/* Layouts: Pages: Checkout: Order
--------------------------------------------------------------------------------------------------------------------- */
.contactservices {
  display: flex;
  margin: 0 -30px;
  padding: 70px 0;
  animation: fadeslidecontactservices ease 1.5s both;

  @keyframes fadeslidecontactservices {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include responsive-down(mobile-large) {
    flex-direction: column;
    margin: 0;
    padding: 50px 0 20px;
  }

  &__item {
    width: 50%;
    padding: 0 30px;

    @include responsive-down(mobile-large) {
      width: 100%;
      margin: 0 0 50px;
      padding: 0;
    }

    i {
      font-size: 48px;
      color: $primary;

      @include responsive-down(mobile-large) {
        font-size: 52px;
      }

      &.icon-faqs {
        @include responsive-down(mobile-large) {
          font-size: 68px;
        }
      }
    }
  }

  &__title {
    margin: 18px 0 8px;
    font-size: 16px;
    font-weight: 700;
    color: $black;
  }

  &__text {
    margin: 0;
    line-height: 1.8;
  }

  &__link {
    color: $primary;
    text-decoration: underline;
    margin: 20px 0 0;
    display: inline-block;

    @include responsive-down(mobile-large) {
      font-size: 14px;
    }
  }
}
