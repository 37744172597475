/* components::partials::header
--------------------------------------------------------------------------------------------------------------------- */
.header {
  background-color: $primary;
  color: $white;
  position: fixed;
  left: 0;
  top: -27px;
  width: 100%;
  z-index: 9;
  height: auto;

  @include responsive-down(tablet) {
    top: -25px;
  }

  &__topbar {
    position: relative;
    padding: 5px 200px;
    background-color: $primary-dark;
    z-index: 8;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
    animation-name: opacityHeader;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    @include responsive-down(tablet) {
      padding: 4px 20px;
    }

    &.closed {
      height: 0;
      visibility: hidden;
      opacity: 0;
      padding: 0;
      transform: translateY(-22px);

      @include transition(transform 0.4s ease-in-out);
    }
  }

  & > .container {
    background-color: $primary;
    position: relative;
    z-index: 8;
    transition: margin 0.3s ease-out;
    animation: header_slide-bottom 1.3s ease;

    @include responsive-up(tablet) {
      min-height: 50px;
    }
  }

  &__topbarclose {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 3px;
    right: 12px;
    font-size: 10px;
    cursor: pointer;

    @include responsive-down(tablet) {
      right: 0;
    }

    i {
      transform: rotate(0);

      @include transition(all 0.15s ease-in-out);
    }

    &:hover {
      @include responsive-up(tablet) {
        i {
          color: $white05;
          transform: rotate(90deg);

          @include transition(all 0.3s ease-in-out);
        }
      }
    }
  }

  &__topbarlist {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    padding: 0;

    li {
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 10px;
      padding: 0;

      &::before {
        display: none;
      }

      i {
        font-size: 17px;
        margin-right: 6px;
      }

      span {
        line-height: 10px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;
    justify-content: space-between;

    @include responsive-down(tablet) {
      padding: 10px 0;
      justify-content: flex-start;
    }
  }

  &__burger-icon {
    display: none;
    margin-right: 15px;

    @include responsive-down(tablet) {
      display: inline-block;
      width: 25px;
    }

    label {
      display: flex;
      flex-direction: column;
      width: 25px;
      cursor: pointer;

      span {
        background: $white;
        border-radius: 10px;
        height: 2px;
        margin: 3px 0;
        transition: 0.4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

        @include responsive-down(tablet) {
          height: 2px;
          margin: 3.3px 0;
        }

        &:nth-of-type(3) {
          width: 75%;
        }
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    &.activated {
      span:nth-of-type(1) {
        width: 50%;
        transform-origin: bottom;
        transform: rotateZ(45deg) translate(4px, 1px);
      }

      span:nth-of-type(2) {
        transform-origin: top;
        transform: rotateZ(-45deg);
      }

      span:nth-of-type(3) {
        transform-origin: bottom;
        width: 50%;
        transform: translate(10.55px, -4.6px) rotateZ(45deg);
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(50% - 280px);

    @include responsive-down(tablet-extra-large) {
      width: calc(50% - 310px);
    }

    @include responsive-down(tablet-large) {
      width: calc(50% - 220px);
    }

    @include responsive-down(tablet) {
      width: auto;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 135px;
      height: auto;
      max-height: 33px;
      transition: width 0.3s ease-out;

      @include responsive-down(tablet) {
        width: 100px;
        height: 33px;
      }
    }
  }

  &__form {
    display: flex;
    align-items: center;
    position: relative;
    width: 510px;
    cursor: pointer;

    @include responsive-down(tablet-extra-large) {
      width: 350px;
    }

    @include responsive-down(tablet-large) {
      width: 300px;
    }

    @include responsive-down(tablet) {
      width: 30px;
      position: absolute;
      right: 30px;
    }

    @include responsive-down(mobile-large) {
      right: 10px;
    }

    .header__input-submit {
      position: absolute;
      right: 0;
      width: 28px;
      height: 28px;

      i {
        position: absolute;
        right: 10px;
        top: 6px;
        z-index: 1;

        @include transition(all 0.25s ease-in-out);

        @include responsive-down(tablet) {
          top: 3px;
          font-size: 22px;
        }
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        .header__input-search {
          background-color: $white;
          color: $primary-dark;

          @include transition(all 0.25s ease-in-out);

          &::placeholder {
            color: $primary-dark;

            @include transition(all 0.25s ease-in-out);
          }
        }

        .header__input-submit {
          i {
            color: $primary-dark;

            @include transition(all 0.25s ease-in-out);
          }
        }
      }
    }

    &.clicked {
      @include responsive-down(tablet) {
        position: fixed;
        top: 53px;
        background: $primary;
        width: 100%;
        right: 0;
        padding: 10px 20px;

        .header__input-search {
          display: flex;
        }

        .header__input-submit {
          right: 22px;
          width: 28px;
          height: 28px;
        }

        .header__input-submit i {
          top: 6px;
          font-size: 16px;
        }
      }
    }
  }

  &__input-search {
    width: 100%;
    background: #4176b3;
    border: none;
    border-radius: 3px !important;
    color: $white;
    font-size: 11px;
    padding: 8px 16px;
    height: 34px;

    @include transition(all 0.25s ease-in-out);

    @include responsive-down(tablet) {
      display: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-size: 13px;

      @include transition(all 0.25s ease-in-out);
    }
  }

  &__utilities {
    width: calc(50% - 250px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: auto;

    @include responsive-down(tablet-extra-large) {
      width: calc(50% - 145px);
    }

    @include responsive-down(tablet-large) {
      width: calc(50% - 155px);
    }

    @include responsive-down(tablet) {
      width: auto;
      position: absolute;
      right: 12px;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        margin: 0 12px;

        @include responsive-down(tablet-large) {
          margin: 0 10px;
        }

        @include responsive-down(tablet) {
          display: none;
        }

        &::before {
          display: none;
        }

        a,
        .blockcart {
          font-size: 14px;
          border-radius: 5px;
          overflow: hidden;
          cursor: pointer;

          @include responsive-down(tablet-large) {
            font-size: 11px;
          }

          i {
            display: inline-block;
            font-size: 22px;
            color: $white;
            background: transparent;
            padding: 5px;

            @include transition(all 0.25s ease-in-out);

            @include responsive-down(tablet) {
              font-size: 22px;
            }
          }

          & > div {
            i {
              display: inline-block;
              font-size: 22px;
              color: $white;
              background: transparent;
              padding: 5px;

              @include transition(all 0.25s ease-in-out);

              @include responsive-down(tablet) {
                font-size: 22px;
              }
            }
          }

          &:hover {
            @include responsive-up(tablet) {
              i {
                background: $primary-dark;

                @include transition(all 0.25s ease-in-out);
              }
            }
          }

          .cart-desktop {
            display: none;
            position: relative;

            @include responsive-up(tablet) {
              display: inline-block;
            }
          }

          .cart-mobile {
            display: none;

            @include responsive-down(tablet) {
              display: inline-block;
            }
          }
        }

        &:last-child {
          margin: 0;

          @include responsive-down(tablet) {
            display: none;
          }
        }

        &.header__cart {
          position: relative;

          .header__cart-number {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 2px;
            right: 3px;
            width: 12px;
            height: 12px;
            background-color: $white;
            color: $primary-dark;
            font-size: 8px;
            border-radius: 10px;
            font-weight: 500;
            cursor: pointer;
          }

          .cart__resume {
            display: none;
            flex-direction: column;
            position: absolute;
            top: 83px;
            right: 0;
            background-color: $white;
            width: 360px;
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.075);
            overflow: hidden;

            @include responsive-down(tablet) {
              display: none;
            }

            &--opened {
              display: flex;

              @include responsive-down(tablet) {
                display: none;
              }
            }
          }

          .cart__resume-shipping {
            padding: 20px 20px 10px;

            p {
              font-size: 11px;
              line-height: 20px;
              margin: 0;
              color: $black;
            }
          }

          .cart__resume-quantity {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            & > span {
              font-size: 12px;
              color: $gray;
            }

            .shipping-progress {
              width: 80%;
              height: 4px;
              border-radius: 10px;
              background: $light-gray;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 68%;
                height: 100%;
                background-color: $primary;
                border-radius: 10px;
              }
            }
          }

          .cart__resume-products {
            flex-direction: column;
            padding: 20px;
            max-height: 320px;
            overflow-y: auto;

            .cart__resume-product {
              margin-top: 18px;
              margin-right: 0;
              margin-left: 0;
              padding-left: 0;
              padding-right: 0;
              padding-top: 18px;
              border-top: 1px solid $light-gray;
              width: 100%;

              &:first-of-type {
                margin-top: 0;
                padding-top: 0;
                border: none;
              }

              a {
                display: flex;
                align-items: stretch;
                overflow: visible;

                .img {
                  width: 100px;
                  height: 100px;
                  border: 1px solid $light-gray;
                  background-color: $light-gray;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .content {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  width: calc(100% - 80px);
                  padding-left: 10px;

                  .title {
                    font-size: 12px;
                    line-height: 16px;
                    margin: 0;
                  }

                  .quantity {
                    margin: 0;
                    font-size: 11px;
                  }

                  .details {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;

                    .price-offer,
                    .price-before,
                    .price-regular {
                      font-size: 16px;
                      margin: 0;
                      font-weight: 600;
                      padding: 0 10px 0 0;
                    }

                    .price-offer {
                      color: $red;
                    }

                    .price-before {
                      color: $gray;
                      text-decoration: line-through;
                    }

                    .price-regular {
                      color: $black;
                    }

                    .delete {
                      i {
                        font-size: 13px;
                        color: $primary;
                        padding: 0;
                      }

                      &:hover {
                        @include responsive-up(tablet) {
                          i {
                            color: $gray;
                          }
                        }
                      }
                    }
                  }
                }

                &:hover {
                  @include responsive-up(tablet) {
                    color: $black;

                    i {
                      background: transparent;
                    }
                  }
                }
              }

              &__content {
                width: 100%;
                position: relative;
              }

              .remove-from-cart {
                justify-content: flex-end;
                position: absolute;
                bottom: 0;
                right: 0;

                .icon-trash {
                  color: $black;
                  font-size: 15px;
                  background: transparent;
                  transition: all 0.2s ease 0s;
                }

                &:hover {
                  @include responsive-up(tablet) {
                    .icon-trash {
                      background: $primary;
                      border-radius: 4px;
                      color: $white;
                    }
                  }
                }
              }
            }
          }

          .cart__resume-total {
            padding: 15px 0 20px;
            border-top: 1px solid $light-gray;
            margin: 0 20px;

            .subtotal {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0;
              margin-bottom: 15px;
              font-size: 15px;
              font-weight: 700;
              color: $black;
            }

            .buttons-container {
              display: flex;
              justify-content: space-between;
              margin: 0 -10px;

              button,
              a {
                padding: 8px 16px;
                font-size: 14px;
                margin: 0 5px;

                &.btn {
                  width: 55%;

                  &:last-child {
                    .flip {
                      &::before {
                        color: $white;
                      }

                      &::after {
                        color: $primary;
                      }
                    }
                  }
                }

                &.btn--line {
                  width: 45%;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__nav {
    background-color: $primary;
    position: relative;
    animation: header_slide-bottom 1.3s ease;
    z-index: 7;

    .extra-menu-mobile {
      display: none;
    }

    @include responsive-down(tablet) {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 70px;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 9;
      animation: none;

      @include transition(all 0.3s ease-in);

      .extra-menu-mobile {
        display: flex;
        margin: auto 0 78px;

        .header__utilities {
          width: 100%;
          justify-content: flex-start;
          margin: 40px 0 0;
          // height: 100%;
        }

        ul {
          display: flex;
          padding-left: 20px;
          padding-right: 20px;
          border-top: 0;
          width: 100%;
          margin: auto 0 0;
          // height: 100%;
          align-items: flex-start;
          justify-content: flex-end;
          flex-direction: column-reverse;

          li {
            padding: 12px 0;
            display: block;

            &::before {
              list-style: none;
              display: none;
            }

            a {
              color: $white;
              font-size: 16px;
              font-weight: 400;
            }

            &.header__cart {
              display: none;
            }

            .icon-user {
              font-size: 18px;
              padding: 0;
              display: inline-flex;
              align-items: center;

              &::after {
                content: "Iniciar sesión";
                color: #fff;
                font-size: 16px;
                margin: 0 0 0 7px;
                font-weight: 400;
                font-family: $font-secondary;
              }
            }
          }
        }
      }
    }

    & > ul {
      display: flex;

      @include responsive-down(tablet) {
        flex-direction: column;
        padding-top: 0;
        // border-top: 1px solid rgba(255, 255, 255, 0.25);
        border-top: none;
      }

      & > li {
        padding: 14px 18px;
        line-height: 13px;
        position: relative;
        transition: padding 0.3s ease-out;

        @include responsive-down(tablet) {
          padding: 14px 18px;
          padding-left: 0;
        }

        &::before {
          display: none;
        }

        &::after {
          @include responsive-up(tablet) {
            content: "";
            position: absolute;
            bottom: 0;
            left: 18px;
            height: 1.5px;
            width: calc(100% - 36px);
            background-color: $secondary;
            opacity: 0;

            @include transition(all 0.25s ease-in-out);
          }
        }

        &:first-child {
          padding-left: 0;

          &::after {
            width: calc(100% - 18px);
            left: 0;

            @include transition(all 0.25s ease-in-out);
          }
        }

        &:last-child {
          padding-right: 0;

          &::after {
            width: calc(100% - 18px);
            left: 18px;

            @include transition(all 0.25s ease-in-out);
          }
        }

        & > span {
          font-size: 14px;
          line-height: 13px;
          color: $white;
          font-weight: 400;
          cursor: pointer;

          @include responsive-down(tablet) {
            font-size: 25px;
            line-height: 30px;
            font-family: $font-main;
          }
        }

        &:hover {
          @include responsive-up(tablet) {
            &::after {
              opacity: 1;

              @include transition(all 0.25s ease-in-out);
            }
          }
        }

        // .header__submenu--opened {
        //   .header__submenu-content {
        //     display: none;
        //   }
        // }

        &.active {
          .background-submenu {
            opacity: 1;
            visibility: visible;

            @include transition(all 0.25s ease-in-out);

            @include responsive-down(tablet) {
              display: none;
            }
          }

          .header__submenu {
            display: flex;

            @include transition(all 0.5s ease-in-out);

            @include responsive-down(tablet) {
              display: block;
            }

            &::before {
              opacity: 1;
              visibility: visible;

              @include transition(all 0.5s ease-in-out);
            }
          }

          .header__submenu-nav {
            @include responsive-down(tablet) {
              display: block;
            }
          }

          .header__submenu-content,
          .header__submenu-content.active {
            @include responsive-down(tablet) {
              display: none;
            }
          }

          .header__submenu--opened {
            .header__submenu-content.active {
              @include responsive-down(tablet) {
                display: block;
              }
            }
          }
        }
      }
    }

    &--opened {
      @include responsive-down(tablet) {
        display: flex;
        top: 49px;
        height: calc(100vh - 48px);

        @include transition(all 0.3s ease-in);

        .header__submenu {
          top: 0 !important;
        }
      }
    }
  }

  &__submenu {
    display: none;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 91px;
    margin-top: 0;
    background: $white;
    min-height: 465px;

    .background-submenu {
      width: 100%;
      height: 100vh;
      position: absolute;
      background-color: $black03;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      top: 0;

      @include transition(all 0.5s ease-in-out);

      @include responsive-down(tablet) {
        display: none;
      }
    }

    @include transition(all 0.5s ease-in-out);

    @include responsive-down(tablet) {
      width: calc(100vw - 65px);
      height: 100%;
      margin-top: 0;
      left: 65px;
      top: 0;
      z-index: 9;
    }
  }

  &__submenu-nav {
    width: 350px;
    padding: 50px 20px 50px 50px;
    background: $light-gray;

    @include responsive-up(desktop) {
      width: 450px;
    }

    @include responsive-down(tablet-extra-large) {
      width: 300px;
      padding: 50px 25px 50px 50px;
    }

    @include responsive-down(tablet-extra-large) {
      width: 100%;
    }

    @include responsive-down(tablet) {
      display: none;
      flex-direction: column;
      padding: 45px 25px 25px;
      height: 100%;
    }

    .only-mobile {
      display: none;
      flex-direction: column;

      @include responsive-down(tablet) {
        display: flex;
        padding: 0 0 10px;
      }

      .back {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $black;
        font-size: 12px;
        line-height: 12px;
        cursor: pointer;

        svg {
          width: 5px;
          margin-right: 10px;
          transform: none;
        }
      }

      .see-all {
        @include responsive-down(tablet) {
          padding: 20px 0 30px;
        }

        @include responsive-down(mobile-large) {
          padding: 0 0 20px;
        }

        a {
          display: flex;
          flex-direction: column;
          color: $primary;
          font-weight: 500;
          font-family: $font-main;
          font-size: 22px;

          span {
            font-size: 12px;
            line-height: 12px;
            color: $primary;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: -65px;
        top: 65px;
        background: $primary;
        width: 65px;
        height: calc(100% - 45px);
      }
    }

    ul {
      padding: 0 20px 40px 0;
      margin: 0 0 40px;
      max-height: 430px;

      @include responsive-down(mobile) {
        margin: 0;
      }

      &::-webkit-scrollbar {
        display: block;
        height: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $light-gray;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $dark-gray;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $dark-gray;
      }

      @include responsive-down(tablet) {
        width: 100%;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 27px 0;

        @include responsive-down(mobile-large) {
          padding: 0 0 23px 0;
        }

        &::before {
          display: none;
        }

        a {
          font-size: 25px;
          font-weight: 400;
          color: $gray;
          position: relative;
          font-family: $font-main;

          @include responsive-down(tablet) {
            color: $light-black;
            font-size: 20px;
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: $black;
            transform-origin: left;
            transform: scaleX(0);
            transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);

            @include transition (all 0.25s ease-in-out);
          }

          &:hover {
            @include responsive-up(tablet) {
              color: $light-black;
            }
          }
        }

        svg {
          width: 5px;
          transform: rotate(180deg);

          @include responsive-up(tablet) {
            display: none;
          }
        }

        &.active {
          a {
            color: $light-black;

            &::after {
              height: 1px;
              opacity: 1;
              width: 100%;
              transform-origin: left;
              transform: scaleX(1);
              transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);

              @include transition (all 0.25s ease-in-out);

              @include responsive-down(tablet) {
                display: none;
              }
            }
          }
        }
      }
    }

    & > a {
      position: absolute;
      bottom: 35px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.2px;

      @include responsive-down(tablet) {
        display: none;
      }

      .flip {
        font-size: 12px;

        &::before,
        &::after {
          font-size: 7px;
          color: $light-black;
        }
      }
    }
  }

  &__submenu-content {
    display: none;
    width: calc(100% - 350px);
    padding: 50px;
    background-color: white;

    @include responsive-up(desktop) {
      width: calc(100% - 450px);
    }

    @include responsive-down(tablet-extra-large) {
      width: calc(100% - 300px);
    }

    @include responsive-down(tablet-extra-large) {
      width: 100%;
    }

    @include responsive-down(tablet) {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: $light-gray;
      padding: 45px 25px 25px;
      z-index: 99999999;
    }

    .only-mobile {
      display: none;
      flex-direction: column;

      @include responsive-down(tablet) {
        display: flex;
        padding: 0;
      }

      .back {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $black;
        font-size: 12px;
        line-height: 12px;
        cursor: pointer;

        svg {
          width: 5px;
          margin-right: 10px;
          transform: none;
        }
      }

      .see-all {
        @include responsive-down(tablet) {
          padding: 20px 0 30px;
        }

        @include responsive-down(mobile-large) {
          padding: 0 0 20px;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: $primary;
          font-weight: 500;
          font-size: 22px;
          font-family: $font-main;

          span {
            font-size: 12px;
            line-height: 12px;
            color: $primary;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: -65px;
        top: 65px;
        background: $primary;
        width: 65px;
        height: calc(100% - 45px);
      }
    }

    ul {
      width: calc(100% - 355px);
      columns: 2;
      padding-left: 0;

      @include responsive-up(desktop) {
        width: calc(100% - 405px);
      }

      @include responsive-up(desktop-large) {
        width: calc(100% - 555px);
      }

      @include responsive-down(tablet-extra-large) {
        width: 100%;
      }

      @include responsive-down(tablet) {
        columns: 1;
      }

      li {
        padding-left: 0;
        padding-bottom: 15px;

        @include responsive-down(tablet) {
          padding-bottom: 20px;
        }

        &::before {
          display: none;
        }

        a {
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 500;

          @include responsive-down(tablet) {
            font-size: 18px;
            font-weight: 300;
          }

          img {
            width: 40px;
            margin-right: 10px;
            mix-blend-mode: multiply;

            @include responsive-down(tablet) {
              width: 40px;
            }
          }
        }

        &.see-all {
          @include responsive-down(tablet) {
            padding: 20px 0 30px;
          }
        }
      }
    }

    & > a {
      position: absolute;
      bottom: 35px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.2px;

      @include responsive-down(tablet) {
        display: none;
      }

      .flip {
        font-size: 12px;

        &::before,
        &::after {
          font-size: 8px;
          color: $light-black;
        }
      }
    }

    & > img {
      position: absolute;
      top: 50px;
      right: 50px;
      max-width: 305px;

      @include responsive-down(tablet-extra-large) {
        display: none;
      }
    }

    &.active {
      display: block;

      @include responsive-down(tablet) {
        display: none;
      }
    }

    &--opened {
      @include responsive-down(tablet) {
        display: flex;
      }
    }
  }

  &__submenu-category {
    font-size: 20px;
    color: $light-black;
    display: inline-block;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.4;

    @include responsive-down(tablet) {
      display: none;
    }
  }

  &--mobile-opened {
    @include responsive-down(tablet) {
      .header__topbar {
        opacity: 0;
        visibility: hidden;
      }

      .header__content {
        div:nth-child(n+2) {
          display: none;
        }
      }

      .header__burger-icon label {
        margin: 3px 0 0 0;
      }
    }
  }
}

body {
  @include responsive-up(tablet) {
    &.scroll-down {
      .header {
        &__logo svg {
          width: 135px;
          height: auto;
          max-height: 33px;
        }

        &__nav {
          & > ul > li {
            padding: 14px 18px;

            &:first-child {
              padding-left: 0;
            }
          }
        }

        &__submenu-nav {
          & > ul > li {
            padding: 0 0 20px 0 !important;
          }
        }

        &__submenu {
          top: 90px;
        }
      }

      .cart__resume {
        top: 84px !important;
      }
    }

    &.scroll-up {
      .header__nav {
        visibility: visible;
        opacity: 1;
        height: auto;
        transform: translate3d(0, 0, 0);

        @include transition(transform 0.35s ease-in-out);
      }

      .header {
        &__submenu {
          top: 41px;
        }
      }

      .cart__resume {
        top: 83px;
      }
    }
  }
}

body.topbar {
  .header {
    top: 0;
  }

  .header__topbar {
    opacity: 1;
    visibility: visible;
  }

  .header__form {
    @include responsive-down(tablet) {
      &.clicked {
        top: 75px;
      }
    }
  }

  .header__submenu {
    top: 118px;
  }

  .header__nav--opened {
    @include responsive-down(tablet) {
      top: 70px;
      height: calc(100vh - 64px);
    }
  }

  @include responsive-up(tablet) {
    &.scroll-down {
      .header {
        &__topbar {
          top: -27px;
          transition: top 0.3s ease-out;
        }

        &__logo svg {
          width: 115px;
          max-height: 33px;
          transition: width 0.3s ease-out;
        }

        & > .container {
          margin: -27px 0 0 0;
          transition: margin 0.3s ease-out;

          @include responsive-up(tablet) {
            min-height: 63px;
          }
        }

        &__nav {
          & > ul > li {
            padding: 9px 18px 12px;
            transition: padding 0.3s ease-out;

            &:first-child {
              padding-left: 0;
            }
          }
        }

        &__content {
          ul > li {
            padding-left: 0;
            padding-bottom: 15px;
          }
        }

        &__submenu {
          top: 97px;
        }
      }

      .cart__resume {
        top: 89px !important;
      }
    }

    &.scroll-up {
      .header {
        &__submenu {
          top: 41px;
        }
      }
    }
  }
}

@keyframes header_slide-bottom {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityHeader {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  50% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
