/* component::categories
--------------------------------------------------------------------------------------------------------------------- */
.categorylist {
  padding: 50px 0;

  @include responsive-down(mobile-large) {
    padding: 20px 0;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px;
    overflow: hidden;
    height: 550px;

    @include responsive-down(desktop) {
      height: 450px;
    }

    @include responsive-down(tablet) {
      height: 400px;
    }

    @include responsive-down(tablet-small) {
      height: 350px;
    }

    @include responsive-down(mobile-large) {
      height: 300px;
    }
  }

  &__content {
    position: absolute;
    text-align: center;
    padding: 0 20px;
  }

  &__name {
    font-size: 50px;
    color: $white;
    text-align: center;
    margin: 10px 0 0;
    line-height: 0.8;

    @include responsive-down(tablet-large) {
      font-size: 40px;
    }

    @include responsive-down(tablet-extra-small) {
      font-size: 34px;
    }

    @include responsive-down(mobile-large) {
      font-size: 24px;
    }
  }

  &__description {
    text-align: center;
    color: $white;
    margin: 20px auto 34px;
    font-size: 18px;
    max-width: 400px;
    line-height: 1.6;

    @include responsive-down(tablet) {
      font-size: 16px;
    }

    @include responsive-down(mobile-large) {
      font-size: 14px;
      line-height: 1.5;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .img,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img {
    position: relative;
    display: grid;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $black03;
    }
  }
}
