/* component::section banners
--------------------------------------------------------------------------------------------------------------------- */
.sectionbanners {
  padding: 150px 0;

  @include responsive-down(tablet) {
    padding: 80px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @include responsive-up(desktop-large) {
      max-width: 1720px;
    }

    @include responsive-down (tablet-extra-small) {
      flex-wrap: wrap;
      padding: 0;
    }
  }

  .banners {
    width: calc(100% / 2 - 20px);
    margin: 0 10px;

    .left-banner {
      margin: 0 20px 0 0;

      @include responsive-down(tablet-extra-small) {
        margin: 0;
      }
    }

    &:nth-child(2),
    .right-banner {
      margin: 50px 0 0 20px;

      @include responsive-down(tablet-extra-small) {
        margin: 0;
      }
    }

    @include responsive-down (tablet-extra-small) {
      width: 100%;
      margin: 0;

      &:nth-child(2) {
        margin: 30px 0 0;
      }
    }
  }

  a {
    color: $white;

    &:hover {
      @include responsive-up(tablet) {
        color: $white;
      }
    }
  }

  &--category {
    background-color: $light-gray;
    padding: 140px 0;

    @include responsive-down (tablet-extra-small) {
      background-color: transparent;
      padding: 20px 0;
    }
  }
}
