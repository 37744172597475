/* common::extends
--------------------------------------------------------------------------------------------------------------------- */
.styleguide {
  &__label {
    margin: 60px 0 20px;
    font-size: 20px;
    font-weight: 800;
    font-style: italic;
    color: $gray;
  }

  &__black {
    padding: 50px;
    background-color: black;
  }

  &__itemsolution {
    width: 400px;
  }

  &__article {
    display: block;
    width: 50% !important;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.visible--desktop {
  @include responsive-down(mobile-large) {
    display: none;
  }
}

.visible--mobile {
  @include responsive-up(mobile-large) {
    display: none;
  }
}

body.topbar {
  main {
    margin: 118px 0 0;

    @include responsive-down(tablet) {
      margin: 78px 0 0;
    }
  }
}

main {
  margin: 91px 0 0;
  overflow: hidden;

  @include responsive-down(tablet) {
    margin: 53px 0 0;
  }
}

.page-index,
body.cms-id-4 {
  main {
    margin: 0;
  }
}

/* TITULARES
--------------------------------------------------------------------------------------------------------------------- */
.h1 {
  color: $dark-gray;

  @include responsive-down(mobile-large) {
    font-size: 40px;
  }

  &--blue {
    color: $primary;
  }

  &--white {
    color: $white;
  }
}

.h2 {
  color: $dark-gray;

  &--blue {
    color: $primary;
  }

  &--white {
    color: $white;
  }
}

.h3 {
  color: $dark-gray;

  &--blue {
    color: $primary;
  }

  &--white {
    color: $white;
  }
}

.h4 {
  color: $dark-gray;

  &--blue {
    color: $primary;
  }

  &--white {
    color: $white;
  }
}

.h5 {
  &--blue {
    color: $primary;
  }

  &--white {
    color: $white;
  }
}

/* ARROW FLIP BUTTONS
--------------------------------------------------------------------------------------------------------------------- */
.flip {
  opacity: 1;
  outline: 0;
  line-height: 40px;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  margin: -10px 0 -10px 10px;
  display: inline-block;
  text-decoration: none;

  @include transition(all 0.3s ease-in);

  &::before {
    top: 0;
    left: 0;
    opacity: 1;
    color: $primary;
    display: block;
    transition: 0.5s;
    line-height: 40px;
    font-weight: 300;
    position: relative;
    content: "\e900";
    font-size: 12px;
    font-family: $icomoon;
    transform: translateY(0) rotateX(0);
  }

  &::after {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    color: $white;
    font-size: 12px;
    display: block;
    transition: 0.5s;
    position: absolute;
    font-weight: 300;
    content: "\e900";
    font-family: $icomoon;
    transform: translateY(50%) rotateX(90deg);

    @include responsive-down(tablet-extra-small) {
      font-size: 15px;
    }
  }
}

/* BOTONES
--------------------------------------------------------------------------------------------------------------------- */
.btn {
  padding: 11px 24px;
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  font-size: 16px;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.3px;

  @include transition(all 0.3s ease-in);

  &:hover {
    @include responsive-up(tablet) {
      background-color: $white;
      color: $primary;
      border: 1px solid $primary;

      @include transition(all 0.3s ease-in);

      .flip {
        &::before {
          opacity: 0;
          transform: translateY(-50%) rotateX(90deg);
          color: $primary;
        }

        &::after {
          opacity: 1;
          transform: translateY(0) rotateX(0);
          color: $primary;
        }
      }
    }
  }

  &:focus {
    background-color: $white;
    color: $primary;
    border: 1px solid $primary;

    @include transition(all 0.3s ease-in);

    .flip {
      &::before {
        opacity: 0;
        transform: translateY(-50%) rotateX(90deg);
        color: $primary;
      }

      &::after {
        opacity: 1;
        transform: translateY(0) rotateX(0);
        color: $primary;
      }
    }
  }

  i {
    font-size: 11px;
    margin: 0 0 0 4px;
  }

  .flip {
    &::after,
    &::before {
      color: $white;
    }
  }

  &--line {
    border: 1px solid $primary;
    background-color: white;
    color: $primary;

    .flip {
      &::after,
      &::before {
        color: $primary;
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        background-color: $primary;
        color: $white;

        @include transition(all 0.3s ease-in);

        .flip {
          &::after,
          &::before {
            color: $white;
          }
        }
      }
    }

    &:focus {
      border: 1px solid $primary;
      background-color: $primary;
      color: $white;

      @include transition(all 0.3s ease-in);

      .flip {
        &::after,
        &::before {
          color: $white;
        }
      }
    }
  }

  &--linewhite {
    border: 1px solid $white;
    background-color: transparent;
    color: $white;

    i {
      color: $white;
    }

    .flip {
      &::before {
        color: $white;
      }

      &::after {
        color: $dark-gray !important;
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        background-color: $white;
        color: $dark-gray;
        border: 1px solid $white;

        i {
          color: $dark-gray;
        }

        .flip {
          &::after,
          &::before {
            color: $white;
          }
        }

        @include transition(all 0.3s ease-in);
      }
    }

    &:focus {
      border: 1px solid $white;
      background-color: $white;
      color: $dark-gray;

      i {
        color: $dark-gray;
      }

      .flip {
        &::after,
        &::before {
          color: $white;
        }
      }

      @include transition(all 0.3s ease-in);
    }
  }

  &--normal {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $mid-gray;
    font-size: 12px;

    &:hover {
      @include responsive-up(tablet) {
        color: $mid-gray;
        background-color: transparent;
        border: none;
      }
    }
  }
}

.btn-analytics {
  padding: 0 !important;
  min-height: 44px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--small {
    min-height: 38px;
  }
}

//link hover línea inferior
.link {
  position: relative;
  color: $dark-gray;

  &::before {
    content: "";
    height: 1px;
    background-color: $dark-gray;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    @include responsive-up(tablet) {
      color: $dark-gray;

      &::before {
        content: "";
        width: 100%;
        transform-origin: left;
        transform: scaleX(1);
        transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  //link header
  &--white {
    color: $white;

    &:hover {
      @include responsive-up(tablet) {
        color: $secondary;

        &::before {
          display: none;
        }
      }
    }
  }
}

.linkmoreinfo {
  font-size: 12px;
  color: $primary;
  text-decoration: underline;

  &:hover {
    @include responsive-up(tablet) {
      color: $black;
    }
  }

  @include responsive-down(tablet) {
    font-size: 14px;
  }
}

/* FORMS
--------------------------------------------------------------------------------------------------------------------- */
.form-group {
  label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 400;
    top: -9px;

    &::before {
      content: attr(data-content);
      font-size: 14px;
      font-weight: 400;
      color: $mid-gray;
      display: inline-block;
      filter: blur(0);
      backface-visibility: hidden;
      transform-origin: left top;
      transition: transform 0.2s ease;
      left: 1rem;
      position: relative;
      transform: translate3d(0, -32px, 0) scale3d(0.82, 0.82, 1);
    }

    &:focus {
      top: 0;
    }
  }

  input {
    &[type=text],
    &[type=number],
    &[type=email],
    &[type=password],
    &[type=tel] {
      width: 100%;
      background-color: $light-gray;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      font-size: 12px;
      color: $black;
      padding: 15px 16px 0;
      height: 49px;

      &::placeholder {
        color: transparent;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &--checkbox {
    width: 100% !important;
    margin: 15px 0 !important;

    label {
      max-height: inherit;
      top: inherit;
    }

    .custom-control {
      display: flex;
      align-items: flex-start;

      .custom-control-label {
        font-size: 13px;
        line-height: 13px;
        color: $mid-gray;
        margin-left: 5px;

        em {
          max-width: 550px;
          width: 100%;
          display: block;
          font-size: 10px;
          margin-top: 5px;
          color: $hover-gray-footer;
        }
      }
    }
  }

  &--radio {
    display: flex;
    align-content: center;
    align-items: center;

    .custom-control {
      margin: 0 0 0 20px;
      order: 2;
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
      }
    }

    .custom-control-label {
      font-size: 14px;
    }

    .label.mr-3 {
      color: $light-gray;
      font-size: 11px;
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
  }

  &--password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input-group__help-text {
      order: 3;
    }

    .form-group {
      width: 100%;
      margin: 0;
    }
  }

  .text-muted {
    display: none;
  }

  select {
    width: 100%;
    background-color: $light-gray;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    padding: 16px;
    font-size: 12px;
    color: $mid-gray;
  }

  input:focus + label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  input:placeholder-shown + label::before {
    transform: translate3d(0, -25px, 0) scale3d(1, 1, 1);
  }

  input:focus + label::before {
    color: $mid-gray !important;
    transform: translate3d(0, -32px, 0) scale3d(0.82, 0.82, 1);
  }

  .invalid-feedback {
    font-size: 10px;
    color: #d32f2f;
    margin-top: 10px;
    padding: 5px 15px;
    background: #d32f2f21;
    border-radius: 3px;
    display: inline-block;
  }
}

.invalid-feedback:empty {
  display: none;
}

::-moz-selection {
  color: $white;
  background: $black;
}

::selection {
  color: $white;
  background: $black;
}

/* CHECKBOX
--------------------------------------------------------------------------------------------------------------------- */
.checkbox {
  display: block;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    // display: flex;
    // align-items: center;
    position: relative;
    font-size: 12px !important;
    font-weight: 400 !important;
    cursor: pointer;

    a {
      font-weight: 700;
      margin-left: 4px;

      &:hover {
        @include responsive-up(tablet) {
          color: $primary;
        }
      }
    }
  }

  label::before {
    content: '';
    -webkit-appearance: none;
    background-color: #d9d9d9;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    padding: 6px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    left: 0;
    transform: none;
  }

  input:checked + label::before {
    border-color: transparent;
    background: #525252;
  }

  input:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 5px;
    width: 4px;
    height: 9px;
    border: solid $white;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
  }
}

/* BREADCRUMB
--------------------------------------------------------------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  margin: 0;
  padding: 30px 50px 15px;
  z-index: 1;
  display: flex;
  animation: header_slide-bottom 1.3s ease;

  @include responsive-down(tablet) {
    padding: 30px 30px 15px;
    margin: 75px 0 15px;
  }

  @include responsive-down(mobile-large) {
    padding: 20px 20px 0;
    margin: 75px 0 0;
  }

  &-item {
    font-size: 12px;
    color: $gray;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px 10px 0;

    @include responsive-down(tablet-extra-small) {
      display: none;
    }

    &::after {
      content: "";
      height: 10px;
      width: 1px;
      background-color: $gray;
      position: absolute;
      right: 0;
      margin: 0 10px 0 0;
    }

    &::before {
      display: none;
    }

    a {
      color: $gray;
    }

    &.active {
      &::after {
        display: none;
      }

      span {
        color: #000;

        @include transition(all 0.3s ease-in);
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        a {
          color: $primary;

          @include transition(all 0.3s ease-in);

          span {
            color: $primary;

            @include transition(all 0.3s ease-in);
          }
        }
      }
    }

    //TODO @dayane ELIMINAR .ACTIVE
    &.breadcrumb-item--mobile {
      @include responsive-down(tablet-extra-small) {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: $mid-gray;
        padding: 0;

        &::before {
          content: "";
          left: 0;
          right: auto;
          display: inline-flex;
          width: 8px;
          height: 8px;
          background: transparent;
          border-top: 1px solid $white;
          border-left: 1px solid $white;
          transform: rotate(-45deg);
          margin: 0 5px 0 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

/* MODAL
--------------------------------------------------------------------------------------------------------------------- */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(35, 35, 35, 0.3);
  z-index: 1002;
  justify-content: center;
  align-items: center;
  display: none;

  &__content {
    height: auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background-color: $white;
    display: flex;
    align-items: center;
  }

  &__close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 100;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    border: none;

    @include responsive-down(tablet-small) {
      right: 10px;
      top: 10px;
    }

    &::before {
      content: "";
      background-color: $white;
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50px;

      @include transition(all 0.1s ease-in);
    }

    .icon-close {
      position: absolute;
      width: 16px;
      height: 1px;
      background-color: $black;
      transition: transform 0.45s cubic-bezier(0.21, 0.61, 0.35, 1);
      transform: rotate(-45deg);

      @include responsive-down(mobile-extra-large) {
        width: 15px;
      }

      &::before {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        height: 1px;
        background-color: $black;
        transition: transform 0.45s cubic-bezier(0.21, 0.61, 0.35, 1);
        transform: rotate(90deg);
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        border-radius: 50px;

        &::before {
          width: 50px;
          height: 50px;

          @include transition(all 0.1s ease-in);
        }
      }
    }
  }

  &--active,
  &.show,
  &.in {
    display: flex !important;
    padding: 30px;

    @include responsive-down(tablet-small) {
      padding: 10px 30px 0;
    }
  }
}

// Background shadowt
.background-shadow {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  z-index: -1;

  @include transition(opacity 0.5s ease-in-out);

  &--visible {
    visibility: visible;
    opacity: 1;
    z-index: 9;

    @include transition(opacity 0.5s ease-in-out);
  }

  &--visible-filters {
    visibility: visible;
    opacity: 1;
    z-index: 10;

    @include transition(opacity 0.5s ease-in-out);
  }
}

// Background shadowt
.arrow-tab {
  display: block;
  width: 10px;
  height: 10px;
  border-top: 1px solid $mid-gray;
  border-left: 1px solid $mid-gray;
}

.ps-alert-error {
  padding: 0;

  .item {
    font-size: 10px;
    color: #d32f2f;
    background: #d32f2f21;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px;

    i {
      display: none;
    }

    &::before {
      display: none;
    }

    p {
      margin: 0;
    }
  }
}

.alert.alert-danger {
  font-size: 10px;
  color: #d32f2f;
  background: #d32f2f21;
  border-radius: 3px;
  display: inline-block;
  padding: 5px 10px;

  &::before {
    display: none;
  }

  .js-error-text {
    margin-top: 0 !important;
    background: transparent !important;

    &:empty {
      display: none;
    }
  }
}

.ps-alert-success {
  padding: 20px;
  background-color: $light-gray;
  font-size: 13px;
  color: $dark-gray;
  margin: 0;

  li {
    padding: 0;

    i {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  p {
    margin: 0;
  }
}

.stars {
  i {
    font-size: 14px;
    color: $secondary;
    padding: 0;

    &.icon-mid-star {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &::before {
        z-index: 1;
      }

      &::after {
        content: "\e914";
        font-family: $icomoon;
        font-size: 14px;
        color: #e8e8e8;
        position: absolute;
        z-index: 0;
      }
    }

    &.icon-star--empty {
      color: #e8e8e8;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeslide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeslide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInSlideLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInSlideRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeslide-top {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes slide-top-intro {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
