/* component:: other:: intro faqs
--------------------------------------------------------------------------------------------------------------------- */
.faqs {
  min-height: 80vh;
}

.introfaqs {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include responsive-down(tablet-extra-small) {
    align-items: flex-end;
    justify-content: flex-start;
    height: 380px;
  }

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent linear-gradient(358deg, #393939 0%, #3b3b3b00 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    bottom: 0;
  }

  &__img {
    display: flex;
    width: 100%;
    height: 100%;

    img,
    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    position: absolute;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;

    @include responsive-down(tablet-extra-small) {
      bottom: 40px;
      left: 20px;
    }

    .title,
    .description {
      text-align: center;
      color: $white;

      @include responsive-down(tablet-extra-small) {
        text-align: left;
      }
    }

    .title {
      margin: 0 0 16px;

      @include responsive-down(tablet-extra-small) {
        font-size: 34px;
        margin: 0 0 16px;
      }
    }

    .description {
      margin: 0;
      font-size: 18px;

      @include responsive-down(tablet-extra-small) {
        font-size: 14px;
      }
    }
  }
}
