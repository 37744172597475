/* component::nav bar product
--------------------------------------------------------------------------------------------------------------------- */
.navproduct {
  background-color: $light-gray;
  border-top: 1px solid $hover-gray !important;
  border-bottom: 1px solid $hover-gray !important;
  width: 100%;
  z-index: 6;

  @include responsive-down(tablet) {
    top: 75px;
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
  }

  .container {
    display: flex;
    align-items: center;
    margin: 0;
    min-height: 47px;

    @include responsive-down(tablet-extra-large) {
      padding: 0 20px 0;
    }

    @include responsive-down(tablet) {
      padding: 0;
      display: none;
      height: 43px;
      min-height: auto;
    }
  }

  &__right {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;

    .introproduct__prices {
      margin: 0;

      .product-price {
        padding: 0;
      }

      @include responsive-down(tablet) {
        display: none;
      }
    }

    .iva {
      display: none;
    }

    .btn--addtocart {
      padding: 5px 10px;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        font-size: 18px;
        margin: 0 0 0 10px;
      }

      .introproduct__prices {
        display: none;
      }

      .text {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        @include responsive-down(tablet) {
          flex-direction: row;
        }
      }

      @include responsive-down(tablet) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;

        i {
          margin: 0 10px 0 0;
        }

        .text {
          order: 1;
          font-size: 16px;
          font-weight: 600;
        }

        .shopping-cart {
          order: 3;
        }

        .btn-add-to-cart__spinner {
          order: 4;
        }

        .introproduct__prices {
          display: block;
          order: 5;

          .current-price {
            align-items: center;
          }

          .discount-percentage {
            display: none;
          }

          .price-regular,
          .price-discount {
            color: $white;
            font-size: 14px;
          }

          .price-regular.current-price-discount {
            color: $red;
          }

          .product-price {
            padding: 0;
          }

          .price-before {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__menu {
    @include responsive-down(tablet) {
      padding: 0 20px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      height: 60px;
    }
  }

  &__item {
    font-size: 14px;
    position: relative;
    margin: 0 20px 0;
    padding: 15px 0;
    display: inline-block;

    @include responsive-down(tablet-extra-large) {
      margin: 0 10px 0;
    }

    @include responsive-down(tablet) {
      margin: 0 25px 0 0;
      padding: 13px 0;
    }

    span {
      position: relative;

      &::before {
        content: "";
        height: 1.5px;
        position: absolute;
        bottom: -15px;
        width: 100%;
        background-color: $mid-gray;
        left: 0;
        opacity: 0;

        @include transition(all 0.35s ease-in);

        @include responsive-down(tablet) {
          height: 1.5px;
          position: absolute;
          bottom: -11px;
        }
      }
    }

    @include responsive-down(tablet) {
      display: inline-block;
      color: $grayac;
      font-size: 16px;
    }

    &--active {
      color: $mid-gray;

      span {
        &::before {
          opacity: 1;

          @include transition(all 0.35s ease-in);
        }
      }
    }
  }

  &--fixed {
    position: fixed;
    top: 91px;

    @include transition(all 0.35s ease-in);

    @include responsive-down(tablet) {
      top: 53px;
      visibility: visible;
      opacity: 1;
      height: auto;

      .container {
        display: block;
      }
    }
  }
}

.addtocart---mobile {
  @include responsive-up(tablet) {
    display: none;
  }
}

.addtocart---desktop {
  @include responsive-down(tablet) {
    display: none;
  }
}

.addtocart-fixed {
  @include responsive-down(tablet) {
    visibility: hidden;
    opacity: 0;
    height: 0;
    position: fixed;
    bottom: 20px;
    width: calc(100% - 20px);
    margin: 0 10px;
    z-index: -1111;

    @include transition(all 0.35s ease-in);
  }

  &--active {
    @include responsive-down(tablet) {
      visibility: visible;
      opacity: 1;
      height: auto;
      z-index: 3;

      @include transition(all 0.35s ease-in);
    }
  }
}

body.scroll-down {
  .navproduct--fixed {
    top: 91px;

    @include transition(all 0.35s ease-in);

    @include responsive-down(tablet) {
      top: 53px;
    }
  }

  &.topbar {
    .navproduct--fixed {
      top: 97px;

      @include transition(all 0.35s ease-in);

      @include responsive-down(tablet) {
        top: 78px;
      }
    }
  }
}

body.topbar {
  .navproduct--fixed {
    top: 118px;

    @include responsive-down(tablet) {
      top: 78px;
    }
  }
}
