/* Layouts: Pages: Checkout: Cart
--------------------------------------------------------------------------------------------------------------------- */
.page-cart {
  .alert.alert-danger {
    margin: 30px 0 0;

    @include responsive-down(mobile-large) {
      margin: 20px 0 0;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        font-size: 14px;

        @include responsive-down(mobile-large) {
          font-size: 13px;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  //Generic styles for Prestashop base cart.tpl (NO TPL MODIFICATIONS)
  .cart-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px auto;
    max-width: 1200px;
    min-height: 70vh;
    padding-top: 50px;

    @include responsive-down(tablet-extra-large) {
      width: calc(100% - 60px);
    }

    @include responsive-down(tablet) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 20px auto;
      padding-top: 20px;
    }

    &-body {
      width: calc(70% - 60px);
      padding-right: 100px;

      @include responsive-down(tablet-extra-large) {
        padding-right: 100px;
      }

      @include responsive-down(tablet-large) {
        padding-right: 30px;
      }

      @include responsive-down(tablet) {
        width: 100%;
        padding-right: 0;
      }

      .cart__card-body {
        padding: 20px 0 20px;
      }

      .card {
        &-header {
          font-size: 20px;

          &__total-products {
            color: $light-gray;
            font-size: 16px;
            margin-left: 8px;
          }
        }

        &-body {
          .cart__card-loader {
            display: none;
          }

          .cart-overview {
            .cart-items {
              margin: 16px 0;

              @include responsive-down(tablet) {
                margin: 10px 0 15px;
              }

              list-style: none;
              padding: 0;

              .cart-item {
                border-radius: 0;
                border-bottom: 1px solid #f6f6f6;
                padding: 30px 0;
                position: relative;
                min-height: 150px;

                .product-line-grid {
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;

                  @include responsive-down(mobile) {
                    height: 120px;
                  }

                  .media {
                    display: flex;

                    img {
                      width: 100px;
                      height: auto;
                      margin-right: 20px;
                      background: #f6f6f6;
                    }

                    &-body {
                      .product-line__title {
                        &:hover {
                          @include responsive-up(tablet-large) {
                            color: rgba(0, 0, 0, 0.65);

                            @include transition(all 0.25s ease-in-out);
                          }
                        }
                      }

                      .product-line-info.product-price {
                        .product-discount {
                          display: flex;
                          align-items: center;
                          margin: 0 0 10px;

                          @include responsive-down(mobile) {
                            flex-direction: column;
                            align-items: flex-end;

                            .discount-percentage {
                              margin: 0 0 5px;
                            }
                          }

                          .regular-price {
                            text-decoration: line-through;
                            color: #bfbfbf;
                            order: 2;
                          }

                          .discount.discount-amount,
                          .discount.discount-percentage {
                            background: $red;
                            color: $white;
                            padding: 3px 6px;
                            margin-right: 10px;
                            font-size: 10px;
                            line-height: 12px;
                            order: 1;

                            @include responsive-down(mobile-extra-large) {
                              padding: 3px 4px;
                            }

                            @include responsive-down(mobile) {
                              margin-right: 0;
                            }
                          }
                        }

                        &.has-discount {
                          .current-price-display {
                            color: $red;
                          }
                        }

                        .current-price {
                          display: flex;
                          justify-content: flex-end;
                          margin: 10px 0;

                          &-display {
                            font-size: 18px;
                            line-height: 14px;
                            color: $black;
                            font-weight: 600;

                            @include responsive-down(mobile-extra-large) {
                              font-size: 15px;
                            }
                          }
                        }
                      }

                      .small {
                        margin: 7.5px 0;
                        font-size: 11px;
                        line-height: 11px;

                        .value {
                          font-weight: 500;
                        }
                      }
                    }
                  }

                  .product-line {
                    &__title {
                      font-size: 14px;
                      max-width: 250px;
                      display: inline-block;
                      line-height: 1.3;

                      @include responsive-down(tablet) {
                        width: 80%;
                        margin: 0 10px 0 0;
                      }
                    }

                    &__body {
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      height: calc(100% - 10px);
                    }
                  }

                  .product-line-grid-right.product-line-actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0 0 0 120px;
                    justify-content: flex-start;
                    justify-content: space-between;
                    margin: -20px 0 0;

                    & > div:first-child {
                      display: flex;
                      align-items: center;
                      position: relative;
                      left: auto;
                      bottom: auto;
                      padding: 0 20px 0 0;

                      span {
                        font-size: 10px;
                        margin-right: 10px;
                      }
                    }

                    .input-group.bootstrap-touchspin {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      width: auto;
                      align-items: center;
                      position: relative;

                      @include responsive-down(mobile) {
                        width: 90px;
                      }

                      .form-control {
                        margin: 0 5px;
                        padding: 10px 0;
                        width: 50px;
                        text-align: center;
                        border: none;
                        display: inline-block;
                        background: transparent;
                        -webkit-appearance: none;
                        position: absolute;
                        left: 25px;

                        @include responsive-down(tablet-extra-small) {
                          width: 40px;
                        }

                        @include responsive-down(mobile) {
                          left: 15px;
                        }

                        &:hover,
                        &:focus {
                          margin: 0 5px;

                          /* Chrome, Safari, Edge, Opera */
                          &::-webkit-outer-spin-button,
                          &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0 5px;
                          }

                          /* Firefox */
                          input[type=number] {
                            -moz-appearance: textfield;
                          }
                        }
                      }

                      span.input-group-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        button {
                          background: #f6f6f6;
                          border: none;
                          padding: 6px;
                          color: $black;
                          letter-spacing: 0;
                          line-height: 0;
                          width: 25px;
                          height: 25px;
                          border-radius: 50px;
                          font-size: 22px;

                          &:hover {
                            @include responsive-up(tablet) {
                              background: #e9e9e9;
                            }
                          }
                        }

                        &.input-group-prepend {
                          margin-right: 0;
                        }
                      }
                    }

                    .product-price {
                      display: none;
                    }

                    .cart-line-product-actions {
                      display: flex;
                      justify-content: flex-end;

                      a {
                        text-decoration: underline;
                        font-size: 13px;

                        &:hover {
                          @include responsive-up(tablet) {
                            text-decoration: none;
                            color: $black;
                          }
                        }
                      }
                    }

                    .input-group-btn-vertical {
                      display: flex;
                      flex-direction: row-reverse;
                      width: 100px;
                      justify-content: space-between;

                      .btn-touchspin {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        color: $black;
                        z-index: 3;
                        height: 25px;
                        width: 25px;
                        background-color: $light-gray;
                        border-radius: 50px;
                        padding: 0;

                        @include transition(all 0.2s ease-in);

                        &.bootstrap-touchspin-up {
                          &::after {
                            content: "\e91d";
                            font-size: 10px;
                            z-index: 1;
                            font-family: $icomoon;
                            color: $black;
                            font-weight: 200;
                          }
                        }

                        &.bootstrap-touchspin-down {
                          &::after {
                            content: "\e91c";
                            font-size: 10px;
                            font-family: $icomoon;
                            z-index: 1;
                            font-weight: 200;
                            color: $black;
                          }
                        }

                        &:hover {
                          @include responsive-up (tablet) {
                            background-color: #e9e9e9;
                            border: none;

                            @include transition(all 0.2s ease-in);
                          }
                        }

                        .touchspin-down,
                        .touchspin-up {
                          display: none;
                        }
                      }
                    }
                  }
                }

                .product-line-info {
                  position: absolute;
                  top: 25px;
                  right: 0;

                  @include responsive-down(mobile) {
                    position: relative;
                    top: 0;
                  }
                }

                &:first-child {
                  padding-top: 0;

                  .product-line-info {
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }

      //button seguir comprando
      .buynext {
        display: none;
      }
    }

    &-totals {
      display: flex;
      justify-content: flex-end;

      p {
        font-size: 16px;
        font-weight: 700;
        color: $black;
        margin: 0;

        span {
          margin-left: 40px;
          font-size: 18px;
        }
      }
    }

    &-right {
      width: calc(30% - 15px);
      margin: 15px 0 40px;

      & > p {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 35px;
      }

      @include responsive-down(tablet) {
        width: 50%;
        margin: 30px 0;
      }

      @include responsive-down(mobile-large) {
        width: 100%;
      }

      .card {
        border-radius: 0;

        &-body {
          .cart-detailed-totals {
            background: #f6f6f6;
            padding: 15px;

            .cart-summary-line {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              span {
                font-size: 14px;
                line-height: 28px;
                color: $dark-gray;

                &.value {
                  color: $black;
                  font-weight: 700;
                }
              }

              &.remove-code a {
                font-size: 11px;
                text-decoration: underline;
                color: #989898;
              }

              &.cart-total {
                .value {
                  font-weight: 700;
                }
              }
            }

            .cart-summary-totals {
              margin-top: 10px;
              padding-top: 10px;
              border-top: 1px solid #e3e3e3;

              span {
                &.label {
                  font-size: 14px;
                  color: $black;
                  font-weight: 800;
                }

                span {
                  font-size: 10px;
                  color: $dark-gray;
                  margin-left: 5px;
                  font-weight: 300;
                }
              }
            }

            .block-promo .input-group {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .form-control {
                width: 100%;
                padding: 11px;
                border: 1px solid $light-gray;
                border-right: 0;
                font-size: 12px;
                color: $light-gray;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;

                &::placeholder {
                  color: $light-gray;
                }
              }

              .btn {
                &--secondary {
                  font-size: 12px;
                  letter-spacing: normal;
                  text-transform: inherit;
                  padding: 6px 18px;
                  border: 1px solid $black;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
            }
          }
        }

        &-footer {
          .cart-detailed-actions {
            .text-sm-center {
              display: flex;
              justify-content: center;
              align-items: center;

              @include responsive-down(tablet) {
                position: fixed;
                bottom: 0;
                right: 0;
                width: 100%;
                padding: 20px;
                z-index: 9;
                background: #fff;
                border-top: 1px solid #e0e0e0;
                align-items: flex-start;
              }

              .button-cart-resume {
                display: flex;
                flex-direction: column;
                min-width: 100px;

                @include responsive-up(tablet) {
                  display: none;
                }

                &__total {
                  font-size: 11px;
                  line-height: 11px;
                  color: $light-gray;
                }

                &__price {
                  font-size: 15px;
                  line-height: 22px;
                }
              }

              a {
                font-size: 16px;
                padding: 10px;
                width: 100%;
                margin: 20px 0;
                display: flex;
                justify-content: space-between;

                @include responsive-down(tablet) {
                  margin: 0;
                }
              }
            }
          }

          .checkout.text-sm-center {
            display: flex;
            justify-content: center;
            padding: 20px 0;

            button {
              font-size: 16px;
              width: 100%;
              display: flex;
              justify-content: space-between;

              &.disabled {
                cursor: not-allowed;
              }
            }
          }

          &__delivery {
            .text-delivery {
              display: flex;
              align-items: center;
              margin: 30px 0 0;
            }

            i {
              display: inline-block;
              margin-right: 8px;
              font-size: 24px;
            }

            span {
              font-size: 12px;
              color: $dark-gray;
            }
          }

          &__payment {
            margin: 30px 0 0;

            span {
              font-size: 14px;
              color: $dark-gray;
            }
          }

          &__logos {
            display: flex;
            align-items: center;
            margin-top: 10px;

            svg,
            img {
              width: 50px;
              margin-right: 20px;
              fill: $dark-gray;

              &.visa {
                width: 40px;
              }

              &.master {
                width: 80px;
              }

              &.bizum {
                width: 55px;
              }
            }
          }

          &__faqs {
            margin-top: 40px;

            p {
              margin-bottom: 15px;
            }

            .titlefaqs {
              font-size: 18px;
              color: $black;
              font-weight: 800;
            }

            ul {
              padding: 0;

              li {
                margin: 10px 0;
                padding: 0;
                color: $mid-gray;
                font-size: 14px;
                cursor: pointer;

                &::before {
                  display: none;
                }

                a {
                  text-decoration: underline;

                  &:hover {
                    @include responsive-up(tablet) {
                      text-decoration: none;
                      color: $black;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .block-promo {
        margin: 30px 0 0;

        .cart-summary-line.remove-code {
          &:last-child {
            margin-bottom: 15px;
          }
        }
      }
    }

    .no-items {
      padding: 30px 0;

      &__title {
        font-weight: 600;
        margin-bottom: 10px;
      }

      &__buttons {
        display: flex;
        margin-top: 20px;

        @include responsive-down(mobile-large) {
          flex-direction: column;
        }

        a {
          width: auto;
          letter-spacing: 0;
          font-size: 14px;
          font-weight: 400;

          &:first-child {
            margin-right: 10px;

            @include responsive-down(mobile-large) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .cart-no-products {
      .cart-grid-totals {
        display: none;
      }
    }
  }

  .notifications-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px auto;
    max-width: 1200px;

    @include responsive-down(tablet-extra-large) {
      width: calc(100% - 60px);
    }

    @include responsive-down(tablet) {
      flex-direction: column;
      align-items: flex-end;
      width: calc(100% - 40px);
      margin: 20px auto;
    }

    article.alert {
      width: 100%;
      padding: 20px;
      text-align: center;
      background: #f1f1f1;
      border: 1px solid transparent;

      li {
        font-size: 13px;
        font-weight: 400;
        padding: 0;
      }

      &.alert-danger {
        border: 1px solid #ce5f68;
      }
    }
  }

  //TODO. @Dayane quitar los producto desde el código
  .featured-products {
    display: none;
  }
}

.modalcarthelp {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 99999999;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  &__before {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 99999999;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  &__close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 100;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    border: none;

    @include responsive-down(tablet-small) {
      right: 10px;
      top: 10px;
    }

    &::before {
      content: "";
      background-color: $white;
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50px;

      @include transition(all 0.1s ease-in);
    }

    .icon-close {
      position: absolute;
      width: 16px;
      height: 1px;
      background-color: $black;
      transition: transform 0.45s cubic-bezier(0.21, 0.61, 0.35, 1);
      transform: rotate(-45deg);

      @include responsive-down(mobile-extra-large) {
        width: 15px;
      }

      &::before {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        height: 1px;
        background-color: $black;
        transition: transform 0.45s cubic-bezier(0.21, 0.61, 0.35, 1);
        transform: rotate(90deg);
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        border-radius: 50px;

        &::before {
          width: 50px;
          height: 50px;

          @include transition(all 0.1s ease-in);
        }
      }
    }
  }

  &__content {
    height: auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    z-index: 99999999;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    display: flex;
    align-items: center;
  }

  &__intro {
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
    text-align: center;
  }

  &__title {
    margin: 0;
    font-size: 16px;
    text-align: left;
  }

  &__body {
    padding: 20px;

    p {
      margin: 13px 0;

      @include responsive-down(mobile-large) {
        font-size: 13px;
      }
    }
  }

  &.modal-open {
    display: flex;

    .modalcarthelp__before {
      display: block;
    }
  }
}
