/* component::product list
--------------------------------------------------------------------------------------------------------------------- */
.sectioncategories {
  padding: 70px 0 50px;
  display: grid;
  grid-template-columns: 2.5fr 2fr 2fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 40px;

  @include responsive-down(tablet-extra-large) {
    align-items: inherit;
    display: flex;
    flex-wrap: wrap;
  }

  @include responsive-down(tablet-extra-small) {
    grid-gap: 20px;
  }

  @include responsive-down(tablet) {
    padding: 30px 20px;
  }

  @include responsive-down(mobile) {
    padding: 20px 0;
  }

  .itemcategory {
    position: relative;
    height: 100%;
    display: block;

    @include responsive-down(tablet-extra-large) {
      width: calc(100% / 2 - 5px);
      height: 250px;
    }

    @include responsive-down(tablet-extra-small) {
      width: 100%;
      height: 250px;
    }

    @include responsive-down(mobile-extra-large) {
      height: 210px;
    }

    &--big {
      grid-row: 1 / 3;
      aspect-ratio: auto;
      // height: 100%;
      min-height: 510px;
      max-height: 510px;

      @include responsive-down(tablet-extra-large) {
        max-height: 350px;
        min-height: 350px;
        width: 100%;
      }

      @include responsive-down(tablet-extra-small) {
        max-height: 250px;
        min-height: 250px;
      }

      @include responsive-down(mobile-extra-large) {
        max-height: 210px;
        min-height: 210px;
      }
    }
  }

  // &__big {
  //   display: inline-block;
  //   width: calc(100% / 3 + 100px);
  //   margin: 0 5px 0 0;
  //   min-height: 100%;

  //   @include responsive-down(tablet-extra-large) {
  //     width: 100%;
  //     margin: 0 0 10px;
  //   }

  //   @include responsive-down(tablet-extra-small) {
  //     width: 100%;
  //     margin: 0 0 20px;
  //   }

  //   .itemcategory {
  //     width: 100%;
  //     height: 100%;

  //     @include responsive-down(tablet-extra-large) {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       height: auto;
  //     }

  //     @include responsive-down(tablet-extra-large) {
  //       max-height: 350px;
  //     }

  //     @include responsive-down(tablet-extra-small) {
  //       max-height: 250px;
  //       height: 250px;
  //     }

  //     @include responsive-down(mobile-extra-large) {
  //       max-height: 210px;
  //     }

  //     img,
  //     video {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }
  // }

  // &__grid {
  //   display: flex;
  //   flex-wrap: wrap;
  //   width: calc(100% / 3 * 2);

  //   @include responsive-down(tablet-extra-large) {
  //     width: calc(100% + 10px);
  //     margin: 0 -5px;
  //   }

  //   @include responsive-down(tablet-extra-small) {
  //     flex-direction: column;
  //     width: 100%;
  //     margin: 0;
  //   }

  //   .itemcategory {
  //     margin: 0 5px;
  //     width: calc(100% / 2 - 10px);

  //     @include responsive-down(tablet-extra-small) {
  //       width: 100%;
  //       margin: 0 0 20px;
  //       max-height: 250px;
  //     }

  //     @include responsive-down(mobile-extra-large) {
  //       max-height: 210px;
  //     }

  //     &:nth-child(1),
  //     &:nth-child(2) {
  //       margin: 0 5px 10px;

  //       @include responsive-down(tablet-extra-small) {
  //         margin: 0 0 20px;
  //       }
  //     }
  //   }
  // }
}
