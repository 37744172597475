
/* MODAL------------------------------------------------------------------------------------------------------------- */
.modalgallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(35, 35, 35, 0.3);
  z-index: 1002;
  justify-content: center;
  align-items: center;
  display: none;

  &--active {
    display: flex;
  }

  &__content {
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $light-gray;
    padding: 29px 40px;
    display: flex;
    align-items: center;

    @include responsive-down(tablet) {
      padding: 20px;
    }
  }

  &__list {
    width: 600px;
    height: 100%;

    // @include responsive-down(tablet) {
    //   width: 70%;
    // }

    @include responsive-down(mobile-large) {
      width: 100%;
    }

    .tns-outer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      @include responsive-down(tablet-extra-small) {
        height: 100%;
      }
    }

    .tns-nav {
      height: 50px;
      margin: 20px 0 0;

      // @include responsive-down(tablet-extra-small) {
      //   position: absolute;
      //   bottom: 30px;
      // }

      @include responsive-down(mobile-large) {
        position: relative;
        bottom: 0;
      }

      button {
        padding: 20px 10px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        position: relative;

        &::before {
          content: "";
          height: 8px;
          width: 8px;
          background-color: $grayac;
          position: absolute;
          border-radius: 50px;
        }

        &.tns-nav-active {
          &::before {
            background-color: $black;
          }
        }
      }
    }

    .tns-ovh {
      display: flex;
      align-items: center;
    }
  }

  &__item {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    right: 40px;
    top: 40px;
    z-index: 100;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive-down(tablet-extra-small) {
      right: 20px;
      top: 20px;
    }

    &:hover {
      @include responsive-up(tablet) {
        &::before {
          transform: scale(1.8);

          @include transition(all 0.1s ease-in);
        }
      }
    }

    &::before {
      content: "";
      height: 40px;
      width: 40px;
      border-radius: 50px;
      position: absolute;
      background-color: $white;

      @include transition(all 0.1s ease-in);

      @include responsive-down(tablet-extra-small) {
        height: 35px;
        width: 35px;
      }
    }

    .icon-close {
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: $black;
      transition: transform 0.45s cubic-bezier(0.21, 0.61, 0.35, 1);
      transform: rotate(-45deg);

      @include responsive-down(mobile-extra-large) {
        width: 17px;
        height: 1px;
      }

      &::before {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        height: 2px;
        background-color: $black;
        transition: transform 0.45s cubic-bezier(0.21, 0.61, 0.35, 1);
        transform: rotate(90deg);

        @include responsive-down(mobile-extra-large) {
          height: 1px;
        }
      }
    }
  }

  &__nav {
    display: flex;

    .slider__arrow {
      position: absolute;
      top: 44%;
      cursor: pointer;
      width: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      i {
        z-index: 1;
      }

      &::after {
        content: "";
        background-color: #e5e5e5;
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        z-index: 0;
        transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
      }

      &:hover {
        @include responsive-up(tablet) {
          &::after {
            background-color: #cecece;
          }
        }
      }

      &--prev {
        left: 150px;

        @include responsive-down(tablet-large) {
          left: 50px;
        }

        @include responsive-down(mobile-large) {
          left: 20px;
          bottom: 110px;
          top: auto;
        }
      }

      &--next {
        right: 150px;

        @include responsive-down(tablet-large) {
          right: 50px;
        }

        @include responsive-down(mobile-large) {
          right: 20px;
          bottom: 110px;
          top: auto;
        }
      }
    }
  }
}
