/* common::grid
--------------------------------------------------------------------------------------------------------------------- */
.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include responsive-down(tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include responsive-down(mobile-large) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--large {
    max-width: 1920px;
  }

  &--medium {
    max-width: 1600px;

    @include responsive-up(desktop-large) {
      max-width: 1350px;
    }

    @include responsive-down(desktop) {
      max-width: 1240px;
    }

    @include responsive-down(desktop-small) {
      max-width: 1200px;
    }
  }

  &--medium-small {
    max-width: 1200px;

    @include responsive-up(desktop-large) {
      max-width: 1800px;
    }
  }

  &--small {
    max-width: 1024px;
  }

  &--extrasmall {
    max-width: 900px;
  }
}
