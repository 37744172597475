/* component:: other:: intro faqs
--------------------------------------------------------------------------------------------------------------------- */
.faqscontent {
  padding: 90px 0;

  @include responsive-down(mobile-large) {
    padding: 60px 0;
  }

  &__list {
    padding: 0;
  }

  &__item {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $hover-gray;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 0;
    cursor: pointer;
    transition: height 0.5s ease;
    position: relative;
    overflow: hidden;

    &:last-child {
      border-bottom: 1px solid $hover-gray;
    }

    .faqtitle {
      position: relative;
      display: inline-block;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      p {
        margin: 0;
      }
    }

    .faqcontent {
      margin: 0;
      padding: 0 3em 0 20px;
      height: 0;
      font-size: 12px;
      line-height: 20px;
      opacity: 0;
      transition: opacity 0.1s ease;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--opened {
      height: auto;
      transition: height 0.5s ease;

      .faqcontent.faqcontent {
        opacity: 1;
        transition: opacity 0.5s ease;
        padding: 0 3em 0 20px;
        height: auto;
        line-height: 20px;
        font-size: 14px !important;

        @include responsive-down(mobile) {
          width: 100%;
        }

        p,
        a {
          font-size: 14px !important;
          line-height: 20px;

          @include responsive-down(tablet) {
            font-size: 14px !important;
          }
        }

        ul,
        ol {
          margin: 0 14px 14px;

          li {
            font-size: 14px !important;
            line-height: 25px;
            margin-bottom: 0;
            padding: 0 0 10px 0;
            list-style: none;
            color: #525252;
          }
        }

        img {
          max-width: 100% !important;
          width: 100%;
          height: 100%;
          padding: 15px 0;
        }
      }

      .faqscontent__cross {
        animation: plus-animation 0.5s ease-out forwards;
      }
    }

    &::before {
      position: absolute;
      left: 0;
    }

    &:hover {
      .faqtitle {
        @include responsive-up(tablet) {
          color: $primary;

          @include transition (all 0.2s ease-in);
        }
      }
    }

    &--hovered {
      .faqscontent__cross-circle {
        .circle {
          &::before {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  &__cross {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: plus-animation-reverse 0.5s ease-out forwards;
    z-index: 8;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 4px;
      background: $primary;
    }

    &::before {
      width: 1px;
      height: 15px;
    }

    &::after {
      width: 15px;
      height: 1px;
    }

    &-circle {
      position: absolute;
      right: -7px;
      top: -7px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 100px;
      z-index: 7;

      .circle {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 100px;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: #ededed;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          border-radius: 50%;
          transform: translate3d(0, 100%, 0);
          transition: transform 0.6s;
          transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
        }
      }
    }
  }

  &__input {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    opacity: 0;
  }
}

@keyframes plus-animation {
  0% {
    transform: rotate(0) scale(1);
  }

  20% {
    transform: rotate(60deg) scale(0.93);
  }

  55% {
    transform: rotate(35deg) scale(0.97);
  }

  80% {
    transform: rotate(48deg) scale(0.94);
  }

  100% {
    transform: rotate(45deg) scale(0.95);
  }
}
