/* component::nav bar product
--------------------------------------------------------------------------------------------------------------------- */
.featuredattributes {
  width: 100%;
  background-color: $light-gray;
  padding: 35px 0;

  @include responsive-down(tablet-extra-small) {
    margin: 50px 0;
    padding: 75px 0;
  }

  @include responsive-down(mobile-large) {
    margin: 0;
    padding: 70px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 130px;

    @include responsive-down(tablet) {
      padding: 0 70px;
    }

    @include responsive-down(tablet-small) {
      padding: 0 30px;
    }

    @include responsive-down(mobile-large) {
      flex-wrap: wrap;
      padding: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 150px;

    @include responsive-down(mobile-large) {
      width: calc(100% / 2);
      max-width: none;

      &:nth-child(1),
      &:nth-child(2) {
        padding: 0 0 40px;
      }
    }

    .img {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .attribute {
      margin: 18px 0 0;
      display: inline-block;
      text-align: center;
      max-width: 150px;

      p {
        margin: 0;
        text-align: center;
      }
    }
  }
}
