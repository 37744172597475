/* Layouts: Pages: Checkout: Order
--------------------------------------------------------------------------------------------------------------------- */
.page-order {
  .l-header {
    .header-nav {
      display: flex;
      justify-content: flex-start;
      padding: 12.5px 50px;
      border-bottom: 1px solid #e3e3e3;
      position: fixed;
      top: 0;
      left: 0;
      background: $white;
      width: 100%;
      z-index: 9;

      @include responsive-down(tablet) {
        padding: 12.5px 20px;
      }

      .header__logo {
        @include responsive-down (tablet) {
          width: 100%;
          justify-content: center;
        }
      }

      .small {
        display: none;
      }

      &--checkout {
        background-color: $primary;

        .header__logo svg {
          width: 135px;
        }
      }
    }
  }

  section.l-wrapper.l-wrapper--boxed {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 100px;
    padding-left: 100px;
    padding-right: 100px;
    min-height: 90vh;

    @include responsive-up (desktop) {
      padding-left: 250px;
      padding-right: 300px;
    }

    @include responsive-down (tablet-extra-large) {
      padding-left: 20px;
      padding-right: 20px;
    }

    #content {
      & > .row {
        display: flex;
        justify-content: space-between;

        @include responsive-down(tablet) {
          flex-direction: column;
          padding-top: 0;
        }

        .cart-grid-right {
          position: fixed;
          right: 200px;
          width: calc(40% - 220px);
          margin: 0 0 0 50px;

          @include responsive-down(desktop) {
            right: 100px;
            width: calc(40% - 150px);
          }

          @include responsive-down(tablet-extra-large) {
            right: 30px;
            width: calc(40% - 80px);
          }

          @include responsive-down(tablet) {
            padding: 40px 0 0;
            position: relative;
            width: 100%;
            right: initial;
            margin: 0;
          }

          @include responsive-up(height-mobile) {
            position: relative;
            right: 0;
          }
        }

        .col-lg-8 {
          width: calc(70% - 110px);
          border-radius: 0;
          padding-top: 0;
          border-top: none;

          @include responsive-down(tablet) {
            width: 100%;
          }

          .checkout-process {
            width: 100%;

            .checkout-step {
              width: 100%;
              margin-bottom: 25px;
              border: 0.5px solid #707070;
              border-radius: 3px;
              padding: 0 30px 0;

              @include responsive-down(mobile) {
                padding: 0 15px;
                margin-bottom: 20px;
              }

              .collapse {
                display: none;
                width: auto;
                margin: initial;
                padding: 0;

                .content.card-body {
                  padding: 10px 0 20px;
                }

                .nav.nav-inline.my-2 {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin: 40px 0;
                  padding: 0;

                  @include responsive-down(mobile-large) {
                    margin: 20px 0;
                  }

                  .nav-item {
                    margin: 0;
                    padding: 0;

                    &::before {
                      display: none;
                    }

                    .nav-link {
                      color: $primary;
                      border: 1px solid $primary !important;
                      border-radius: 3px;
                      padding: 9px 22px;
                      font-weight: 600;
                      background: $white;
                      min-width: 150px;
                      text-align: center;
                      font-size: 14px;

                      @include responsive-down(mobile) {
                        min-width: inherit;
                        width: 100%;
                        display: inline-block;
                      }

                      &.active {
                        color: $white;
                        background-color: $primary;
                        border: 1px solid $primary !important;

                        &::after {
                          width: 100%;
                        }
                      }

                      &[aria-selected="true"] {
                        border: 1px solid $black;
                      }
                    }

                    &:first-child {
                      .nav-link {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;

                        @include transition($transition-fast);

                        &:hover {
                          @include responsive-up(tablet) {
                            background-color: $primary;
                            border: 1px solid $primary;
                            color: $white;

                            @include transition($transition-slow);
                          }
                        }
                      }
                    }

                    &:last-child {
                      .nav-link {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;

                        @include transition($transition-fast);

                        &:hover {
                          @include responsive-up(tablet) {
                            background-color: $primary;
                            border: 1px solid $primary;
                            color: $white;

                            @include transition($transition-slow);
                          }
                        }
                      }
                    }
                  }
                }

                .form-fields {
                  display: flex;
                  flex-wrap: wrap;
                  width: calc(100% + 20px);
                  margin-right: -20px;

                  .form-group {
                    width: calc(50% - 30px);
                    margin: 15px 30px 15px 0;
                    position: relative;

                    @include responsive-down(tablet) {
                      width: 100%;
                      margin: 10px 20px 10px 0;
                    }

                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                      width: calc(50% - 30px);

                      @include responsive-down(mobile-large) {
                        width: calc(100% - 20px);
                        margin: 5px 0 10px 0;
                      }
                    }

                    &.form-group_forgot-password {
                      width: calc(100% - 20px);

                      @include responsive-down(mobile-large) {
                        width: 100%;
                        margin: 5px 0 10px 0;
                      }

                      .forgot-password__link {
                        text-decoration: underline;
                      }
                    }

                    .custom-select {
                      width: 100%;
                      height: 44px;
                      outline: none;
                      padding: 0;
                      padding-left: 12px;
                      font-size: 14px;
                      opacity: 0.8;
                      z-index: 2;
                      position: relative;
                      color: #8d8d8d !important;
                      background-color: #f7f7f7;
                      border: 1px solid #c4c4c4;
                      margin: 0;
                      border-radius: 4px;

                      & + label {
                        display: none;
                      }
                    }

                    &#delivery {
                      width: 100%;

                      label {
                        font-size: 11px;
                        color: $black;
                        margin: 30px 0 5px;
                      }
                    }

                    &.use-adress {
                      label {
                        font-size: 14px;
                        top: 0;
                        display: inline-block !important;
                        margin: 20px 0;
                      }
                    }
                  }

                  .custom-control.custom-checkbox {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    .custom-control-label {
                      font-size: 13px;
                      line-height: 13px;
                      color: $dark-gray;
                      margin-left: 5px;
                    }
                  }

                  .create-account {
                    display: flex;
                    flex-direction: column;

                    span {
                      &.text-muted {
                        font-size: 12px;
                        margin-top: 5px;
                        color: $dark-gray;
                      }
                    }
                  }
                }

                .tab-content {
                  form {
                    .form-fields {
                      .form-group {
                        &.form-group--radio {
                          display: flex;
                          align-content: center;
                          align-items: center;

                          .label.mr-3 {
                            display: flex;
                            align-items: center;
                          }
                        }

                        &.form-group--news {
                          .custom-control.custom-checkbox {
                            display: flex;
                            align-items: flex-start;
                            padding: 20px;
                            background-color: $green-news;
                          }
                        }

                        &.form-group--fullwidth {
                          width: 100%;
                        }

                        .custom-control.custom-radio.custom-control-inline {
                          display: flex;
                          align-items: center;
                          margin: 0 10px;

                          input {
                            margin-top: 0;
                            margin-right: 5px;
                          }
                        }

                        small.form-text.text-muted {
                          display: none;
                        }

                        .input-group {
                          position: relative;
                        }

                        .custom-control.custom-checkbox {
                          display: flex;
                          align-items: flex-start;

                          .radio {
                            display: flex;
                            margin: 2px 0 0 0;
                          }

                          input[type="checkbox"] {
                            margin-top: 0;
                            margin-right: 5px;
                          }

                          label {
                            line-height: 1.2;
                            position: inherit;
                            max-height: inherit;

                            em {
                              max-width: 550px;
                              width: 100%;
                              display: block;
                              font-size: 10px;
                              margin-top: 5px;
                              color: $hover-gray-footer;
                            }
                          }
                        }

                        &.customer_privacy,
                        &.psgdpr {
                          width: 100%;
                        }

                        select {
                          width: 100%;
                          height: 44px;
                          outline: none;
                          padding: 10px 25px 0;
                          padding-left: 18px;
                          font-size: 14px;
                          opacity: 0.8;
                          z-index: 2;
                          position: relative;
                          color: #8d8d8d !important;
                          background-color: #f7f7f7;
                          border: 1px solid #c4c4c4;
                          margin: 0;
                          border-radius: 4px;
                        }

                        &.form-group--password {
                          .form-group {
                            margin: 0;
                            width: 100%;
                          }
                        }
                      }

                      & > .form-informations {
                        width: calc(100% - 20px);
                        margin: 20px 0 0;
                        line-height: 1.6;

                        .font-weight-bold {
                          font-weight: 600;
                          font-size: 18px;
                        }
                      }
                    }

                    .form-footer {
                      display: flex;
                      justify-content: flex-start;

                      button,
                      a {
                        font-size: 16px;
                        width: auto;
                        padding: 8px 24px;
                        margin: 20px 0;
                      }

                      .forgot-password {
                        margin-right: 10px;
                      }
                    }
                  }

                  .checkout-form {
                    display: none;

                    &.active {
                      display: block;
                    }
                  }

                  .tab-pane {
                    display: none;

                    &.active {
                      display: block;
                    }
                  }
                }

                .js-address-form {
                  form {
                    & > p {
                      &:first-of-type {
                        width: 70%;
                        margin: 20px 0 40px;
                        font-size: 14px;
                        line-height: 1.5;
                        color: $dark-gray;

                        @include responsive-down(tablet) {
                          width: 100%;
                        }
                      }
                    }
                  }
                }

                #delivery-address {
                  .form-group {
                    &:nth-of-type(5) {
                      width: calc(100% - 30px);
                      margin: 40px 0;

                      @include responsive-down(tablet) {
                        width: 100%;
                        margin: 10px 20px 10px 0;
                      }
                    }
                  }

                  .custom-control.custom-checkbox {
                    width: 100%;
                    margin: 40px 0 20px;
                  }
                }

                &#content-checkout-payment-step {
                  padding: 20px 0;
                }

                &#content-checkout-addresses-step,
                &#content-checkout-delivery-step {
                  padding: 0 0 30px;
                }

                .form-footer {
                  button,
                  a {
                    width: auto;
                    margin-top: 20px;
                    padding: 8px 24px;
                    font-size: 13px;
                  }
                }

                .address-selector {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  margin: 20px 0;

                  @include responsive-down(mobile-large) {
                    margin: 20px 0;
                  }

                  .col-12.col-md-6.col-lg-4.mb-3 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: calc(50% - 15px);
                    border: 1px solid #eee;
                    padding: 20px;
                    margin-bottom: 20px;
                    min-height: 220px;

                    @include responsive-down(mobile-large) {
                      width: 100%;
                    }

                    .card--address {
                      .material-icons {
                        display: none;
                      }

                      .card-body_add-address {
                        display: flex;
                        align-items: center;

                        i {
                          margin: 0 6px 0 0;
                        }
                      }
                    }
                  }

                  .address-item {
                    justify-content: flex-start !important;
                    width: calc(50% - 15px);
                    padding: 20px;
                    background: #fff;
                    border: 1px solid #fff;

                    &.selected {
                      background: #f5f5f5;
                      border: 1px solid #f5f5f5;
                    }

                    .card.mb-0 {
                      .card-body {
                        margin: 0 0 30px 25px;
                        font-size: 13px;
                      }

                      .card-header {
                        margin: 0;
                        padding: 12px 0 10px;
                        background-color: transparent;

                        .custom-control-label {
                          padding: 0 0 0 8px;
                        }
                      }
                    }

                    .card-footer {
                      margin: 20px 0 0;

                      .edit-address {
                        margin-right: 10px;
                        color: $dark-gray;
                        font-size: 13px;
                        text-decoration: underline;

                        @include transition(all 0.3s ease-in);

                        &:hover {
                          @include responsive-up(tablet) {
                            text-decoration: none;
                            color: $black;

                            @include transition(all 0.3s ease-in);
                          }
                        }

                        .material-icons {
                          display: none;
                        }
                      }

                      .delete-address {
                        color: $dark-gray;
                        font-size: 13px;
                        text-decoration: underline;

                        @include transition(all 0.3s ease-in);

                        .material-icons {
                          display: none;
                        }

                        &:hover {
                          @include responsive-up(tablet) {
                            text-decoration: none;
                            color: $black;

                            @include transition(all 0.3s ease-in);
                          }
                        }
                      }
                    }
                  }
                }

                #conditions-to-approve {
                  font-size: 13px;
                  padding: 1.4rem 0 1.5rem;
                  border-top: 1px solid #e6e6e6;
                  margin: 2rem 0 0;

                  .custom-checkbox {
                    display: flex;
                    align-items: center;
                    margin: 5px 0 0;

                    @include responsive-down(tablet) {
                      align-content: flex-start;
                    }

                    .custom-control-label {
                      margin-left: 8px;
                    }
                  }
                }
              }

              .card-header {
                padding: 24px 30px;
                margin: 0 -30px;
                background-color: $light-gray;

                @include responsive-down(tablet) {
                  padding: 20px 25px;
                }

                @include responsive-down(mobile-large) {
                  padding: 14px 20px;
                }

                @include responsive-down(mobile) {
                  margin: 0 -15px;
                }

                .step-title {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 24px;
                  font-family: $font-secondary;

                  @include responsive-down(tablet-small) {
                    font-size: 20px;
                  }

                  @include responsive-down(mobile-large) {
                    font-size: 18px;
                  }

                  .material-icons,
                  .step-number {
                    display: none;
                  }

                  .step-edit {
                    color: $gray;
                    cursor: pointer;
                    text-decoration: none;
                    border: none;
                    width: 30px;
                    height: 30px;
                    background: $white;
                    padding: 0;
                    display: flex;
                    border-radius: 50px;
                    align-items: center;
                    justify-content: center;

                    i {
                      display: inline-block;
                      transform: rotate(-180deg);
                      margin: 0;
                      font-size: 13px;
                      font-weight: 600;
                      color: $light-black;
                    }
                  }
                }
              }

              &.-complete {
                .content.card-body {
                  padding: 10px 0 20px;

                  p {
                    font-size: 14px;
                    line-height: 12px;
                    color: $mid-gray;
                    margin: 15px 0 5px !important;

                    &:first-child {
                      margin-top: 10px !important;
                    }

                    &:nth-of-type(3) {
                      margin-top: 20px !important;
                    }

                    a {
                      text-transform: initial;
                      font-size: 12px;
                      color: $black;
                      text-decoration: underline;

                      &:hover {
                        @include responsive-up(tablet) {
                          text-decoration: none;
                        }
                      }
                    }

                    small {
                      font-size: 12px;
                    }
                  }

                  .clearfix {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    height: 100%;

                    button,
                    a {
                      font-size: 16px;
                      width: auto;
                      margin: 30px 0 0;
                    }
                  }

                  .identity {
                    color: $mid-gray;
                    margin-bottom: 0 !important;

                    a {
                      display: inline-block;
                      margin-left: 5px;
                      color: $black;
                    }
                  }
                }
              }

              &.-unreachable {
                cursor: not-allowed;
              }

              &.-reachable {
                cursor: initial;

                #content-checkout-addresses-step {
                  .h4 {
                    padding: 20px 0 0;
                  }
                }

                &.-complete {
                  .step-edit {
                    transform: rotate(180deg);
                  }

                  &.-current {
                    .step-edit {
                      transform: rotate(0deg);
                    }
                  }
                }

                &.-clickable.-unreachable {
                  .step-edit {
                    transform: rotate(180deg);
                  }
                }
              }

              &.-current {
                .step-title {
                  padding-bottom: 0;
                  border-bottom: 1px solid #f6f6f6;
                }

                & > .collapse {
                  display: block;

                  .btn--underline {
                    color: $black;
                    margin-bottom: 20px;
                  }

                  button {
                    width: auto;
                    margin: 10px 0 0;
                  }

                  #contadorCaracteres {
                    font-size: 12px;
                    color: $dark-gray;
                    margin-top: 10px;
                  }
                }
              }

              .cnt-custom-btn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 20px;
                margin-top: 20px;

                button {
                  font-size: 13px;
                }
              }

              .custom-cnt-invoice-button {
                display: flex;
                justify-content: flex-end;
              }

              .payment-options {
                margin-top: 20px;

                & > div {
                  display: block;
                  height: auto;

                  .alert.alert-info {
                    padding: 20px;
                    background-color: $light-gray;
                    font-size: 13px;
                    color: $dark-gray;
                    margin: 16px 0;

                    p {
                      margin: 0;
                    }

                    & > section {
                      display: flex;
                      align-items: center;

                      img {
                        width: 100px;
                      }
                    }

                    dl {
                      display: flex;
                      align-content: center;
                      flex-wrap: wrap;

                      dt,
                      dd {
                        width: calc(100% / 2);
                        margin-left: 0;
                        margin-top: 10px;
                      }

                      dd {
                        color: $black;
                      }
                    }
                  }

                  .payment-option {
                    margin-bottom: 0.5rem;
                    display: flex;

                    label {
                      font-size: 14px;
                      margin: 0 0 0 6px;
                    }

                    img {
                      max-width: 150px;
                    }
                  }

                  &.payment-options__info {
                    margin: 15px 0 30px;
                    display: flex;
                    align-items: center;
                    color: $dark-gray;
                    background-color: $light-gray;

                    i {
                      font-size: 22px;
                      line-height: 22px;
                    }

                    p {
                      font-size: 13px;
                      line-height: 1.4;
                      margin-left: 10px;
                    }
                  }
                }
              }

              #conditions-to-approve {
                ul {
                  padding: 0;
                  margin: 0;

                  li {
                    padding: 0;
                    margin: 0;
                    display: inline-flex;
                    align-items: flex-start;

                    &::before {
                      content: none;
                    }

                    .condition-label {
                      margin: 0 0 0 9px;
                    }
                  }
                }

                label {
                  font-size: 14px;
                  color: $black;

                  a {
                    font-size: 14px;
                    text-transform: lowercase;
                    color: $grayac;
                  }
                }
              }

              #payment-confirmation {
                margin-top: 20px;

                .text-center {
                  display: flex;
                  justify-content: flex-start;

                  button {
                    display: inline-flex;
                    align-items: center;
                    font-size: 16px;
                    width: auto;
                    padding: 8px 24px;
                    margin: 20px 0 0;

                    @include responsive-down(mobile-large) {
                      text-align: left;
                      padding: 8px 20px;
                    }

                    i {
                      display: inline-block;
                      margin-left: 10px;
                      font-size: 16px;
                    }
                  }
                }
              }

              .modal {
                display: none;
              }

              .content.card-body {
                .delivery-options-list {
                  .clearfix {
                    height: auto;
                  }

                  .form-fields {
                    width: 100%;
                    margin-right: 0;

                    .delivery-options {
                      width: 100%;

                      .delivery-option {
                        margin: 10px 0;

                        &.row {
                          display: flex;
                          align-items: flex-start;

                          label {
                            width: 100%;
                            margin-left: 5px;

                            .row {
                              display: flex;
                              align-items: center;
                              width: 100%;
                              position: relative;

                              @include responsive-down(tablet) {
                                position: relative;
                                flex-direction: row;
                                align-items: flex-start;
                              }

                              div {
                                &:nth-child(2) {
                                  margin: 0 0 0 auto;
                                }
                              }

                              .carrier-delay {
                                margin-left: 10px;
                                color: $grayac;

                                @include responsive-down(tablet) {
                                  margin-left: 0;
                                }
                              }

                              .row {
                                margin: 0;
                              }

                              .carrier-name,
                              .carrier-delay {
                                font-size: 14px;
                              }

                              .carrier-name {
                                color: $black;
                              }

                              .carrier-price {
                                font-size: 14px;
                                margin-left: 10px;
                              }

                              img {
                                display: none;
                              }

                              .text--right {
                                position: absolute;
                                right: 0;
                                top: 0;
                              }
                            }

                            &.custom-control-label {
                              display: none;
                            }
                          }
                        }

                        &:first-child {
                          margin-top: 20px;
                        }
                      }

                      #fspasc_wrapper {
                        padding: 25px;
                        background-color: $dark-gray;
                        font-size: 13px;
                        color: $dark-gray;

                        .fspasc-col {
                          margin-top: 0;
                          margin-bottom: 30px;

                          .carrier_title {
                            font-weight: 400;
                          }

                          #fspasc_store_selector {
                            margin: 15px 0;

                            select {
                              width: 100%;
                              border: 1px solid $dark-gray;
                              border-radius: 3px;
                              padding: 16px 10px;
                              font-size: 12px;
                              color: $dark-gray;
                            }
                          }

                          #fspasc_store_address {
                            font-size: 13px;
                            margin: 15px 0;
                          }

                          #fspasc_store_phone {
                            font-size: 13px;
                            margin: 15px 0;
                          }
                        }
                      }

                      .gm-style-iw-d {
                        padding: 0 10px 10px 10px;
                        margin-top: 10px;

                        & > div {
                          & > br {
                            display: none;
                          }

                          & > strong {
                            display: block;
                            margin-bottom: 10px;
                          }

                          table {
                            margin: 16px 0;

                            tbody tr td {
                              font-size: 11.5px;

                              strong {
                                font-weight: 400;
                                margin-bottom: 8px;
                                display: inline-block;
                              }
                            }
                          }

                          & > a {
                            background: $black;
                            border: 1px solid $black;
                            color: $white;
                            padding: 8px 16px;
                            border-radius: 3px;
                            font-size: 12px;

                            &:first-of-type {
                              background: transparent;
                              color: $black;

                              &:hover {
                                @include responsive-up(tablet) {
                                  background: $dark-gray;
                                  color: $gray;
                                  border-color: $gray;

                                  @include transition(all 0.25s ease-in-out);
                                }
                              }
                            }

                            &:hover {
                              @include responsive-up(tablet) {
                                background: $gray;
                                border-color: $gray;
                                color: $white;

                                @include transition(all 0.25s ease-in-out);
                              }
                            }
                          }
                        }
                      }

                      .gm-ui-hover-effect {
                        top: 0 !important;
                        right: 0 !important;
                      }
                    }

                    .order-options {
                      .form-group {
                        textarea {
                          margin: 20px 0 0;
                          min-height: 200px;
                          width: 100%;
                          font-size: 14px;
                          padding: 12px;
                        }
                      }
                    }
                  }

                  .cnt-button {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    button {
                      font-size: 13px;
                    }
                  }

                  form {
                    button {
                      font-size: 16px;
                      margin-top: 20px;
                      width: auto;
                      padding: 8px 24px;
                    }
                  }
                }
              }
            }

            #checkout-addresses-step {
              #delivery-address {
                .form-fields {
                  .form-group.row {
                    label {
                      display: initial;
                      position: inherit;
                    }
                  }
                }
              }
            }

            .clearfix {
              height: 100%;
            }
          }
        }

        .col-lg-4.order-checkout {
          width: calc(40% - 95px);

          @include responsive-up(desktop) {
            width: calc(30% - 10px);
          }

          @include responsive-down(tablet-large) {
            width: calc(40% - 55px);
          }

          @include responsive-down(tablet) {
            width: 100%;
            padding: 50px 0 0;
          }
        }
      }
    }
  }

  .card.js-cart.mb-5 {
    padding-left: 20px;

    @include responsive-down(tablet-large) {
      padding-left: 0;
    }

    .card-body.card__summary {
      .cart-summary-products {
        p.mb-0 {
          margin-top: 0;
          line-height: 24px;
          margin-bottom: 25px;
          font-size: 20px;
          font-weight: 700;
        }

        .link__showsummary {
          display: none;
        }

        #cart-summary-product-list {
          .media-list {
            padding: 0;

            &__item {
              display: flex;
              align-items: stretch;
              padding: 25px 0;
              border-bottom: 1px solid #f6f6f6;
              padding-right: 15px;

              &::before {
                display: none;
              }

              .media-body {
                width: 100%;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .product-name {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  line-height: 16px;
                  color: $dark-gray;
                }

                .clearfix {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  height: 20px;
                  font-size: 12px;

                  .product-price {
                    font-size: 15px;
                    font-weight: 700;
                  }

                  .product-quantity {
                    font-size: 14px;
                    font-weight: 500;
                  }
                }

                .product-line-info {
                  width: 100%;
                  font-size: 11px;
                }
              }

              a {
                height: 95px;

                img {
                  width: 95px;
                  height: auto;
                  background: #f6f6f6;
                }
              }

              &:first-child {
                padding-top: 0;
              }

              &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
              }
            }
          }
        }
      }

      .cart-summary-subtotals-container {
        .cart-summary-line.cart-summary-subtotals {
          display: flex;
          justify-content: space-between;
          margin: 0 0 10px;
          font-size: 13px;

          .label {
            font-size: 13px;
            line-height: 13px;
            color: $dark-gray;
          }

          .value {
            font-size: 13px;
            line-height: 13px;
            font-weight: 700;
          }
        }
      }

      .block-promo {
        .alert {
          .js-error-text {
            font-size: 10px;
            color: #d32f2f;
            margin-top: 10px;
            margin-bottom: 0;
            padding: 5px 15px;
            background: #d32f2f21;
            border-radius: 3px;
            display: inline-block;

            &:empty {
              padding: 0;
              margin-top: 0;
            }
          }
        }
      }

      .order-resume-title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .card-footer {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e3e3e3;

      .cart-summary-line.cart-total {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 700;

          span {
            font-size: 11px;
            color: $dark-gray;
            margin-left: 5px;
            font-weight: 300;
          }
        }

        .value {
          font-weight: 700;
        }
      }
    }

    ul.media-list {
      margin-bottom: 50px;
      max-height: 265px;
      min-height: 160px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: block;
        height: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $light-gray;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $dark-gray;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $dark-gray;
      }
    }

    .container-background {
      background-color: #f6f6f6;
      padding: 15px;
      margin: 15px 0 35px;
    }

    .remove-code {
      display: flex;
      margin-bottom: 10px;

      a {
        font-size: 11px;
        text-decoration: underline;
        color: $light-gray;

        &:hover {
          @include responsive-up(tablet) {
            text-decoration: none;
          }
        }
      }
    }
  }

  .promo-code__content {
    .input-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .form-control {
        width: 100%;
        padding: 11px;
        border: 1px solid #989898;
        border-right: 0;
        font-size: 12px;
        color: #989898;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .btn--secondary {
        font-size: 12px;
        letter-spacing: normal;
        text-transform: inherit;
        padding: 6px 18px;
        border: 1px solid $black;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .ps-alert-error.alert.alert-danger.address-item,
  .invalid-feedback {
    font-size: 10px;
    color: #d32f2f;
    margin-top: 10px;
    padding: 5px 15px;
    background: #d32f2f21;
    border-radius: 3px;
    display: inline-block;

    ul {
      padding: 0;

      li {
        padding: 0;

        &::before {
          display: none;
        }
      }
    }

    &:empty {
      display: none;
    }
  }

  .form-group {
    ul {
      padding: 0;
    }
  }

  .alert.alert-danger {
    font-size: 10px;
    color: #d32f2f;
    background: #d32f2f21;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px;

    &::before {
      display: none;
    }

    .js-error-text {
      margin-top: 0 !important;
      background: transparent !important;

      &:empty {
        display: none;
      }
    }
  }

  p.alert.alert-danger {
    padding: 5px 15px;

    &:empty {
      display: none;
    }
  }

  .l-footer {
    display: flex;
    justify-content: center;
    margin: 0;
    text-align: center;
    flex-wrap: wrap;
    padding: 0 0 20px;

    @include responsive-down(mobile) {
      margin: 40px 0 0;
    }

    .footer__payment {
      width: 100%;

      @include responsive-up(tablet) {
        display: none;
      }
    }

    .footer__icons {
      justify-content: flex-end;
    }

    .footer__legal {
      .textlegal {
        text-align: left;
      }

      @include responsive-down(tablet) {
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: row;
        padding: 20px 0 0;

        .textlegal {
          width: 100%;
          padding: 20px 0;
          order: 1;
        }

        .linklegal {
          padding: 0 20px 0 0;
        }
      }

      @include responsive-down(mobile) {
        .linklegal {
          padding: 0 20px 20px 0;
        }
      }
    }
  }

  .modal {
    display: none;
  }
}

.input-group__help-text {
  color: $light-gray;
  font-size: 9px;
  margin-top: 5px;
}
