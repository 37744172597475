/* Layouts: Pages: Checkout: Order
--------------------------------------------------------------------------------------------------------------------- */
main.main-maintenance {
  margin: 0;
  height: 100vh;
}

.maintenance {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    svg {
      width: 150px;
    }
  }

  &__claim {
    .description,
    .title {
      color: $white;
      text-align: center;
    }

    .title {
      margin: 20px 0;

      @include responsive-down(mobile-large) {
        margin: 15px 0;
      }
    }

    .description {
      max-width: 300px;
      text-align: center;
      line-height: 1.4;
      margin: 0 auto 20px;
    }
  }

  &__video {
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: grid;
    }
  }

  &__socialitem {
    i {
      color: $white;
      font-size: 20px;
      margin: 0 5px;
    }
  }
}
