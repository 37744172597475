/* Layouts: Pages: Checkout: Order
--------------------------------------------------------------------------------------------------------------------- */
.sectioncontactform {
  background-color: $light-gray;
  padding: 80px 0;
  border-bottom: 1px solid #e5e5e5;

  @include responsive-down(tablet-extra-small) {
    padding: 70px 0 50px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;

    @include responsive-down(tablet-extra-small) {
      flex-direction: column;
    }
  }

  &__info {
    width: 50%;
    padding: 0 20px;

    @include responsive-down(tablet-extra-small) {
      width: 100%;
      margin: 0 0 40px;
    }
  }

  &__form {
    width: 47%;
    padding: 0 20px;

    @include responsive-down(tablet-extra-small) {
      width: 100%;
    }

    textarea {
      background-color: $white;
      border: 1px solid #e5e5e5;
      color: $grayac;
      resize: none;

      &::-webkit-input-placeholder {
        color: $grayac;
      }
    }

    .btn {
      border-radius: 5px;
      width: 200px;

      &:hover {
        @include responsive-up(tablet) {
          background-color: #0a3b76;
          color: $white;
          border: 1px solid #0a3b76;
        }
      }

      @include responsive-down(tablet-extra-small) {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
      }
    }

    .checkbox {
      margin: 20px 0;

      label::before {
        background-color: $white;
      }

      a {
        color: $primary;
        text-decoration: underline;
        font-weight: 300;

        &:hover {
          @include responsive-up(tablet) {
            color: $black;
          }
        }
      }
    }

    .contact-form {
      .forms-group {
        margin: 0 0 20px;

        @include responsive-down(tablet-extra-small) {
          margin: 0 0 15px;
        }

        .input {
          background-color: $white;
          border: 1px solid #e5e5e5;
        }

        .label {
          color: $grayac;
        }

        label {
          margin: 0 5px 5px 0;
          display: inline-block;
        }

        select {
          padding: 3px;
          background-color: $white;
          font-size: 12px;
        }

        .custom-file-input {
          padding: 20px 0;
        }

        &--file {
          label {
            max-height: none;
            margin: 0 5px 10px 0;
          }

          input {
            padding: 0 0 10px !important;
          }
        }
      }
    }
  }

  &__title {
    font-size: 26px;
    padding: 0 0 20px;
    max-width: 240px;
    line-height: 1;

    @include responsive-down(tablet-extra-small) {
      max-width: none;
      font-size: 34px;
      padding: 0 0 40px;
    }
  }

  &__contact {
    margin: 10px 0;
    font-weight: 800;
    font-size: 16px;
  }

  &__description {
    padding: 0 0 45px;
    margin: 0;

    @include responsive-down(tablet-extra-small) {
      padding: 0 0 30px;
    }
  }

  &__link {
    font-size: 16px;
    padding: 8px 0;
    display: block;
  }
}
