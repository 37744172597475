/* Layouts: Pages: Simple CMS
--------------------------------------------------------------------------------------------------------------------- */
.page-header--cms {
  padding: 100px 50px 20px;
  max-width: 900px;
  margin: 0 auto;

  @include responsive-down(tablet) {
    padding: 80px 30px 20px;
  }

  @include responsive-down(mobile-large) {
    padding: 50px 20px 20px;
  }
}

.pagesimple {
  padding: 0 0 80px;

  &__content {
    padding: 0;
    animation: fadeIn ease 1.2s;

    h1 {
      font-size: 45px;

      @include responsive-down(tablet) {
        font-size: 35px;
      }

      @include responsive-down(mobile-large) {
        font-size: 30px;
      }
    }

    h2 {
      font-size: 28px;
      color: $mid-gray;
      text-transform: inherit;
      padding: 40px 0 5px;

      @include responsive-down(mobile-large) {
        padding: 20px 0 0;
      }
    }

    h3 {
      font-size: 24px;
      color: $mid-gray;
      text-transform: inherit;
      padding: 40px 0 0;
      line-height: 1.4;

      @include responsive-down(mobile-large) {
        padding: 30px 0 0;
      }
    }

    h4,
    h5 {
      font-size: 21px;
      color: $mid-gray;
      text-transform: inherit;
      padding: 40px 0 0;
      line-height: 1.4;
    }

    p {
      margin: 16px 0;
      font-size: 14px;
      color: $mid-gray;
    }

    a {
      text-decoration: underline;
    }

    ul {
      padding: 0 0 0 30px;
    }

    ul,
    ol {
      padding-top: 10px;
      padding-bottom: 10px;

      li {
        font-size: 14px;
        line-height: 1.5;
        color: $mid-gray;
        padding: 0 0 10px 0;

        ol {
          padding: 10px 0 10px 30px;
        }
      }
    }
  }
}
