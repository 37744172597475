/* variables::fonts::main
--------------------------------------------------------------------------------------------------------------------- */
@font-face {
  font-family: 'Britanica';
  src: url('/themes/goodyear-power/assets/css/fonts/Britanica-Bold.eot');
  src:
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Bold.eot') format('embedded-opentype'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Bold.woff') format('woff'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Bold.ttf') format('truetype'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Bold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Britanica';
  src: url('/themes/goodyear-power/assets/css/fonts/Britanica-Regular.eot');
  src:
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Regular.eot') format('embedded-opentype'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Regular.woff') format('woff'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Regular.ttf') format('truetype'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Britanica';
  src: url('/themes/goodyear-power/assets/css/fonts/Britanica-Light.eot');
  src:
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Light.eot') format('embedded-opentype'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Light.woff') format('woff'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Light.ttf') format('truetype'),
    url('/themes/goodyear-power/assets/css/fonts/Britanica-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
