/* component::text seo
--------------------------------------------------------------------------------------------------------------------- */
.textseo {
  padding: 80px 0;

  .container {
    display: flex;
    column-gap: 50px;
    font-family: $font-secondary;

    @include responsive-down(mobile-large) {
      column-count: 1;
      flex-direction: column;
    }

    .textseo-left,
    .textseo-right {
      width: 50%;

      @include responsive-down(mobile-large) {
        width: 100%;
      }
    }

    .textseo-right {
      @include responsive-down(mobile-large) {
        margin: 20px 0 0;
      }
    }
  }

  &--onecolumn {
    .container {
      .textseo-left {
        width: 100%;
      }

      .textseo-right {
        width: 0;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 16px;
    font-family: $font-secondary;
    font-weight: 700;
    margin: 0 0 10px;
    line-height: 1.6;
  }

  ul li,
  ol li {
    font-size: 14px;
  }

  ul li {
    padding: 0 0 10px;
  }

  a {
    text-decoration: underline;
  }

  p {
    margin: 0 0 14px;
    line-height: 1.7;
  }
}
