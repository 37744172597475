/* component::redes sociales
--------------------------------------------------------------------------------------------------------------------- */
.rsocial {
  padding: 60px 0;

  &__intro {
    display: flex;
    justify-content: space-between;
    padding: 0 0 40px;
    align-items: flex-end;

    @include responsive-down(mobile-large) {
      flex-direction: column;
      align-items: center;
      padding: 0 0 30px;
    }

    h3 {
      margin: 0 auto 0 0;

      @include responsive-down(mobile-large) {
        text-align: center;
        margin: 0 0 20px 0;
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 500;

      @include responsive-down(mobile-large) {
        font-size: 14px;
      }

      i {
        display: inline-block;
        font-size: 30px;
        margin-right: 10px;

        @include responsive-down(mobile-large) {
          font-size: 18px;
        }
      }
    }
  }

  &__gallery {
    height: 1160px;
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;

    @include responsive-up(tablet) {
      height: 580px;
    }

    @include responsive-up(tablet-extra-large) {
      height: 700px;
    }

    @include responsive-down(mobile-large) {
      height: 790px;
    }
  }

  &__link {
    width: calc(25% - 10px);
    margin: 0 5px 10px;
    overflow: hidden;

    @include responsive-down(tablet) {
      width: calc(50% - 10px);
    }

    &:nth-of-type(4n+1) {
      @include responsive-up(tablet) {
        order: 1;
      }
    }

    &:nth-of-type(4n+2) {
      @include responsive-up(tablet) {
        order: 2;
      }
    }

    &:nth-of-type(4n+3) {
      @include responsive-up(tablet) {
        order: 3;
      }
    }

    &:nth-of-type(4n) {
      @include responsive-up(tablet) {
        order: 4;
      }
    }

    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(8) {
      height: 250px;

      @include responsive-up(tablet-extra-large) {
        height: 310px;
      }

      @include responsive-down(mobile-large) {
        height: 160px;
      }
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(7) {
      height: 310px;

      @include responsive-up(tablet-extra-large) {
        height: 370px;
      }

      @include responsive-down(mobile-large) {
        height: 210px;
      }
    }

    .break {
      @include responsive-up(tablet) {
        flex-basis: 100%;
        width: 0;
        margin: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);

      @include transition(all 0.5s ease-in-out);
    }

    i {
      font-size: 26px;
      margin: 0 10px 0 0;

      @include responsive-down(mobile-large) {
        font-size: 16px;
      }
    }

    &:hover {
      @include responsive-up(tablet) {
        color: $black;

        img {
          transform: scale(1.1);

          @include transition(all 0.5s ease-in-out);
        }
      }
    }
  }
}
