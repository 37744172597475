/* component::intro category
--------------------------------------------------------------------------------------------------------------------- */
.page-category.category-id-10 {
  .breadcrumb {
    @include responsive-down(tablet) {
      display: none;
    }
  }
}

.introcategory {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  max-width: 740px;
  animation: slide-top-intro 1.3s ease;

  @include responsive-down(tablet) {
    padding: 60px 0 0;
  }

  @include responsive-down(mobile-large) {
    text-align: left;
    padding: 30px 0 0;
  }

  p {
    line-height: 1.7;
  }

  .title {
    text-align: center;
    margin: 0 0 30px;
    font-size: 34px;

    @include responsive-down(tablet) {
      font-size: 30px;
    }

    @include responsive-down(mobile-large) {
      font-size: 24px;
      text-align: left;
      margin: 0 0 15px;
    }
  }
}
